import { all, call, fork, put, takeEvery } from "redux-saga/effects";
import Api, { patchData } from './Api';
import { GET_ALL_SIZE } from '../constants/AppConstant';

import {
  loadAccountLedgers as loadAccountLedgersAction,
  loadAccountLedgersSuccess,
  addAccountLedgerSuccess,
  updateAccountLedgerSuccess,
  exportExcelAccountLedgersSuccess,
  reportAccountLedgersSuccess,
  reportSumAccountLedgersSuccess
} from '../actions/AccountLedger';

import {
  ACCOUNTLEDGERS_LOAD,
  ACCOUNTLEDGER_ADD,
  ACCOUNTLEDGER_UPDATE,
  ACCOUNTLEDGER_DELETE,
  ACCOUNTLEDGER_DELETE_ALL,
  ACCOUNTLEDGERS_EXPORT_EXCEL,
  ACCOUNTLEDGERS_REPORT
} from '../constants/ActionTypes';

import {
  userSignOut
} from '../actions/Auth';

export const getAccountLedgerById = (id, callback) => {
  Api().get('account-ledger/' + id).then(response => {
    callback(response.data);
  })
}

export const getAllAccountLedgers = (callback) => {
  Api().post('account-ledger/search', { limit: GET_ALL_SIZE })
    .then(response => {
      callback(response.data.records);
    })
}

export const addOrUpdateAccountLedger = (model, callback) => {
  Api().post('account-ledger/add-or-update', model).then(response => {
    callback(response);
  })
}

export const getAccountLedgersByCompany = (type, company, callback) => {
  Api().post('account-ledger/search', {
    limit: GET_ALL_SIZE,
    filterGroups: [{
      filters: [{
        property: 'companyId',
        operator: 'equal',
        value: type === 'firstLoad' ? company.id : company.companyId
      }]
    }]
  }).then(response => {
    callback(response.data.records);
  })
}

export const getMaxAccountLedgerCodeSorted = (callback) => {
  Api().post('account-ledger/search', {
    offset: 0,
    limit: 1,
    orderBy: "codeSorted",
    descending: true
  })
    .then(response => {
      let codeSorted = 0;
      let records = response.data.records;
      if (records.length) {
        codeSorted = records[0].codeSorted;
      }
      callback(codeSorted);
    })
}

const loadAccountLedgersRequest = async (params) => {
  if (params.filterGroups && params.filterGroups.length) {
    return await Api().post(`account-ledger/search`, params)
      .then(data => data)
      .catch(error => error);
  }

  return await Api().get(`account-ledger`, { params })
    .then(data => data)
    .catch(error => error);
}

const addAccountLedgerRequest = async (payload) =>
  await Api().post(`account-ledger`, payload)
    .then(data => data)
    .catch(error => error);

const updateAccountLedgerRequest = async (payload) =>
  await Api().patch(`account-ledger/${payload.id}`, patchData(payload))
    .then(data => data)
    .catch(error => error);

export const deleteAccountLedgerRequest = async (id) =>
  await Api().delete(`account-ledger/${id}`)
    .then(data => data)
    .catch(error => error);

function* loadAccountLedgers({ payload }) {
  try {
    const data = yield call(loadAccountLedgersRequest, payload);
    data.status == 401 || data.response && data.response.status == 401 ? yield put(userSignOut()) :
      yield put(loadAccountLedgersSuccess(data.data));
  } catch (error) {
    //yield put(showAuthMessage(error));
  }
}

function* processLoadAccountLedgers() {
  yield takeEvery(ACCOUNTLEDGERS_LOAD, loadAccountLedgers);
}

function* loadAccountLedger({ selectedAccountLedgerId }) {
  try {
    const data = yield call(loadAccountLedgersRequest, selectedAccountLedgerId);
    data.status == 401 || data.response && data.response.status == 401 ? yield put(userSignOut()) :
      yield put(loadAccountLedgersSuccess(data.data));
  } catch (error) {
    //yield put(showAuthMessage(error));
  }
}

function* processLoadAccountLedger() {
  yield takeEvery(ACCOUNTLEDGERS_LOAD, loadAccountLedger);
}


function* addAccountLedger({ payload }) {
  try {
    const data = yield call(addAccountLedgerRequest, payload.model);
    data.status == 401 || data.response && data.response.status == 401 ? yield put(userSignOut()) :
      yield put(addAccountLedgerSuccess(payload.filter));
  } catch (error) {
    //yield put(showAuthMessage(error));
  }
}

function* processAddAccountLedger() {
  yield takeEvery(ACCOUNTLEDGER_ADD, addAccountLedger);
}


function* updateAccountLedger({ payload }) {
  try {
    const data = yield call(updateAccountLedgerRequest, payload.model);
    data.status == 401 || data.response && data.response.status == 401 ? yield put(userSignOut()) :
      yield put(updateAccountLedgerSuccess(payload.filter));
  } catch (error) {
    //yield put(showAuthMessage(error));
  }
}

function* processUpdateAccountLedger() {
  yield takeEvery(ACCOUNTLEDGER_UPDATE, updateAccountLedger);
}

function* deleteAccountLedger({ payload }) {
  try {
    const data = yield call(deleteAccountLedgerRequest, payload.model.id);
    data.status == 401 || data.response && data.response.status == 401 ? yield put(userSignOut()) :
      yield put(loadAccountLedgersAction(payload.filter));
  } catch (error) {
    //yield put(showAuthMessage(error));
  }
}

function* deleteAllAccountLedgers({ payload }) {
  try {
    for (let i = 0; i < payload.models.length; ++i) {
      const data = yield call(deleteAccountLedgerRequest, payload.models[i].id);
      if (data.status == 401 || data.response && data.response.status == 401) { yield put(userSignOut()); break; }
    }

    yield put(loadAccountLedgersAction(payload.filter));
  } catch (error) {
    //yield put(showAuthMessage(error));
  }
}

function* processDeleteAccountLedger() {
  yield takeEvery(ACCOUNTLEDGER_DELETE, deleteAccountLedger);
}

function* processDeleteAllAccountLedgers() {
  yield takeEvery(ACCOUNTLEDGER_DELETE_ALL, deleteAllAccountLedgers);
}


const exportAccountLedgersRequest = async (params) => {
  return await Api({ responseType: 'blob' }).post(`account-ledger/export/excel`, params)
    .then(data => data)
    .catch(error => error);
}


function* exportAccountLedgers({ payload }) {
  try {
    const data = yield call(exportAccountLedgersRequest, payload);
    data.status == 401 || data.response && data.response.status == 401 ? yield put(userSignOut()) :
      yield put(exportExcelAccountLedgersSuccess(data.data));
  } catch (error) {
    //yield put(showAuthMessage(error));
  }
}

function* processExportAccountLedgers() {
  yield takeEvery(ACCOUNTLEDGERS_EXPORT_EXCEL, exportAccountLedgers);
}


const reportAccountLedgersRequest = async (params) => {
  return await Api().post(`account-ledger/report`, params)
    .then(data => data)
    .catch(error => error);
}

function* reportAccountLedgers({ payload }) {
  try {
    if (!payload) { payload = {} }
    const data = yield call(reportAccountLedgersRequest, payload);

    if (data.status == 401 || data.response && data.response.status == 401) {
      yield put(userSignOut());
    } else {
      if (payload.type == 'month') {
        yield put(reportAccountLedgersSuccess({ data: data.data, filter: payload }));
      } else {
        yield put(reportSumAccountLedgersSuccess(data.data));
      }
    }
  } catch (error) {
    //yield put(showAuthMessage(error));
  }
}

function* processReportAccountLedgers() {
  yield takeEvery(ACCOUNTLEDGERS_REPORT, reportAccountLedgers);
}

export default function* AccountLedgerSagas() {
  yield all([fork(processLoadAccountLedgers),
  fork(processLoadAccountLedger),
  fork(processAddAccountLedger),
  fork(processUpdateAccountLedger),
  fork(processDeleteAllAccountLedgers),
  fork(processDeleteAccountLedger),
  fork(processExportAccountLedgers),
  fork(processReportAccountLedgers)
  ]);
}
