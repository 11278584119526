import { all, call, fork, put, takeEvery } from "redux-saga/effects";
import Api, { patchData } from './Api';
import { GET_ALL_SIZE } from '../constants/AppConstant';

import {
  loadOrderStoreProducts as loadOrderStoreProductsAction,
  loadOrderStoreProductsSuccess,
  addOrderStoreProductSuccess,
  updateOrderStoreProductSuccess,
  exportExcelOrderStoreProductsSuccess,
  reportOrderStoreProductsSuccess
} from '../actions/OrderStoreProduct';

import {
  ORDERSTOREPRODUCTS_LOAD,
  ORDERSTOREPRODUCT_ADD,
  ORDERSTOREPRODUCT_UPDATE,
  ORDERSTOREPRODUCT_DELETE,
  ORDERSTOREPRODUCT_DELETE_ALL,
  ORDERSTOREPRODUCTS_EXPORT_EXCEL,
  ORDERSTOREPRODUCTS_REPORT
} from '../constants/ActionTypes';

import {
  userSignOut
} from '../actions/Auth';

export const getOrderStoreProductById = (id, callback) => {
  Api().get('order-store-product/' + id).then(response => {
    callback(response.data);
  })
}

export const getOrderStoreProductsTotalMoney = (callback, params) => {
  Api().post('order-store-product/total-money', params)
    .then(response => {
      callback(response.data);
    })
}

export const getAllOrderStoreProducts = (callback, params) => {
  if (!params) {
    params = {
      limit: GET_ALL_SIZE
    }
  }

  Api().post('order-store-product/search', params)
    .then(response => {
      callback(response.data.records);        
    })
}

export const getOrderStoreProductsByCompany = (companyId, callback) => {
  Api().post('order-store-product/search', {
    limit: GET_ALL_SIZE,
    filterGroups: [{
      filters: [{
        property: 'companyId',
        operator: 'equal',
        value: companyId
      }]
    }]
  }).then(response => {
    callback(response.data.records);    
  })
}

export const getMaxOrderStoreProductCodeSorted = (callback) => {
  Api().post('order-store-product/search', { offset: 0,
    limit: 1,
    orderBy: "codeSorted",
    descending: true
  })
    .then(response => {
      let codeSorted = 0;
      let records = response.data.records;
      if (records.length) {
        codeSorted = records[0].codeSorted;
      }
      callback(codeSorted);
    })
}

const loadOrderStoreProductsRequest = async (params) => {
  if (params.filterGroups && params.filterGroups.length) {
    return await Api().post(`order-store-product/search`, params)
      .then(data => data)
      .catch(error => error);
  }

  return await Api().get(`order-store-product`, { params })
    .then(data => data)
    .catch(error => error);
}

const addOrderStoreProductRequest = async (payload) =>
  await Api().post(`order-store-product`, payload)
    .then(data => data)
    .catch(error => error);

const updateOrderStoreProductRequest = async (payload) =>
  await Api().patch(`order-store-product/${payload.id}`, patchData(payload))
    .then(data => data)
    .catch(error => error);

export const deleteOrderStoreProductRequest = async (id) =>
  await Api().delete(`order-store-product/${id}`)
    .then(data => data)
    .catch(error => error);

function* loadOrderStoreProducts({ payload }) {
  try {
    const data = yield call(loadOrderStoreProductsRequest, payload);
    data.status == 401 || data.response && data.response.status == 401 ? yield put(userSignOut()) :
    yield put(loadOrderStoreProductsSuccess(data.data));
  } catch (error) {
    //yield put(showAuthMessage(error));
  }
}

function* processLoadOrderStoreProducts() {
  yield takeEvery(ORDERSTOREPRODUCTS_LOAD, loadOrderStoreProducts);
}

function* loadOrderStoreProduct({ selectedOrderStoreProductId }) {
  try {
    const data = yield call(loadOrderStoreProductsRequest, selectedOrderStoreProductId);
    data.status == 401 || data.response && data.response.status == 401 ? yield put(userSignOut()) :
    yield put(loadOrderStoreProductsSuccess(data.data));
  } catch (error) {
    //yield put(showAuthMessage(error));
  }
}

function* processLoadOrderStoreProduct() {
  yield takeEvery(ORDERSTOREPRODUCTS_LOAD, loadOrderStoreProduct);
}


function* addOrderStoreProduct({ payload }) {
  try {
    const data = yield call(addOrderStoreProductRequest, payload.model);
    data.status == 401 || data.response && data.response.status == 401 ? yield put(userSignOut()) :
    yield put(addOrderStoreProductSuccess(payload.filter));
  } catch (error) {
    //yield put(showAuthMessage(error));
  }
}

function* processAddOrderStoreProduct() {
  yield takeEvery(ORDERSTOREPRODUCT_ADD, addOrderStoreProduct);
}


function* updateOrderStoreProduct({ payload }) {
  try {
    const data = yield call(updateOrderStoreProductRequest, payload.model);
    data.status == 401 || data.response && data.response.status == 401 ? yield put(userSignOut()) :
    yield put(updateOrderStoreProductSuccess(payload.filter));
  } catch (error) {
    //yield put(showAuthMessage(error));
  }
}

function* processUpdateOrderStoreProduct() {
  yield takeEvery(ORDERSTOREPRODUCT_UPDATE, updateOrderStoreProduct);
}

function* deleteOrderStoreProduct({ payload }) {
  try {
    const data = yield call(deleteOrderStoreProductRequest, payload.model.id);
    data.status == 401 || data.response && data.response.status == 401 ? yield put(userSignOut()) :
    yield put(loadOrderStoreProductsAction(payload.filter));
  } catch (error) {
    //yield put(showAuthMessage(error));
  }
}

function* deleteAllOrderStoreProducts({ payload }) {
  try {
    for (let i = 0; i < payload.models.length; ++i) {
      const data = yield call(deleteOrderStoreProductRequest, payload.models[i].id);
      if (data.status == 401 || data.response && data.response.status == 401) { yield put(userSignOut()); break; }
    }

    yield put(loadOrderStoreProductsAction(payload.filter));
  } catch (error) {
    //yield put(showAuthMessage(error));
  }
}

function* processDeleteOrderStoreProduct() {
  yield takeEvery(ORDERSTOREPRODUCT_DELETE, deleteOrderStoreProduct);
}

function* processDeleteAllOrderStoreProducts() {
  yield takeEvery(ORDERSTOREPRODUCT_DELETE_ALL, deleteAllOrderStoreProducts);
}

const exportOrderStoreProductsRequest = async (params) => {
  return await Api({responseType: 'blob'}).post(`order-store-product/export/excel`, params)
    .then(data => data)
    .catch(error => error);
}


function* exportOrderStoreProducts({ payload }) {
  try {
    const data = yield call(exportOrderStoreProductsRequest, payload);
    data.status == 401 || data.response && data.response.status == 401 ? yield put(userSignOut()) :
    yield put(exportExcelOrderStoreProductsSuccess(data.data));
  } catch (error) {
    //yield put(showAuthMessage(error));
  }
}

function* processExportOrderStoreProducts() {
  yield takeEvery(ORDERSTOREPRODUCTS_EXPORT_EXCEL, exportOrderStoreProducts);
}

const reportOrderStoreProductsRequest = async (params) => {
  return await Api().post(`order-store-product/report`, params)
    .then(data => data)
    .catch(error => error);
}


function* reportOrderStoreProducts({ payload }) {
  try {
    if (!payload) { payload = {} }
    const data = yield call(reportOrderStoreProductsRequest, payload);
    data.status == 401 || data.response && data.response.status == 401 ? yield put(userSignOut()) :
    yield put(reportOrderStoreProductsSuccess(data.data));
  } catch (error) {
    //yield put(showAuthMessage(error));
  }
}

function* processReportOrderStoreProducts() {
  yield takeEvery(ORDERSTOREPRODUCTS_REPORT, reportOrderStoreProducts);
}

export default function* OrderStoreProductSagas() {
  yield all([fork(processLoadOrderStoreProducts),
  fork(processLoadOrderStoreProduct),
  fork(processAddOrderStoreProduct),
  fork(processUpdateOrderStoreProduct),
  fork(processDeleteAllOrderStoreProducts),
  fork(processDeleteOrderStoreProduct),
  fork(processExportOrderStoreProducts),
  fork(processReportOrderStoreProducts)
]);
}
