import { all, call, fork, put, takeEvery } from "redux-saga/effects";
import Api, { patchData } from './Api';
import { GET_ALL_SIZE } from '../constants/AppConstant';

import {
  loadProducts as loadProductsAction,
  loadProductsSuccess,
  addProductSuccess,
  updateProductSuccess,
  exportExcelProducts,
  exportExcelProductsSuccess,
  exportExcelProductsFailed
} from '../actions/Product';

import {
  PRODUCTS_LOAD,
  PRODUCT_ADD,
  PRODUCT_UPDATE,
  PRODUCT_DELETE,
  PRODUCT_DELETE_ALL,
  PRODUCTS_EXPORT_EXCEL,
  PRODUCTS_EXPORT_EXCEL_SUCCESS,
  PRODUCTS_EXPORT_EXCEL_FAILED
} from '../constants/ActionTypes';

import {
  userSignOut
} from '../actions/Auth';

export const getProductById = (id, callback) => {
  Api().get('product/' + id).then(response => {
    callback(response.data);
  })
}

export const getAllProducts = (callback, params) => {
  if (!params) {
    params = {
      limit: GET_ALL_SIZE
    }
  }

  Api().post('product/search', params)
    .then(response => {
      callback(response.data.records);        
    })
}

export const multipleUpdateProduct = (model, callback) => {
  Api().post('product/multiple-update', model).then(response => {
    callback(response);
  })
}

export const getProductsByCompany = (companyId, callback) => {
  Api().post('product/search', {
    limit: GET_ALL_SIZE,
    filterGroups: [{
      filters: [{
        property: 'companyId',
        operator: 'equal',
        value: companyId,
      }]
    }]
  }).then(response => {
    callback(response.data.records);
  })
}


export const getProductsByCategory = (type, category, callback) => {
  Api().post('product/search', {
    limit: GET_ALL_SIZE,
    filterGroups: [{
      filters: [{
        property: 'categoryId',
        operator: 'equal',
        value: type === 'firstLoad' ? category.id : category.categoryId
      }]
    }]
  }).then(response => {
    if(response){
      callback(response.data.records);    
    }else {
      callback(response.data = null);    
    }
  })
}

export const getMaxProductCodeSorted = (callback) => {
  Api().post('product/search', { offset: 0,
    limit: 1,
    orderBy: "codeSorted",
    descending: true
  })
    .then(response => {
      let codeSorted = 0;
      let records = response.data.records;
      if (records.length) {
        codeSorted = records[0].codeSorted;
      }
      callback(codeSorted);
    })
}

const loadProductsRequest = async (params) => {
  if (params.filterGroups && params.filterGroups.length) {
    return await Api().post(`product/search`, params)
      .then(data => data)
      .catch(error => error);
  }

  return await Api().get(`product`, { params })
    .then(data => data)
    .catch(error => error);
}

const addProductRequest = async (payload) =>
  await Api().post(`product`, payload)
    .then(data => data)
    .catch(error => error);

const updateProductRequest = async (payload) =>
  await Api().patch(`product/${payload.id}`, patchData(payload))
    .then(data => data)
    .catch(error => error);

export const deleteProductRequest = async (id) =>
  await Api().delete(`product/${id}`)
    .then(data => data)
    .catch(error => error);

function* loadProducts({ payload }) {
  try {
    const data = yield call(loadProductsRequest, payload);
    data.status == 401 || data.response && data.response.status == 401 ? yield put(userSignOut()) :
    yield put(loadProductsSuccess(data.data));
  } catch (error) {
    //yield put(showAuthMessage(error));
  }
}

function* processLoadProducts() {
  yield takeEvery(PRODUCTS_LOAD, loadProducts);
}

function* loadProduct({ selectedProductId }) {
  try {
    const data = yield call(loadProductsRequest, selectedProductId);
    data.status == 401 || data.response && data.response.status == 401 ? yield put(userSignOut()) :
    yield put(loadProductsSuccess(data.data));
  } catch (error) {
    //yield put(showAuthMessage(error));
  }
}

function* processLoadProduct() {
  yield takeEvery(PRODUCTS_LOAD, loadProduct);
}


function* addProduct({ payload }) {
  try {
    const data = yield call(addProductRequest, payload.model);
    data.status == 401 || data.response && data.response.status == 401 ? yield put(userSignOut()) :
    yield put(addProductSuccess(payload.filter));
  } catch (error) {
    //yield put(showAuthMessage(error));
  }
}

function* processAddProduct() {
  yield takeEvery(PRODUCT_ADD, addProduct);
}


function* updateProduct({ payload }) {
  try {
    const data = yield call(updateProductRequest, payload.model);
    data.status == 401 || data.response && data.response.status == 401 ? yield put(userSignOut()) :
    yield put(updateProductSuccess(payload.filter));
  } catch (error) {
    //yield put(showAuthMessage(error));
  }
}

function* processUpdateProduct() {
  yield takeEvery(PRODUCT_UPDATE, updateProduct);
}

function* deleteProduct({ payload }) {
  try {
    const data = yield call(deleteProductRequest, payload.model.id);
    data.status == 401 || data.response && data.response.status == 401 ? yield put(userSignOut()) :
    yield put(loadProductsAction(payload.filter));
  } catch (error) {
    //yield put(showAuthMessage(error));
  }
}

function* deleteAllProducts({ payload }) {
  try {
    for (let i = 0; i < payload.models.length; ++i) {
      const data = yield call(deleteProductRequest, payload.models[i].id);
      if (data.status == 401 || data.response && data.response.status == 401) { yield put(userSignOut()); break; }
    }

    yield put(loadProductsAction(payload.filter));
  } catch (error) {
    //yield put(showAuthMessage(error));
  }
}

function* processDeleteProduct() {
  yield takeEvery(PRODUCT_DELETE, deleteProduct);
}

function* processDeleteAllProducts() {
  yield takeEvery(PRODUCT_DELETE_ALL, deleteAllProducts);
}


const exportProductsRequest = async (params) => {
  return await Api({responseType: 'blob'}).post(`product/export/excel`, params)
    .then(data => data)
    .catch(error => error);
}


function* exportProducts({ payload }) {
  try {
    const data = yield call(exportProductsRequest, payload);
    data.status == 401 || data.response && data.response.status == 401 ? yield put(userSignOut()) :
    yield put(exportExcelProductsSuccess(data.data));
  } catch (error) {
    //yield put(showAuthMessage(error));
  }
}

function* processExportProducts() {
  yield takeEvery(PRODUCTS_EXPORT_EXCEL, exportProducts);
}

export default function* ProductSagas() {
  yield all([fork(processLoadProducts),
  fork(processLoadProduct),
  fork(processAddProduct),
  fork(processUpdateProduct),
  fork(processDeleteAllProducts),
  fork(processDeleteProduct),
  fork(processExportProducts)
]);
}
