import { all, call, fork, put, takeEvery } from "redux-saga/effects";
import Api, { patchData } from './Api';
import { GET_ALL_SIZE } from '../constants/AppConstant';

import {
  loadOrders as loadOrdersAction,
  loadOrdersSuccess,
  addOrderSuccess,
  updateOrderSuccess,
  exportExcelOrdersSuccess,
  reportOrdersSuccess
} from '../actions/Order';

import {
  ORDERS_LOAD,
  ORDER_ADD,
  ORDER_UPDATE,
  ORDER_DELETE,
  ORDER_DELETE_ALL,
  ORDERS_EXPORT_EXCEL,
  ORDERS_REPORT
} from '../constants/ActionTypes';

import {
  userSignOut
} from '../actions/Auth';

export const getOrderById = (id, callback) => {
  Api().get('order/' + id).then(response => {
    callback(response.data);
  })
}

export const getAllOrders = (callback, params) => {
  if (!params) {
    params = {
      limit: GET_ALL_SIZE
    }
  }

  Api().post('order/search', params)
    .then(response => {
      callback(response.data.records);        
    })
}

export const changeOrderStatus = (model, callback) => {
  Api().post('order/change-status', model).then(response => {
    callback(response);
  })
}

export const getOrdersByCompany = (companyId, callback) => {
  Api().post('order/search', {
    limit: GET_ALL_SIZE,
    filterGroups: [{
      filters: [{
        property: 'companyId',
        operator: 'equal',
        value: companyId
      }]
    }]
  }).then(response => {
    callback(response.data.records);    
  })
}

export const getMaxOrderCodeSorted = (type, callback) => {
  Api().post('order/search', { offset: 0,
    limit: 1,
    orderBy: "codeSorted",
    descending: true,
    filterGroups: [{
      filters: [{
        property: 'type',
        operator: '=',
        value: type
      }]
    }]
  })
    .then(response => {
      let codeSorted = 0;
      let records = response.data.records;
      if (records.length) {
        codeSorted = records[0].codeSorted;
      }
      callback(codeSorted);
    })
}

const loadOrdersRequest = async (params) => {
  if (params.filterGroups && params.filterGroups.length) {
    return await Api().post(`order/search`, params)
      .then(data => data)
      .catch(error => error);
  }

  return await Api().get(`order`, { params })
    .then(data => data)
    .catch(error => error);
}

const addOrderRequest = async (payload) =>
  await Api().post(`order/add`, payload)
    .then(data => data)
    .catch(error => error);

const updateOrderRequest = async (payload) =>
  await Api().patch(`order/update/${payload.id}`, patchData(payload))
    .then(data => data)
    .catch(error => error);

export const deleteOrderRequest = async (id) =>
  await Api().delete(`order/${id}`)
    .then(data => data)
    .catch(error => error);

function* loadOrders({ payload }) {
  try {
    const data = yield call(loadOrdersRequest, payload);
    data.status == 401 || data.response && data.response.status == 401 ? yield put(userSignOut()) :
    yield put(loadOrdersSuccess(data.data));
  } catch (error) {
    //yield put(showAuthMessage(error));
  }
}

function* processLoadOrders() {
  yield takeEvery(ORDERS_LOAD, loadOrders);
}

function* loadOrder({ selectedOrderId }) {
  try {
    const data = yield call(loadOrdersRequest, selectedOrderId);
    data.status == 401 || data.response && data.response.status == 401 ? yield put(userSignOut()) :
    yield put(loadOrdersSuccess(data.data));
  } catch (error) {
    //yield put(showAuthMessage(error));
  }
}

function* processLoadOrder() {
  yield takeEvery(ORDERS_LOAD, loadOrder);
}


function* addOrder({ payload }) {
  try {
    const data = yield call(addOrderRequest, payload.model);
    data.status == 401 || data.response && data.response.status == 401 ? yield put(userSignOut()) :
    yield put(addOrderSuccess(payload.filter));
  } catch (error) {
    //yield put(showAuthMessage(error));
  }
}

function* processAddOrder() {
  yield takeEvery(ORDER_ADD, addOrder);
}


function* updateOrder({ payload }) {
  try {
    const data = yield call(updateOrderRequest, payload.model);
    data.status == 401 || data.response && data.response.status == 401 ? yield put(userSignOut()) :
    yield put(updateOrderSuccess(payload.filter));
  } catch (error) {
    //yield put(showAuthMessage(error));
  }
}

function* processUpdateOrder() {
  yield takeEvery(ORDER_UPDATE, updateOrder);
}

function* deleteOrder({ payload }) {
  try {
    const data = yield call(deleteOrderRequest, payload.model.id);
    data.status == 401 || data.response && data.response.status == 401 ? yield put(userSignOut()) :
    yield put(loadOrdersAction(payload.filter));
  } catch (error) {
    //yield put(showAuthMessage(error));
  }
}

function* deleteAllOrders({ payload }) {
  try {
    for (let i = 0; i < payload.models.length; ++i) {
      const data = yield call(deleteOrderRequest, payload.models[i].id);
      if (data.status == 401 || data.response && data.response.status == 401) { yield put(userSignOut()); break; }
    }

    yield put(loadOrdersAction(payload.filter));
  } catch (error) {
    //yield put(showAuthMessage(error));
  }
}

function* processDeleteOrder() {
  yield takeEvery(ORDER_DELETE, deleteOrder);
}

function* processDeleteAllOrders() {
  yield takeEvery(ORDER_DELETE_ALL, deleteAllOrders);
}

const exportOrdersRequest = async (params) => {
  return await Api({responseType: 'blob'}).post(`order/export/excel`, params)
    .then(data => data)
    .catch(error => error);
}


function* exportOrders({ payload }) {
  try {
    const data = yield call(exportOrdersRequest, payload);
    data.status == 401 || data.response && data.response.status == 401 ? yield put(userSignOut()) :
    yield put(exportExcelOrdersSuccess(data.data));
  } catch (error) {
    //yield put(showAuthMessage(error));
  }
}

function* processExportOrders() {
  yield takeEvery(ORDERS_EXPORT_EXCEL, exportOrders);
}

const reportOrdersRequest = async (params) => {
  return await Api().post(`order/report`, params)
    .then(data => data)
    .catch(error => error);
}


function* reportOrders({ payload }) {
  try {
    if (!payload) { payload = {} }
    const data = yield call(reportOrdersRequest, payload);
    data.status == 401 || data.response && data.response.status == 401 ? yield put(userSignOut()) :
    yield put(reportOrdersSuccess(data.data));
  } catch (error) {
    //yield put(showAuthMessage(error));
  }
}

function* processReportOrders() {
  yield takeEvery(ORDERS_REPORT, reportOrders);
}

export default function* OrderSagas() {
  yield all([fork(processLoadOrders),
  fork(processLoadOrder),
  fork(processAddOrder),
  fork(processUpdateOrder),
  fork(processDeleteAllOrders),
  fork(processDeleteOrder),
  fork(processExportOrders),
  fork(processReportOrders)
]);
}
