import {
  QUOTATIONPRODUCTS_SELECT_ALL,
  QUOTATIONPRODUCTS_UNSELECT_ALL,
  QUOTATIONPRODUCT_SELECTED,
  QUOTATIONPRODUCTS_LOAD,
  QUOTATIONPRODUCTS_LOAD_SUCCESS,
  QUOTATIONPRODUCTS_LOAD_FAILED,
  QUOTATIONPRODUCT_LOAD,
  QUOTATIONPRODUCT_LOAD_SUCCESS,
  QUOTATIONPRODUCT_LOAD_FAILED,
  QUOTATIONPRODUCT_ADD,
  QUOTATIONPRODUCT_ADD_SUCCESS,
  QUOTATIONPRODUCT_ADD_FAILED,
  QUOTATIONPRODUCT_UPDATE,
  QUOTATIONPRODUCT_UPDATE_SUCCESS,
  QUOTATIONPRODUCT_UPDATE_FAILED,
  QUOTATIONPRODUCT_DELETE,
  QUOTATIONPRODUCT_DELETE_SUCCESS,
  QUOTATIONPRODUCT_DELETE_FAILED,
  QUOTATIONPRODUCT_DELETE_ALL,
  QUOTATIONPRODUCT_DELETE_ALL_SUCCESS,
  QUOTATIONPRODUCT_DELETE_ALL_FAILED
} from '../constants/ActionTypes';

export const selectAllQuotationProducts = () => {
  return {
    type: QUOTATIONPRODUCTS_SELECT_ALL
  }
}

export const unselectAllQuotationProducts = () => {
  return {
    type: QUOTATIONPRODUCTS_UNSELECT_ALL
  }
}

export const selectQuotationProduct = data => {
  return {
    type: QUOTATIONPRODUCT_SELECTED,
    payload: data
  }
}

export const loadQuotationProducts = (data) => {
  return {
    type: QUOTATIONPRODUCTS_LOAD,
    payload: data
  };
}

export const loadQuotationProductsSuccess = (data) => {
  return {
    type: QUOTATIONPRODUCTS_LOAD_SUCCESS,
    payload: data
  };
}

export const loadQuotationProductsFailed = () => {
  return {
    type: QUOTATIONPRODUCTS_LOAD_FAILED
  };
}

export const loadQuotationProduct = () => {
  return {
    type: QUOTATIONPRODUCT_LOAD
  };
}

export const loadQuotationProductSuccess = (data) => {
  return {
    type: QUOTATIONPRODUCT_LOAD_SUCCESS,
    payload: data
  };
}


export const loadQuotationProductFailed = () => {
  return {
    type: QUOTATIONPRODUCT_LOAD_FAILED
  };
}


export const addQuotationProduct = (data) => {
  return {
    type: QUOTATIONPRODUCT_ADD,
    payload: data
  };
}


export const addQuotationProductSuccess = (filter) => {
  return {
    type: QUOTATIONPRODUCT_ADD_SUCCESS,
    message: 'QuotationProduct added successfully.',
    payload: filter
  };
}

export const addQuotationProductFailed = () => {
  return {
    type: QUOTATIONPRODUCT_ADD_FAILED
  };
}

export const updateQuotationProduct = (data) => {
  return {
    type: QUOTATIONPRODUCT_UPDATE,
    payload: data
  };
}


export const updateQuotationProductSuccess = (data) => {
  return {
    type: QUOTATIONPRODUCT_UPDATE_SUCCESS,
    payload: data
  };
}

export const updateQuotationProductFailed = (data) => {
  return {
    type: QUOTATIONPRODUCT_UPDATE_FAILED,
    payload: data
  };
}

export const deleteQuotationProduct = (data) => {
  return {
    type: QUOTATIONPRODUCT_DELETE,
    payload: data
  };
}

export const deleteQuotationProductSuccess = () => {
  return {
    type: QUOTATIONPRODUCT_DELETE_SUCCESS,
    message: 'QuotationProduct deleted successfully.'
  };
}

export const deleteQuotationProductFailed = () => {
  return {
    type: QUOTATIONPRODUCT_DELETE_FAILED
  };
}

export const deleteAllQuotationProducts = (data) => {
  return {
    type: QUOTATIONPRODUCT_DELETE_ALL,
    payload: data
  };
}

export const deleteAllQuotationProductsSuccess = () => {
  return {
    type: QUOTATIONPRODUCT_DELETE_ALL_SUCCESS,
    message: 'QuotationProduct all deleted successfully.'
  };
}

export const deleteAllQuotationProductsFailed = () => {
  return {
    type: QUOTATIONPRODUCT_DELETE_ALL_FAILED
  };
}
