import { all, call, fork, put, takeEvery } from "redux-saga/effects";
import Api, { patchData } from './Api';
import { GET_ALL_SIZE } from '../constants/AppConstant';

import {
  loadSupplyForms as loadSupplyFormsAction,
  loadSupplyFormsSuccess,
  addSupplyFormSuccess,
  updateSupplyFormSuccess,
  exportExcelSupplyFormsSuccess
} from '../actions/SupplyForm';

import {
  SUPPLYFORMS_LOAD,
  SUPPLYFORM_ADD,
  SUPPLYFORM_UPDATE,
  SUPPLYFORM_DELETE,
  SUPPLYFORM_DELETE_ALL,
  SUPPLYFORMS_EXPORT_EXCEL
} from '../constants/ActionTypes';

import {
  userSignOut
} from '../actions/Auth';

export const getSupplyFormById = (id, callback) => {
  Api().get('supply-form/' + id).then(response => {
    callback(response.data);
  })
}

export const changeSupplyFormStatus = (model, callback) => {
  Api().post('supply-form/change-status', model).then(response => {
    callback(response);
  })
}

export const getAllSupplyForms = (callback) => {
  Api().post('supply-form/search', { limit: GET_ALL_SIZE })
    .then(response => {
      callback(response.data.records);
    })
}

export const getMaxSupplyFormCodeSorted = (type, callback) => {
  Api().post('supply-form/search', { offset: 0,
    limit: 1,
    orderBy: "codeSorted",
    descending: true,
    filterGroups: [{
      filters: [{
        property: 'type',
        operator: '=',
        value: type
      }]
    }]
  })
    .then(response => {
      let codeSorted = 0;
      let records = response.data.records;
      if (records.length) {
        codeSorted = records[0].codeSorted;
      }
      callback(codeSorted);
    })
}

const loadSupplyFormsRequest = async (params) => {
  if (params.filterGroups && params.filterGroups.length) {
    return await Api().post(`supply-form/search`, params)
      .then(data => data)
      .catch(error => error);
  }

  return await Api().get(`supply-form`, { params })
    .then(data => data)
    .catch(error => error);
}


const addSupplyFormRequest = async (payload) =>
  await Api().post(`supply-form`, payload)
    .then(data => data)
    .catch(error => error);

const updateSupplyFormRequest = async (payload) =>
  await Api().patch(`supply-form/${payload.id}`, patchData(payload))
    .then(data => data)
    .catch(error => error);

export const deleteSupplyFormRequest = async (id) =>
  await Api().delete(`supply-form/${id}`)
    .then(data => data)
    .catch(error => error);

const exportSupplyFormsRequest = async (params) => {
  return await Api({responseType: 'blob'}).post(`supply-form/export/excel`, params)
    .then(data => data)
    .catch(error => error);
}

function* loadSupplyForms({ payload }) {
  try {

    const data = yield call(loadSupplyFormsRequest, payload);
    data.status == 401 || data.response && data.response.status == 401 ? yield put(userSignOut()) :
    yield put(loadSupplyFormsSuccess(data.data));
  } catch (error) {
    //yield put(showAuthMessage(error));
  }
}

function* processLoadSupplyForms() {
  yield takeEvery(SUPPLYFORMS_LOAD, loadSupplyForms);
}

function* loadSupplyForm({ selectedSupplyFormId }) {
  try {

    const data = yield call(loadSupplyFormsRequest, selectedSupplyFormId);
    data.status == 401 || data.response && data.response.status == 401 ? yield put(userSignOut()) :
    yield put(loadSupplyFormsSuccess(data.data));
  } catch (error) {
    //yield put(showAuthMessage(error));
  }
}

function* processLoadSupplyForm() {
  yield takeEvery(SUPPLYFORMS_LOAD, loadSupplyForm);
}


function* addSupplyForm({ payload }) {
  try {
    const data = yield call(addSupplyFormRequest, payload.model);
    data.status == 401 || data.response && data.response.status == 401 ? yield put(userSignOut()) :
    yield put(addSupplyFormSuccess(data.data));
  } catch (error) {
    //yield put(showAuthMessage(error));
  }
}

function* processAddSupplyForm() {
  yield takeEvery(SUPPLYFORM_ADD, addSupplyForm);
}


function* updateSupplyForm({ payload }) {
  try {
    const data = yield call(updateSupplyFormRequest, payload.model);
    data.status == 401 || data.response && data.response.status == 401 ? yield put(userSignOut()) :
    yield put(updateSupplyFormSuccess(payload.filter));
  } catch (error) {
    //yield put(showAuthMessage(error));
  }
}

function* processUpdateSupplyForm() {
  yield takeEvery(SUPPLYFORM_UPDATE, updateSupplyForm);
}

function* deleteSupplyForm({ payload }) {
  try {
    const data = yield call(deleteSupplyFormRequest, payload.model.id);
    data.status == 401 || data.response && data.response.status == 401 ? yield put(userSignOut()) :
    yield put(loadSupplyFormsAction(payload.filter));
  } catch (error) {
    //yield put(showAuthMessage(error));
  }
}

function* deleteAllSupplyForms({ payload }) {
  try {
    for (let i = 0; i < payload.models.length; ++i) {
      const data = yield call(deleteSupplyFormRequest, payload.models[i].id);
      if (data.status == 401 || data.response && data.response.status == 401) { yield put(userSignOut()); break; }
    }

    yield put(loadSupplyFormsAction(payload.filter));
  } catch (error) {
    //yield put(showAuthMessage(error));
  }
}

function* processDeleteSupplyForm() {
  yield takeEvery(SUPPLYFORM_DELETE, deleteSupplyForm);
}

function* processDeleteAllSupplyForms() {
  yield takeEvery(SUPPLYFORM_DELETE_ALL, deleteAllSupplyForms);
}

function* exportSupplyForms({ payload }) {
  try {
    const data = yield call(exportSupplyFormsRequest, payload);
    data.status == 401 || data.response && data.response.status == 401 ? yield put(userSignOut()) :
    yield put(exportExcelSupplyFormsSuccess(data.data));
  } catch (error) {
    //yield put(showAuthMessage(error));
  }
}

function* processExportSupplyForms() {
  yield takeEvery(SUPPLYFORMS_EXPORT_EXCEL, exportSupplyForms);
}

export default function* SupplyFormSagas() {
  yield all([fork(processLoadSupplyForms),
  fork(processLoadSupplyForm),
  fork(processAddSupplyForm),
  fork(processUpdateSupplyForm),
  fork(processDeleteAllSupplyForms),
  fork(processDeleteSupplyForm),
  fork(processExportSupplyForms)]);
}
