import { all, call, fork, put, takeEvery } from "redux-saga/effects";
import Api, { patchData } from './Api';
import { GET_ALL_SIZE } from '../constants/AppConstant';

import {
  loadStoreProducts as loadStoreProductsAction,
  loadStoreProductsSuccess,
  addStoreProductSuccess,
  updateStoreProductSuccess,
  exportExcelStoreProductsSuccess,
  reportStoreProductsSuccess
} from '../actions/StoreProduct';

import {
  STOREPRODUCTS_LOAD,
  STOREPRODUCT_ADD,
  STOREPRODUCT_UPDATE,
  STOREPRODUCT_DELETE,
  STOREPRODUCT_DELETE_ALL,
  STOREPRODUCTS_EXPORT_EXCEL,
  STOREPRODUCTS_REPORT
} from '../constants/ActionTypes';

import {
  userSignOut
} from '../actions/Auth';

export const getStoreProductById = (id, callback) => {
  Api().get('store-product/' + id).then(response => {
    callback(response.data);
  })
}

export const getAllStoreProducts = (callback, params) => {
  if (!params) {
    params = {
      limit: GET_ALL_SIZE
    }
  }

  Api().post('store-product/search', params)
    .then(response => {
      callback(response.data.records);        
    })
}

export const getStoreProductsByCompany = (companyId, callback) => {
  Api().post('store-product/search', {
    limit: GET_ALL_SIZE,
    filterGroups: [{
      filters: [{
        property: 'companyId',
        operator: 'equal',
        value: companyId
      }]
    }]
  }).then(response => {
    callback(response.data.records);    
  })
}

export const getMaxStoreProductCodeSorted = (type, callback) => {
  Api().post('store-product/search', { offset: 0,
    limit: 1,
    storeProductBy: "codeSorted",
    descending: true,
    filterGroups: [{
      filters: [{
        property: 'type',
        operator: '=',
        value: type
      }]
    }]
  })
    .then(response => {
      let codeSorted = 0;
      let records = response.data.records;
      if (records.length) {
        codeSorted = records[0].codeSorted;
      }
      callback(codeSorted);
    })
}

export const getTotalMoney = (callback) => {
  Api().post('store-product/total-money')
    .then(response => {
      callback(response.data);
    })
}

const loadStoreProductsRequest = async (params) => {
  if (params.filterGroups && params.filterGroups.length) {
    return await Api().post(`store-product/search`, params)
      .then(data => data)
      .catch(error => error);
  }

  return await Api().get(`store-product`, { params })
    .then(data => data)
    .catch(error => error);
}

const addStoreProductRequest = async (payload) =>
  await Api().post(`store-product`, payload)
    .then(data => data)
    .catch(error => error);

const updateStoreProductRequest = async (payload) =>
  await Api().patch(`store-product/${payload.id}`, patchData(payload))
    .then(data => data)
    .catch(error => error);

export const deleteStoreProductRequest = async (id) =>
  await Api().delete(`store-product/${id}`)
    .then(data => data)
    .catch(error => error);

function* loadStoreProducts({ payload }) {
  try {
    const data = yield call(loadStoreProductsRequest, payload);
    data.status == 401 || data.response && data.response.status == 401 ? yield put(userSignOut()) :
    yield put(loadStoreProductsSuccess(data.data));
  } catch (error) {
    //yield put(showAuthMessage(error));
  }
}

function* processLoadStoreProducts() {
  yield takeEvery(STOREPRODUCTS_LOAD, loadStoreProducts);
}

function* loadStoreProduct({ selectedStoreProductId }) {
  try {
    const data = yield call(loadStoreProductsRequest, selectedStoreProductId);
    data.status == 401 || data.response && data.response.status == 401 ? yield put(userSignOut()) :
    yield put(loadStoreProductsSuccess(data.data));
  } catch (error) {
    //yield put(showAuthMessage(error));
  }
}

function* processLoadStoreProduct() {
  yield takeEvery(STOREPRODUCTS_LOAD, loadStoreProduct);
}


function* addStoreProduct({ payload }) {
  try {
    const data = yield call(addStoreProductRequest, payload.model);
    data.status == 401 || data.response && data.response.status == 401 ? yield put(userSignOut()) :
    yield put(addStoreProductSuccess(payload.filter));
  } catch (error) {
    //yield put(showAuthMessage(error));
  }
}

function* processAddStoreProduct() {
  yield takeEvery(STOREPRODUCT_ADD, addStoreProduct);
}


function* updateStoreProduct({ payload }) {
  try {
    const data = yield call(updateStoreProductRequest, payload.model);
    data.status == 401 || data.response && data.response.status == 401 ? yield put(userSignOut()) :
    yield put(updateStoreProductSuccess(payload.filter));
  } catch (error) {
    //yield put(showAuthMessage(error));
  }
}

function* processUpdateStoreProduct() {
  yield takeEvery(STOREPRODUCT_UPDATE, updateStoreProduct);
}

function* deleteStoreProduct({ payload }) {
  try {
    const data = yield call(deleteStoreProductRequest, payload.model.id);
    data.status == 401 || data.response && data.response.status == 401 ? yield put(userSignOut()) :
    yield put(loadStoreProductsAction(payload.filter));
  } catch (error) {
    //yield put(showAuthMessage(error));
  }
}

function* deleteAllStoreProducts({ payload }) {
  try {
    for (let i = 0; i < payload.models.length; ++i) {
      const data = yield call(deleteStoreProductRequest, payload.models[i].id);
      if (data.status == 401 || data.response && data.response.status == 401) { yield put(userSignOut()); break; }
    }

    yield put(loadStoreProductsAction(payload.filter));
  } catch (error) {
    //yield put(showAuthMessage(error));
  }
}

function* processDeleteStoreProduct() {
  yield takeEvery(STOREPRODUCT_DELETE, deleteStoreProduct);
}

function* processDeleteAllStoreProducts() {
  yield takeEvery(STOREPRODUCT_DELETE_ALL, deleteAllStoreProducts);
}

const exportStoreProductsRequest = async (params) => {
  return await Api({responseType: 'blob'}).post(`store-product/export/excel`, params)
    .then(data => data)
    .catch(error => error);
}


function* exportStoreProducts({ payload }) {
  try {
    const data = yield call(exportStoreProductsRequest, payload);
    data.status == 401 || data.response && data.response.status == 401 ? yield put(userSignOut()) :
    yield put(exportExcelStoreProductsSuccess(data.data));
  } catch (error) {
    //yield put(showAuthMessage(error));
  }
}

function* processExportStoreProducts() {
  yield takeEvery(STOREPRODUCTS_EXPORT_EXCEL, exportStoreProducts);
}

const reportStoreProductsRequest = async (params) => {
  return await Api().post(`store-product/report`, params)
    .then(data => data)
    .catch(error => error);
}


function* reportStoreProducts({ payload }) {
  try {
    if (!payload) { payload = {} }
    const data = yield call(reportStoreProductsRequest, payload);
    data.status == 401 || data.response && data.response.status == 401 ? yield put(userSignOut()) :
    yield put(reportStoreProductsSuccess(data.data));
  } catch (error) {
    //yield put(showAuthMessage(error));
  }
}

function* processReportStoreProducts() {
  yield takeEvery(STOREPRODUCTS_REPORT, reportStoreProducts);
}

export default function* StoreProductSagas() {
  yield all([fork(processLoadStoreProducts),
  fork(processLoadStoreProduct),
  fork(processAddStoreProduct),
  fork(processUpdateStoreProduct),
  fork(processDeleteAllStoreProducts),
  fork(processDeleteStoreProduct),
  fork(processExportStoreProducts),
  fork(processReportStoreProducts)
]);
}
