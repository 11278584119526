import {
  ACCOUNTLEDGERS_SELECT_ALL,
  ACCOUNTLEDGERS_UNSELECT_ALL,
  ACCOUNTLEDGER_SELECTED,
  ACCOUNTLEDGERS_LOAD,
  ACCOUNTLEDGERS_LOAD_SUCCESS,
  ACCOUNTLEDGERS_LOAD_FAILED,
  ACCOUNTLEDGER_LOAD,
  ACCOUNTLEDGER_LOAD_SUCCESS,
  ACCOUNTLEDGER_LOAD_FAILED,
  ACCOUNTLEDGER_ADD,
  ACCOUNTLEDGER_ADD_SUCCESS,
  ACCOUNTLEDGER_ADD_FAILED,
  ACCOUNTLEDGER_UPDATE,
  ACCOUNTLEDGER_UPDATE_SUCCESS,
  ACCOUNTLEDGER_UPDATE_FAILED,
  ACCOUNTLEDGER_DELETE,
  ACCOUNTLEDGER_DELETE_SUCCESS,
  ACCOUNTLEDGER_DELETE_FAILED,
  ACCOUNTLEDGER_DELETE_ALL,
  ACCOUNTLEDGER_DELETE_ALL_SUCCESS,
  ACCOUNTLEDGER_DELETE_ALL_FAILED,
  ACCOUNTLEDGERS_EXPORT_EXCEL,
  ACCOUNTLEDGERS_EXPORT_EXCEL_SUCCESS,
  ACCOUNTLEDGERS_EXPORT_EXCEL_FAILED,
  ACCOUNTLEDGERS_REPORT,
  ACCOUNTLEDGERS_REPORT_SUCCESS,
  ACCOUNTLEDGERS_REPORT_FAILED,
  ACCOUNTLEDGERS_REPORTSUM_SUCCESS
} from '../constants/ActionTypes';
import { PAGE_SIZES } from '../constants/AppConstant';
import Moment from 'moment';

const INIT_STATE = {
  paging: {
    records: [],
    offset: 0,
    limit: PAGE_SIZES,
    totalRecords: 0
  },
  reportData: [],
  reportSumData: [],
  selectedAccountLedger: null,
  selectedAccountLedgerId: null,
  message: null,
  messageType: 'info',
  filter: {
    offset: 0,
    limit: PAGE_SIZES,
    orderBy: 'id',
    descending: true,
    filterGroups: null
  },
  hideLoading: true
};

export default (state = INIT_STATE, action) => {
  switch (action.type) {
    case ACCOUNTLEDGERS_SELECT_ALL:
      return {
        ...state,
        paging: {
          ...state.paging,
          records: state.paging.records.map(data => {
            data.selected = true;
            return data;
          })
        }
      }
    case ACCOUNTLEDGERS_UNSELECT_ALL:
      return {
        ...state,
        paging: {
          ...state.paging,
          records: state.paging.records.map(data => {
            data.selected = false;
            return data;
          })
        }
      }
    case ACCOUNTLEDGER_SELECTED:
      return {
        ...state,
        selectedAccountLedger: action.payload,
        selectedAccountLedgerId: action.payload.id
      };
    case ACCOUNTLEDGERS_LOAD:
      return {
        ...state,
        filter: action.payload ? action.payload : state.filter
      };
    case ACCOUNTLEDGERS_LOAD_SUCCESS:
      return {
        ...state,
        paging: action.payload
      };
    case ACCOUNTLEDGERS_LOAD_FAILED:
      return {
        ...state
      };
    case ACCOUNTLEDGER_LOAD:
      return {
        ...state
      };
    case ACCOUNTLEDGER_LOAD_SUCCESS:
      return {
        ...state
      };
    case ACCOUNTLEDGER_LOAD_FAILED:
      return {
        ...state
      };
    case ACCOUNTLEDGER_ADD:
      return {
        ...state,
        hideLoading: false
      };
    case ACCOUNTLEDGER_ADD_SUCCESS:
      return {
        ...state,
        hideLoading: true
      };
    case ACCOUNTLEDGER_ADD_FAILED:
      return {
        ...state,
        hideLoading: true
      };
    case ACCOUNTLEDGER_UPDATE:
      return {
        ...state,
        hideLoading: false
      };
    case ACCOUNTLEDGER_UPDATE_SUCCESS:
      return {
        ...state,
        hideLoading: true
      };
    case ACCOUNTLEDGER_UPDATE_FAILED:
      return {
        ...state,
        hideLoading: true
      };
    case ACCOUNTLEDGER_DELETE:
      return {
        ...state
      };
    case ACCOUNTLEDGER_DELETE_SUCCESS:
      return {
        ...state,
        message: action.message
      };
    case ACCOUNTLEDGER_DELETE_FAILED:
      return {
        ...state
      };
    case ACCOUNTLEDGER_DELETE_ALL:
      return {
        ...state
      };
    case ACCOUNTLEDGER_DELETE_ALL_SUCCESS:
      return {
        ...state,
        message: action.message
      };
    case ACCOUNTLEDGER_DELETE_ALL_FAILED:
      return {
        ...state
      };
    case ACCOUNTLEDGERS_EXPORT_EXCEL:
      return {
        ...state,
        excelBlob: null
      };
    case ACCOUNTLEDGERS_EXPORT_EXCEL_SUCCESS:
      return {
        ...state,
        excelBlob: action.payload
      };
    case ACCOUNTLEDGERS_EXPORT_EXCEL_FAILED:
      return {
        ...state,
        excelBlob: null
      };
    case ACCOUNTLEDGERS_REPORT:
      return {
        ...state,
        reportData: []
      };
    case ACCOUNTLEDGERS_REPORT_SUCCESS:
      let reportData = action.payload;
      let result = [];
      for (var d = Moment(reportData.filter.startDate).format('YYYY-MM-1'); d <= Moment(reportData.filter.endDate).format('YYYY-MM-1'); d = Moment(d).add(1, 'months').format('YYYY-MM-1')) {
        let date = Moment(d);
        let data = reportData.data.filter(r => r.key.month == date.format('M') && r.key.year == date.format('YYYY'));

        let receiveData = data.filter(r => r.key.types == 1);
        if (receiveData.length) {
          result.push(receiveData[0]);
        } else {
          result.push({
            key: {
              month: parseInt(date.format('M')),
              year: parseInt(date.format('YYYY')),
              types: 1
            },
            value: 0
          })
        }

        let payData = data.filter(r => r.key.types == 2);
        if (payData.length) {
          result.push(payData[0]);
        } else {
          result.push({
            key: {
              month: parseInt(date.format('M')),
              year: parseInt(date.format('YYYY')),
              types: 2
            },
            value: 0
          })
        }
      }

      return {
        ...state,
        reportData: result
      };
    case ACCOUNTLEDGERS_REPORT_FAILED:
      return {
        ...state,
        reportData: []
      };
      case ACCOUNTLEDGERS_REPORTSUM_SUCCESS:
        return {
          ...state,
          reportSumData: action.payload
        };
    default:
      return state;
  }
}
