import {
    ORDERPRODUCTS_SELECT_ALL,
    ORDERPRODUCTS_UNSELECT_ALL,
    ORDERPRODUCT_SELECTED,
    ORDERPRODUCTS_LOAD,
    ORDERPRODUCTS_LOAD_SUCCESS,
    ORDERPRODUCTS_LOAD_FAILED,
    ORDERPRODUCT_LOAD,
    ORDERPRODUCT_LOAD_SUCCESS,
    ORDERPRODUCT_LOAD_FAILED,
    ORDERPRODUCT_ADD,
    ORDERPRODUCT_ADD_SUCCESS,
    ORDERPRODUCT_ADD_FAILED,
    ORDERPRODUCT_UPDATE,
    ORDERPRODUCT_UPDATE_SUCCESS,
    ORDERPRODUCT_UPDATE_FAILED,
    ORDERPRODUCT_DELETE,
    ORDERPRODUCT_DELETE_SUCCESS,
    ORDERPRODUCT_DELETE_FAILED,
    ORDERPRODUCT_DELETE_ALL,
    ORDERPRODUCT_DELETE_ALL_SUCCESS,
    ORDERPRODUCT_DELETE_ALL_FAILED,
    ORDERPRODUCTS_EXPORT_EXCEL,
    ORDERPRODUCTS_EXPORT_EXCEL_SUCCESS,
    ORDERPRODUCTS_EXPORT_EXCEL_FAILED,
    ORDERPRODUCTS_REPORT,
    ORDERPRODUCTS_REPORT_SUCCESS,
    ORDERPRODUCTS_REPORT_FAILED
  } from '../constants/ActionTypes';
  import { PAGE_SIZES } from '../constants/AppConstant';
  
  const INIT_STATE = {
    paging: {
      records: [],
      offset: 0,
      limit: PAGE_SIZES,
      totalRecords: 0
    },
    reportData: [],
    selectedOrderProduct: null,
    selectedOrderProductId: null,
    message: null,
    messageType: 'info',
    filter: {
      offset: 0,
      limit: PAGE_SIZES,
      orderBy: 'id',
      descending: true,
      filterGroups: null
    },
    hideLoading: true
  };
  
  export default (state = INIT_STATE, action) => {
    switch (action.type) {
      case ORDERPRODUCTS_SELECT_ALL:
        return {
          ...state,
          paging: {
            ...state.paging,
            records: state.paging.records.map(data => {
              data.selected = true;
              return data;
            })
          }
        }
      case ORDERPRODUCTS_UNSELECT_ALL:
        return {
          ...state,
          paging: {
            ...state.paging,
            records: state.paging.records.map(data => {
              data.selected = false;
              return data;
            })
          }
        }
      case ORDERPRODUCT_SELECTED:
        return {
          ...state,
          selectedOrderProduct: action.payload,
          selectedOrderProductId: action.payload.id
        };
      case ORDERPRODUCTS_LOAD:
        return {
          ...state,
          filter: action.payload ? action.payload : state.filter
        };
      case ORDERPRODUCTS_LOAD_SUCCESS:
        return {
          ...state,
          paging: action.payload
        };
      case ORDERPRODUCTS_LOAD_FAILED:
        return {
          ...state
        };
      case ORDERPRODUCT_LOAD:
        return {
          ...state
        };
      case ORDERPRODUCT_LOAD_SUCCESS:
        return {
          ...state
        };
      case ORDERPRODUCT_LOAD_FAILED:
        return {
          ...state
        };
      case ORDERPRODUCT_ADD:
        return {
          ...state,
          hideLoading: false
        };
      case ORDERPRODUCT_ADD_SUCCESS:
        return {
          ...state,
          hideLoading: true
        };
      case ORDERPRODUCT_ADD_FAILED:
        return {
          ...state,
          hideLoading: true
        };
      case ORDERPRODUCT_UPDATE:
        return {
          ...state,
          hideLoading: false
        };
      case ORDERPRODUCT_UPDATE_SUCCESS:
        return {
          ...state,
          hideLoading: true
        };
      case ORDERPRODUCT_UPDATE_FAILED:
        return {
          ...state,
          hideLoading: true
        };
      case ORDERPRODUCT_DELETE:
        return {
          ...state
        };
      case ORDERPRODUCT_DELETE_SUCCESS:
        return {
          ...state,
          message: action.message
        };
      case ORDERPRODUCT_DELETE_FAILED:
        return {
          ...state
        };
      case ORDERPRODUCT_DELETE_ALL:
        return {
          ...state
        };
      case ORDERPRODUCT_DELETE_ALL_SUCCESS:
        return {
          ...state,
          message: action.message
        };
      case ORDERPRODUCT_DELETE_ALL_FAILED:
        return {
          ...state
        };
      case ORDERPRODUCTS_EXPORT_EXCEL:
        return {
          ...state,
          excelBlob: null
        };
      case ORDERPRODUCTS_EXPORT_EXCEL_SUCCESS:
        return {
          ...state,
          excelBlob: action.payload
        };
      case ORDERPRODUCTS_EXPORT_EXCEL_FAILED:
        return {
          ...state,
          excelBlob: null
        };
      case ORDERPRODUCTS_REPORT:
        return {
          ...state,
          reportData: []
        };
      case ORDERPRODUCTS_REPORT_SUCCESS:
        return {
          ...state,
          reportData: action.payload
        };
      case ORDERPRODUCTS_REPORT_FAILED:
        return {
          ...state,
          reportData: []
        };
      default:
        return state;
    }
  }
  