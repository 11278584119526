import {
    STOREPRODUCTS_SELECT_ALL,
    STOREPRODUCTS_UNSELECT_ALL,
    STOREPRODUCT_SELECTED,
    STOREPRODUCTS_LOAD,
    STOREPRODUCTS_LOAD_SUCCESS,
    STOREPRODUCTS_LOAD_FAILED,
    STOREPRODUCT_LOAD,
    STOREPRODUCT_LOAD_SUCCESS,
    STOREPRODUCT_LOAD_FAILED,
    STOREPRODUCT_ADD,
    STOREPRODUCT_ADD_SUCCESS,
    STOREPRODUCT_ADD_FAILED,
    STOREPRODUCT_UPDATE,
    STOREPRODUCT_UPDATE_SUCCESS,
    STOREPRODUCT_UPDATE_FAILED,
    STOREPRODUCT_DELETE,
    STOREPRODUCT_DELETE_SUCCESS,
    STOREPRODUCT_DELETE_FAILED,
    STOREPRODUCT_DELETE_ALL,
    STOREPRODUCT_DELETE_ALL_SUCCESS,
    STOREPRODUCT_DELETE_ALL_FAILED,
    STOREPRODUCTS_EXPORT_EXCEL,
    STOREPRODUCTS_EXPORT_EXCEL_SUCCESS,
    STOREPRODUCTS_EXPORT_EXCEL_FAILED,
    STOREPRODUCTS_REPORT,
    STOREPRODUCTS_REPORT_SUCCESS,
    STOREPRODUCTS_REPORT_FAILED
  } from '../constants/ActionTypes';
  
  export const selectAllStoreProducts = () => {
    return {
      type: STOREPRODUCTS_SELECT_ALL
    }
  }
  
  export const unselectAllStoreProducts = () => {
    return {
      type: STOREPRODUCTS_UNSELECT_ALL
    }
  }
  
  export const selectStoreProduct = data => {
    return {
      type: STOREPRODUCT_SELECTED,
      payload: data
    }
  }
  
  export const loadStoreProducts = (data) => {
    return {
      type: STOREPRODUCTS_LOAD,
      payload: data
    };
  }
  
  export const loadStoreProductsSuccess = (data) => {
    return {
      type: STOREPRODUCTS_LOAD_SUCCESS,
      payload: data
    };
  }
  
  export const loadStoreProductsFailed = () => {
    return {
      type: STOREPRODUCTS_LOAD_FAILED
    };
  }
  
  export const loadStoreProduct = () => {
    return {
      type: STOREPRODUCT_LOAD
    };
  }
  
  export const loadStoreProductSuccess = (data) => {
    return {
      type: STOREPRODUCT_LOAD_SUCCESS,
      payload: data
    };
  }
  
  
  export const loadStoreProductFailed = () => {
    return {
      type: STOREPRODUCT_LOAD_FAILED
    };
  }
  
  
  export const addStoreProduct = (data) => {
    return {
      type: STOREPRODUCT_ADD,
      payload: data
    };
  }
  
  
  export const addStoreProductSuccess = (filter) => {
    return {
      type: STOREPRODUCT_ADD_SUCCESS,
      message: 'StoreProduct added successfully.',
      payload: filter
    };
  }
  
  export const addStoreProductFailed = () => {
    return {
      type: STOREPRODUCT_ADD_FAILED
    };
  }
  
  export const updateStoreProduct = (data) => {
    return {
      type: STOREPRODUCT_UPDATE,
      payload: data
    };
  }
  
  
  export const updateStoreProductSuccess = (data) => {
    return {
      type: STOREPRODUCT_UPDATE_SUCCESS,
      payload: data
    };
  }
  
  export const updateStoreProductFailed = (data) => {
    return {
      type: STOREPRODUCT_UPDATE_FAILED,
      payload: data
    };
  }
  
  export const deleteStoreProduct = (data) => {
    return {
      type: STOREPRODUCT_DELETE,
      payload: data
    };
  }
  
  export const deleteStoreProductSuccess = () => {
    return {
      type: STOREPRODUCT_DELETE_SUCCESS,
      message: 'StoreProduct deleted successfully.'
    };
  }
  
  export const deleteStoreProductFailed = () => {
    return {
      type: STOREPRODUCT_DELETE_FAILED
    };
  }
  
  export const deleteAllStoreProducts = (data) => {
    return {
      type: STOREPRODUCT_DELETE_ALL,
      payload: data
    };
  }
  
  export const deleteAllStoreProductsSuccess = () => {
    return {
      type: STOREPRODUCT_DELETE_ALL_SUCCESS,
      message: 'StoreProduct all deleted successfully.'
    };
  }
  
  export const deleteAllStoreProductsFailed = () => {
    return {
      type: STOREPRODUCT_DELETE_ALL_FAILED
    };
  }
  
  export const exportExcelStoreProducts = (filter) => {
    return {
      type: STOREPRODUCTS_EXPORT_EXCEL,
      payload: filter
    }
  }
  
  export const exportExcelStoreProductsSuccess = (data) => {
    return {
      type: STOREPRODUCTS_EXPORT_EXCEL_SUCCESS,
      payload: data
    };
  }
  
  export const exportExcelStoreProductsFailed = () => {
    return {
      type: STOREPRODUCTS_EXPORT_EXCEL_FAILED
    };
  }
  
  export const reportStoreProducts = (filter) => {
    return {
      type: STOREPRODUCTS_REPORT,
      payload: filter
    }
  }
  
  export const reportStoreProductsSuccess = (data) => {
    return {
      type: STOREPRODUCTS_REPORT_SUCCESS,
      payload: data
    };
  }
  export const reportStoreProductsFailed = () => {
    return {
      type: STOREPRODUCTS_REPORT_FAILED
    };
  }