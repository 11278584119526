import {
    ORDERPRODUCTS_SELECT_ALL,
    ORDERPRODUCTS_UNSELECT_ALL,
    ORDERPRODUCT_SELECTED,
    ORDERPRODUCTS_LOAD,
    ORDERPRODUCTS_LOAD_SUCCESS,
    ORDERPRODUCTS_LOAD_FAILED,
    ORDERPRODUCT_LOAD,
    ORDERPRODUCT_LOAD_SUCCESS,
    ORDERPRODUCT_LOAD_FAILED,
    ORDERPRODUCT_ADD,
    ORDERPRODUCT_ADD_SUCCESS,
    ORDERPRODUCT_ADD_FAILED,
    ORDERPRODUCT_UPDATE,
    ORDERPRODUCT_UPDATE_SUCCESS,
    ORDERPRODUCT_UPDATE_FAILED,
    ORDERPRODUCT_DELETE,
    ORDERPRODUCT_DELETE_SUCCESS,
    ORDERPRODUCT_DELETE_FAILED,
    ORDERPRODUCT_DELETE_ALL,
    ORDERPRODUCT_DELETE_ALL_SUCCESS,
    ORDERPRODUCT_DELETE_ALL_FAILED,
    ORDERPRODUCTS_EXPORT_EXCEL,
    ORDERPRODUCTS_EXPORT_EXCEL_SUCCESS,
    ORDERPRODUCTS_EXPORT_EXCEL_FAILED,
    ORDERPRODUCTS_REPORT,
    ORDERPRODUCTS_REPORT_SUCCESS,
    ORDERPRODUCTS_REPORT_FAILED
  } from '../constants/ActionTypes';
  
  export const selectAllOrderProducts = () => {
    return {
      type: ORDERPRODUCTS_SELECT_ALL
    }
  }
  
  export const unselectAllOrderProducts = () => {
    return {
      type: ORDERPRODUCTS_UNSELECT_ALL
    }
  }
  
  export const selectOrderProduct = data => {
    return {
      type: ORDERPRODUCT_SELECTED,
      payload: data
    }
  }
  
  export const loadOrderProducts = (data) => {
    return {
      type: ORDERPRODUCTS_LOAD,
      payload: data
    };
  }
  
  export const loadOrderProductsSuccess = (data) => {
    return {
      type: ORDERPRODUCTS_LOAD_SUCCESS,
      payload: data
    };
  }
  
  export const loadOrderProductsFailed = () => {
    return {
      type: ORDERPRODUCTS_LOAD_FAILED
    };
  }
  
  export const loadOrderProduct = () => {
    return {
      type: ORDERPRODUCT_LOAD
    };
  }
  
  export const loadOrderProductSuccess = (data) => {
    return {
      type: ORDERPRODUCT_LOAD_SUCCESS,
      payload: data
    };
  }
  
  
  export const loadOrderProductFailed = () => {
    return {
      type: ORDERPRODUCT_LOAD_FAILED
    };
  }
  
  
  export const addOrderProduct = (data) => {
    return {
      type: ORDERPRODUCT_ADD,
      payload: data
    };
  }
  
  
  export const addOrderProductSuccess = (filter) => {
    return {
      type: ORDERPRODUCT_ADD_SUCCESS,
      message: 'OrderProduct added successfully.',
      payload: filter
    };
  }
  
  export const addOrderProductFailed = () => {
    return {
      type: ORDERPRODUCT_ADD_FAILED
    };
  }
  
  export const updateOrderProduct = (data) => {
    return {
      type: ORDERPRODUCT_UPDATE,
      payload: data
    };
  }
  
  
  export const updateOrderProductSuccess = (data) => {
    return {
      type: ORDERPRODUCT_UPDATE_SUCCESS,
      payload: data
    };
  }
  
  export const updateOrderProductFailed = (data) => {
    return {
      type: ORDERPRODUCT_UPDATE_FAILED,
      payload: data
    };
  }
  
  export const deleteOrderProduct = (data) => {
    return {
      type: ORDERPRODUCT_DELETE,
      payload: data
    };
  }
  
  export const deleteOrderProductSuccess = () => {
    return {
      type: ORDERPRODUCT_DELETE_SUCCESS,
      message: 'OrderProduct deleted successfully.'
    };
  }
  
  export const deleteOrderProductFailed = () => {
    return {
      type: ORDERPRODUCT_DELETE_FAILED
    };
  }
  
  export const deleteAllOrderProducts = (data) => {
    return {
      type: ORDERPRODUCT_DELETE_ALL,
      payload: data
    };
  }
  
  export const deleteAllOrderProductsSuccess = () => {
    return {
      type: ORDERPRODUCT_DELETE_ALL_SUCCESS,
      message: 'OrderProduct all deleted successfully.'
    };
  }
  
  export const deleteAllOrderProductsFailed = () => {
    return {
      type: ORDERPRODUCT_DELETE_ALL_FAILED
    };
  }
  
  export const exportExcelOrderProducts = (filter) => {
    return {
      type: ORDERPRODUCTS_EXPORT_EXCEL,
      payload: filter
    }
  }
  
  export const exportExcelOrderProductsSuccess = (data) => {
    return {
      type: ORDERPRODUCTS_EXPORT_EXCEL_SUCCESS,
      payload: data
    };
  }
  
  export const exportExcelOrderProductsFailed = () => {
    return {
      type: ORDERPRODUCTS_EXPORT_EXCEL_FAILED
    };
  }
  
  export const reportOrderProducts = (filter) => {
    return {
      type: ORDERPRODUCTS_REPORT,
      payload: filter
    }
  }
  
  export const reportOrderProductsSuccess = (data) => {
    return {
      type: ORDERPRODUCTS_REPORT_SUCCESS,
      payload: data
    };
  }
  export const reportOrderProductsFailed = () => {
    return {
      type: ORDERPRODUCTS_REPORT_FAILED
    };
  }