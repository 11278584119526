import { all } from "redux-saga/effects";
import settingSagas from './Setting';
import companySagas from './Company';
import departmentSagas from './Department';
import employeeSagas from './Employee';
import personalPaperSagas from './PersonalPaper';
import paymentSagas from './Payment';
import contractSagas from './Contract';
import quotationSagas from './Quotation';
import workLocationSagas from './WorkLocation';
import customerSagas from './Customer';
import customerRegSagas from './CustomerReg';
import leaveTrackSagas from './LeaveTrack';
import leaveTrackHistorySagas from './LeaveTrackHistory';
import sampleSages from './Sample';
import authSaga from './Auth';
import userSagas from './User';
import roleSagas from './Role';
import jobSagas from './Job';
import mailSagas from './Mail';
import mailToUserSagas from './MailToUser';
import templateSagas from './Template';
import invoiceSagas from './Invoice';
import albumSagas from './Album';
import mediaSagas from './Media';
import librarySagas from './Library';
import notificationSagas from './Notification';
import partnerSagas from './Partner';
import projectSagas from './Project';
import documentSagas from './Document';
import documentCategorySagas from './DocumentCategory';
import personalMailSagas from './PersonalMail';
import productSagas from './Product';
import supplySagas from './Supply';
import salarySettingSagas from './SalarySetting';
import salaryHistorySagas from './SalaryHistory';
import approvalSagas from './Approval';
import advanceSalarySagas from './AdvanceSalary';
import ledgerSagas from './Ledger';
import paymentVoucherSagas from './PaymentVoucher';
import receiptVoucherSagas from './ReceiptVoucher';
import productImportVoucherSagas from './ProductImportVoucher';
import productExportVoucherSagas from './ProductExportVoucher';
import supplyImportVoucherSagas from './SupplyImportVoucher';
import supplyExportVoucherSagas from './SupplyExportVoucher';
import providerSagas from './Provider';
import orderSagas from './Order';
import orderProductSaga from './OrderProduct';
import categorySagas from './Category';
import storeSagas from './Store';
import storeProductSaga from './StoreProduct';
import supplyVoucherItemSagas from './SupplyVoucherItem';
import supplyFormSagas from './SupplyForm';
import supplyFormItemSagas from './SupplyFormItem';
import timeKeepSagas from './TimeKeep';
import quotationProductSagas from './QuotationProduct';
import accountLedgerSagas from './AccountLedger';
import orderStoreSagas from './OrderStore';
import orderStoreProductSagas from './OrderStoreProduct';

export default function* rootSaga(getState) {
  yield all([
    settingSagas(),
    companySagas(),
    departmentSagas(),
    employeeSagas(),
    personalPaperSagas(),
    paymentSagas(),
    contractSagas(),
    quotationSagas(),
    workLocationSagas(),
    customerSagas(),
    customerRegSagas(),
    leaveTrackSagas(),
    leaveTrackHistorySagas(),
    sampleSages(),
    authSaga(),
    userSagas(),
    roleSagas(),
    jobSagas(),
    mailSagas(),
    mailToUserSagas(),
    templateSagas(),
    invoiceSagas(),
    albumSagas(),
    mediaSagas(),
    librarySagas(),
    notificationSagas(),
    partnerSagas(),
    projectSagas(),
    documentSagas(),
    documentCategorySagas(),
    personalMailSagas(),
    productSagas(),
    supplySagas(),
    salarySettingSagas(),
    salaryHistorySagas(),
    approvalSagas(),
    advanceSalarySagas(),
    ledgerSagas(),
    paymentVoucherSagas(),
    receiptVoucherSagas(),
    productImportVoucherSagas(),
    productExportVoucherSagas(),
    supplyImportVoucherSagas(),
    supplyExportVoucherSagas(),
    providerSagas(),
    orderSagas(),
    orderProductSaga(),
    categorySagas(),
    storeSagas(),
    storeProductSaga(),
    supplyVoucherItemSagas(),
    supplyFormSagas(),
    supplyFormItemSagas(),
    timeKeepSagas(),
    quotationProductSagas(),
    accountLedgerSagas(),
    orderStoreSagas(),
    orderStoreProductSagas(),
  ]);
}
