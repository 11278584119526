import { all, call, fork, put, takeEvery } from "redux-saga/effects";
import Api, { patchData } from './Api';
import { GET_ALL_SIZE } from '../constants/AppConstant';

import {
  loadQuotationProducts as loadQuotationProductsAction,
  loadQuotationProductsSuccess,
  updateQuotationProductSuccess,
  addQuotationProductSuccess,
  deleteQuotationProductSuccess,
  deleteAllQuotationProductsSuccess
} from '../actions/QuotationProduct';

import {
  QUOTATIONPRODUCTS_LOAD,
  QUOTATIONPRODUCT_ADD,
  QUOTATIONPRODUCT_UPDATE,
  QUOTATIONPRODUCT_DELETE,
  QUOTATIONPRODUCT_DELETE_ALL,
} from '../constants/ActionTypes';

import {
  userSignOut
} from '../actions/Auth';

export const getQuotationProductById = (id, callback) => {
  Api().get('quotation-product/' + id).then(response => {
    callback(response.data);
  })
}

export const getAllQuotationProducts = (callback, params) => {
  if (!params) {
    params = {
      offset: 0,
      limit: GET_ALL_SIZE,
      orderBy: 'id',
      descending: false
    }
  }
  
  Api().post('quotation-product/search', {
    ...params,
    offset: 0,
    limit: GET_ALL_SIZE
  })
    .then(response => {
      callback(response.data.records);
    })
}

const loadQuotationProductsRequest = async (params) => {
  if (params.filterGroups && params.filterGroups.length) {
    return await Api().post(`quotation-product/search`, params)
      .then(data => data)
      .catch(error => error);
  }

  return await Api().get(`quotation-product`, { params })
    .then(data => data)
    .catch(error => error);
}

const addQuotationProductRequest = async (payload) =>
  await Api().post(`quotation-product`, payload)
    .then(data => data)
    .catch(error => error);

const updateQuotationProductRequest = async (payload) =>
  await Api().patch(`quotation-product/${payload.id}`, patchData(payload))
    .then(data => data)
    .catch(error => error);

export const deleteQuotationProductRequest = async (id) =>
  await Api().delete(`quotation-product/${id}`)
    .then(data => data)
    .catch(error => error);

function* loadQuotationProducts({ payload }) {
  try {
    const data = yield call(loadQuotationProductsRequest, payload);
    data.status == 401 || data.response && data.response.status == 401 ? yield put(userSignOut()) :
      yield put(loadQuotationProductsSuccess(data.data));
  } catch (error) {
    //yield put(showAuthMessage(error));
  }
}

function* processLoadQuotationProducts() {
  yield takeEvery(QUOTATIONPRODUCTS_LOAD, loadQuotationProducts);
}

function* loadQuotationProduct({ selectedQuotationProductId }) {
  try {
    const data = yield call(loadQuotationProductsRequest, selectedQuotationProductId);
    data.status == 401 || data.response && data.response.status == 401 ? yield put(userSignOut()) :
      yield put(loadQuotationProductsSuccess(data.data));
  } catch (error) {
    //yield put(showAuthMessage(error));
  }
}

function* processLoadQuotationProduct() {
  yield takeEvery(QUOTATIONPRODUCTS_LOAD, loadQuotationProduct);
}

function* addQuotationProduct({ payload }) {
  try {
    const data = yield call(addQuotationProductRequest, payload.model);
    data.status == 401 || data.response && data.response.status == 401 ? yield put(userSignOut()) :
      yield put(addQuotationProductSuccess(payload.filter));
  } catch (error) {
    //yield put(showAuthMessage(error));
  }
}

function* processAddQuotationProduct() {
  yield takeEvery(QUOTATIONPRODUCT_ADD, addQuotationProduct);
}

function* updateQuotationProduct({ payload }) {
  try {
    const data = yield call(updateQuotationProductRequest, payload.model);
    data.status == 401 || data.response && data.response.status == 401 ? yield put(userSignOut()) :
      yield put(updateQuotationProductSuccess(payload.filter));
  } catch (error) {
    //yield put(showAuthMessage(error));
  }
}

function* processUpdateQuotationProduct() {
  yield takeEvery(QUOTATIONPRODUCT_UPDATE, updateQuotationProduct);
}

function* deleteQuotationProduct({ payload }) {
  try {
    const data = yield call(deleteQuotationProductRequest, payload.model.id);
    data.status == 401 || data.response && data.response.status == 401 ? yield put(userSignOut()) :
      yield put(deleteQuotationProductSuccess(payload.filter));
  } catch (error) {
    //yield put(showAuthMessage(error));
  }
}

function* deleteAllQuotationProducts({ payload }) {
  try {
    for (let i = 0; i < payload.models.length; ++i) {
      const data = yield call(deleteQuotationProductRequest, payload.models[i].id);
      if (data.status == 401 || data.response && data.response.status == 401) { yield put(userSignOut()); break; }
    }

    yield put(deleteAllQuotationProductsSuccess(payload.filter));
  } catch (error) {
    //yield put(showAuthMessage(error));
  }
}

function* processDeleteQuotationProduct() {
  yield takeEvery(QUOTATIONPRODUCT_DELETE, deleteQuotationProduct);
}

function* processDeleteAllQuotationProducts() {
  yield takeEvery(QUOTATIONPRODUCT_DELETE_ALL, deleteAllQuotationProducts);
}

export default function* QuotationProductSagas() {
  yield all([fork(processLoadQuotationProducts),
  fork(processLoadQuotationProduct),
  fork(processAddQuotationProduct),
  fork(processUpdateQuotationProduct),
  fork(processDeleteAllQuotationProducts),
  fork(processDeleteQuotationProduct)]);
}
