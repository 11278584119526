import {
    ORDERS_SELECT_ALL,
    ORDERS_UNSELECT_ALL,
    ORDER_SELECTED,
    ORDERS_LOAD,
    ORDERS_LOAD_SUCCESS,
    ORDERS_LOAD_FAILED,
    ORDER_LOAD,
    ORDER_LOAD_SUCCESS,
    ORDER_LOAD_FAILED,
    ORDER_ADD,
    ORDER_ADD_SUCCESS,
    ORDER_ADD_FAILED,
    ORDER_UPDATE,
    ORDER_UPDATE_SUCCESS,
    ORDER_UPDATE_FAILED,
    ORDER_DELETE,
    ORDER_DELETE_SUCCESS,
    ORDER_DELETE_FAILED,
    ORDER_DELETE_ALL,
    ORDER_DELETE_ALL_SUCCESS,
    ORDER_DELETE_ALL_FAILED,
    ORDERS_EXPORT_EXCEL,
    ORDERS_EXPORT_EXCEL_SUCCESS,
    ORDERS_EXPORT_EXCEL_FAILED,
    ORDERS_REPORT,
    ORDERS_REPORT_SUCCESS,
    ORDERS_REPORT_FAILED
  } from '../constants/ActionTypes';
  
  export const selectAllOrders = () => {
    return {
      type: ORDERS_SELECT_ALL
    }
  }
  
  export const unselectAllOrders = () => {
    return {
      type: ORDERS_UNSELECT_ALL
    }
  }
  
  export const selectOrder = data => {
    return {
      type: ORDER_SELECTED,
      payload: data
    }
  }
  
  export const loadOrders = (data) => {
    return {
      type: ORDERS_LOAD,
      payload: data
    };
  }
  
  export const loadOrdersSuccess = (data) => {
    return {
      type: ORDERS_LOAD_SUCCESS,
      payload: data
    };
  }
  
  export const loadOrdersFailed = () => {
    return {
      type: ORDERS_LOAD_FAILED
    };
  }
  
  export const loadOrder = () => {
    return {
      type: ORDER_LOAD
    };
  }
  
  export const loadOrderSuccess = (data) => {
    return {
      type: ORDER_LOAD_SUCCESS,
      payload: data
    };
  }
  
  
  export const loadOrderFailed = () => {
    return {
      type: ORDER_LOAD_FAILED
    };
  }
  
  
  export const addOrder = (data) => {
    return {
      type: ORDER_ADD,
      payload: data
    };
  }
  
  
  export const addOrderSuccess = (filter) => {
    return {
      type: ORDER_ADD_SUCCESS,
      message: 'Order added successfully.',
      payload: filter
    };
  }
  
  export const addOrderFailed = () => {
    return {
      type: ORDER_ADD_FAILED
    };
  }
  
  export const updateOrder = (data) => {
    return {
      type: ORDER_UPDATE,
      payload: data
    };
  }
  
  
  export const updateOrderSuccess = (data) => {
    return {
      type: ORDER_UPDATE_SUCCESS,
      payload: data
    };
  }
  
  export const updateOrderFailed = (data) => {
    return {
      type: ORDER_UPDATE_FAILED,
      payload: data
    };
  }
  
  export const deleteOrder = (data) => {
    return {
      type: ORDER_DELETE,
      payload: data
    };
  }
  
  export const deleteOrderSuccess = () => {
    return {
      type: ORDER_DELETE_SUCCESS,
      message: 'Order deleted successfully.'
    };
  }
  
  export const deleteOrderFailed = () => {
    return {
      type: ORDER_DELETE_FAILED
    };
  }
  
  export const deleteAllOrders = (data) => {
    return {
      type: ORDER_DELETE_ALL,
      payload: data
    };
  }
  
  export const deleteAllOrdersSuccess = () => {
    return {
      type: ORDER_DELETE_ALL_SUCCESS,
      message: 'Order all deleted successfully.'
    };
  }
  
  export const deleteAllOrdersFailed = () => {
    return {
      type: ORDER_DELETE_ALL_FAILED
    };
  }
  
  export const exportExcelOrders = (filter) => {
    return {
      type: ORDERS_EXPORT_EXCEL,
      payload: filter
    }
  }
  
  export const exportExcelOrdersSuccess = (data) => {
    return {
      type: ORDERS_EXPORT_EXCEL_SUCCESS,
      payload: data
    };
  }
  
  export const exportExcelOrdersFailed = () => {
    return {
      type: ORDERS_EXPORT_EXCEL_FAILED
    };
  }
  
  export const reportOrders = (filter) => {
    return {
      type: ORDERS_REPORT,
      payload: filter
    }
  }
  
  export const reportOrdersSuccess = (data) => {
    return {
      type: ORDERS_REPORT_SUCCESS,
      payload: data
    };
  }
  export const reportOrdersFailed = () => {
    return {
      type: ORDERS_REPORT_FAILED
    };
  }