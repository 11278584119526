import {
    PROVIDERS_SELECT_ALL,
    PROVIDERS_UNSELECT_ALL,
    PROVIDER_SELECTED,
    PROVIDERS_LOAD,
    PROVIDERS_LOAD_SUCCESS,
    PROVIDERS_LOAD_FAILED,
    PROVIDER_LOAD,
    PROVIDER_LOAD_SUCCESS,
    PROVIDER_LOAD_FAILED,
    PROVIDER_ADD,
    PROVIDER_ADD_SUCCESS,
    PROVIDER_ADD_FAILED,
    PROVIDER_UPDATE,
    PROVIDER_UPDATE_SUCCESS,
    PROVIDER_UPDATE_FAILED,
    PROVIDER_DELETE,
    PROVIDER_DELETE_SUCCESS,
    PROVIDER_DELETE_FAILED,
    PROVIDER_DELETE_ALL,
    PROVIDER_DELETE_ALL_SUCCESS,
    PROVIDER_DELETE_ALL_FAILED,
    PROVIDERS_EXPORT_EXCEL,
    PROVIDERS_EXPORT_EXCEL_SUCCESS,
    PROVIDERS_EXPORT_EXCEL_FAILED,
    PROVIDERS_REPORT,
    PROVIDERS_REPORT_SUCCESS,
    PROVIDERS_REPORT_FAILED
  } from '../constants/ActionTypes';
  
  export const selectAllProviders = () => {
    return {
      type: PROVIDERS_SELECT_ALL
    }
  }
  
  export const unselectAllProviders = () => {
    return {
      type: PROVIDERS_UNSELECT_ALL
    }
  }
  
  export const selectProvider = data => {
    return {
      type: PROVIDER_SELECTED,
      payload: data
    }
  }
  
  export const loadProviders = (data) => {
    return {
      type: PROVIDERS_LOAD,
      payload: data
    };
  }
  
  export const loadProvidersSuccess = (data) => {
    return {
      type: PROVIDERS_LOAD_SUCCESS,
      payload: data
    };
  }
  
  export const loadProvidersFailed = () => {
    return {
      type: PROVIDERS_LOAD_FAILED
    };
  }
  
  export const loadProvider = () => {
    return {
      type: PROVIDER_LOAD
    };
  }
  
  export const loadProviderSuccess = (data) => {
    return {
      type: PROVIDER_LOAD_SUCCESS,
      payload: data
    };
  }
  
  
  export const loadProviderFailed = () => {
    return {
      type: PROVIDER_LOAD_FAILED
    };
  }
  
  
  export const addProvider = (data) => {
    return {
      type: PROVIDER_ADD,
      payload: data
    };
  }
  
  
  export const addProviderSuccess = (filter) => {
    return {
      type: PROVIDER_ADD_SUCCESS,
      message: 'Provider added successfully.',
      payload: filter
    };
  }
  
  export const addProviderFailed = () => {
    return {
      type: PROVIDER_ADD_FAILED
    };
  }
  
  export const updateProvider = (data) => {
    return {
      type: PROVIDER_UPDATE,
      payload: data
    };
  }
  
  
  export const updateProviderSuccess = (data) => {
    return {
      type: PROVIDER_UPDATE_SUCCESS,
      payload: data
    };
  }
  
  export const updateProviderFailed = (data) => {
    return {
      type: PROVIDER_UPDATE_FAILED,
      payload: data
    };
  }
  
  export const deleteProvider = (data) => {
    return {
      type: PROVIDER_DELETE,
      payload: data
    };
  }
  
  export const deleteProviderSuccess = () => {
    return {
      type: PROVIDER_DELETE_SUCCESS,
      message: 'Provider deleted successfully.'
    };
  }
  
  export const deleteProviderFailed = () => {
    return {
      type: PROVIDER_DELETE_FAILED
    };
  }
  
  export const deleteAllProviders = (data) => {
    return {
      type: PROVIDER_DELETE_ALL,
      payload: data
    };
  }
  
  export const deleteAllProvidersSuccess = () => {
    return {
      type: PROVIDER_DELETE_ALL_SUCCESS,
      message: 'Provider all deleted successfully.'
    };
  }
  
  export const deleteAllProvidersFailed = () => {
    return {
      type: PROVIDER_DELETE_ALL_FAILED
    };
  }
  
  export const exportExcelProviders = (filter) => {
    return {
      type: PROVIDERS_EXPORT_EXCEL,
      payload: filter
    }
  }
  
  export const exportExcelProvidersSuccess = (data) => {
    return {
      type: PROVIDERS_EXPORT_EXCEL_SUCCESS,
      payload: data
    };
  }
  
  export const exportExcelProvidersFailed = () => {
    return {
      type: PROVIDERS_EXPORT_EXCEL_FAILED
    };
  }
  
  export const reportProviders = (filter) => {
    return {
      type: PROVIDERS_REPORT,
      payload: filter
    }
  }
  
  export const reportProvidersSuccess = (data) => {
    return {
      type: PROVIDERS_REPORT_SUCCESS,
      payload: data
    };
  }
  export const reportProvidersFailed = () => {
    return {
      type: PROVIDERS_REPORT_FAILED
    };
  }