import {
    STORES_SELECT_ALL,
    STORES_UNSELECT_ALL,
    STORE_SELECTED,
    STORES_LOAD,
    STORES_LOAD_SUCCESS,
    STORES_LOAD_FAILED,
    STORE_LOAD,
    STORE_LOAD_SUCCESS,
    STORE_LOAD_FAILED,
    STORE_ADD,
    STORE_ADD_SUCCESS,
    STORE_ADD_FAILED,
    STORE_UPDATE,
    STORE_UPDATE_SUCCESS,
    STORE_UPDATE_FAILED,
    STORE_DELETE,
    STORE_DELETE_SUCCESS,
    STORE_DELETE_FAILED,
    STORE_DELETE_ALL,
    STORE_DELETE_ALL_SUCCESS,
    STORE_DELETE_ALL_FAILED,
    STORES_EXPORT_EXCEL,
    STORES_EXPORT_EXCEL_SUCCESS,
    STORES_EXPORT_EXCEL_FAILED,
    STORES_REPORT,
    STORES_REPORT_SUCCESS,
    STORES_REPORT_FAILED
  } from '../constants/ActionTypes';
  import { PAGE_SIZES } from '../constants/AppConstant';
  
  const INIT_STATE = {
    paging: {
      records: [],
      offset: 0,
      limit: PAGE_SIZES,
      totalRecords: 0
    },
    reportData: [],
    selectedStore: null,
    selectedStoreId: null,
    message: null,
    messageType: 'info',
    filter: {
      offset: 0,
      limit: PAGE_SIZES,
      orderBy: 'id',
      descending: true,
      filterGroups: null
    },
    hideLoading: true
  };
  
  export default (state = INIT_STATE, action) => {
    switch (action.type) {
      case STORES_SELECT_ALL:
        return {
          ...state,
          paging: {
            ...state.paging,
            records: state.paging.records.map(data => {
              data.selected = true;
              return data;
            })
          }
        }
      case STORES_UNSELECT_ALL:
        return {
          ...state,
          paging: {
            ...state.paging,
            records: state.paging.records.map(data => {
              data.selected = false;
              return data;
            })
          }
        }
      case STORE_SELECTED:
        return {
          ...state,
          selectedStore: action.payload,
          selectedStoreId: action.payload.id
        };
      case STORES_LOAD:
        return {
          ...state,
          filter: action.payload ? action.payload : state.filter
        };
      case STORES_LOAD_SUCCESS:
        return {
          ...state,
          paging: action.payload
        };
      case STORES_LOAD_FAILED:
        return {
          ...state
        };
      case STORE_LOAD:
        return {
          ...state
        };
      case STORE_LOAD_SUCCESS:
        return {
          ...state
        };
      case STORE_LOAD_FAILED:
        return {
          ...state
        };
      case STORE_ADD:
        return {
          ...state,
          hideLoading: false
        };
      case STORE_ADD_SUCCESS:
        return {
          ...state,
          hideLoading: true
        };
      case STORE_ADD_FAILED:
        return {
          ...state,
          hideLoading: true
        };
      case STORE_UPDATE:
        return {
          ...state,
          hideLoading: false
        };
      case STORE_UPDATE_SUCCESS:
        return {
          ...state,
          hideLoading: true
        };
      case STORE_UPDATE_FAILED:
        return {
          ...state,
          hideLoading: true
        };
      case STORE_DELETE:
        return {
          ...state
        };
      case STORE_DELETE_SUCCESS:
        return {
          ...state,
          message: action.message
        };
      case STORE_DELETE_FAILED:
        return {
          ...state
        };
      case STORE_DELETE_ALL:
        return {
          ...state
        };
      case STORE_DELETE_ALL_SUCCESS:
        return {
          ...state,
          message: action.message
        };
      case STORE_DELETE_ALL_FAILED:
        return {
          ...state
        };
      case STORES_EXPORT_EXCEL:
        return {
          ...state,
          excelBlob: null
        };
      case STORES_EXPORT_EXCEL_SUCCESS:
        return {
          ...state,
          excelBlob: action.payload
        };
      case STORES_EXPORT_EXCEL_FAILED:
        return {
          ...state,
          excelBlob: null
        };
      case STORES_REPORT:
        return {
          ...state,
          reportData: []
        };
      case STORES_REPORT_SUCCESS:
        return {
          ...state,
          reportData: action.payload
        };
      case STORES_REPORT_FAILED:
        return {
          ...state,
          reportData: []
        };
      default:
        return state;
    }
  }
  