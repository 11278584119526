import {
  ORDERSTORES_SELECT_ALL,
  ORDERSTORES_UNSELECT_ALL,
  ORDERSTORE_SELECTED,
  ORDERSTORES_LOAD,
  ORDERSTORES_LOAD_SUCCESS,
  ORDERSTORES_LOAD_FAILED,
  ORDERSTORE_LOAD,
  ORDERSTORE_LOAD_SUCCESS,
  ORDERSTORE_LOAD_FAILED,
  ORDERSTORE_ADD,
  ORDERSTORE_ADD_SUCCESS,
  ORDERSTORE_ADD_FAILED,
  ORDERSTORE_UPDATE,
  ORDERSTORE_UPDATE_SUCCESS,
  ORDERSTORE_UPDATE_FAILED,
  ORDERSTORE_DELETE,
  ORDERSTORE_DELETE_SUCCESS,
  ORDERSTORE_DELETE_FAILED,
  ORDERSTORE_DELETE_ALL,
  ORDERSTORE_DELETE_ALL_SUCCESS,
  ORDERSTORE_DELETE_ALL_FAILED,
  ORDERSTORES_EXPORT_EXCEL,
  ORDERSTORES_EXPORT_EXCEL_SUCCESS,
  ORDERSTORES_EXPORT_EXCEL_FAILED,
  ORDERSTORES_REPORT,
  ORDERSTORES_REPORT_SUCCESS,
  ORDERSTORES_REPORT_FAILED
} from '../constants/ActionTypes';

export const selectAllOrderStores = () => {
  return {
    type: ORDERSTORES_SELECT_ALL
  }
}

export const unselectAllOrderStores = () => {
  return {
    type: ORDERSTORES_UNSELECT_ALL
  }
}

export const selectOrderStore = data => {
  return {
    type: ORDERSTORE_SELECTED,
    payload: data
  }
}

export const loadOrderStores = (data) => {
  return {
    type: ORDERSTORES_LOAD,
    payload: data
  };
}

export const loadOrderStoresSuccess = (data) => {
  return {
    type: ORDERSTORES_LOAD_SUCCESS,
    payload: data
  };
}

export const loadOrderStoresFailed = () => {
  return {
    type: ORDERSTORES_LOAD_FAILED
  };
}

export const loadOrderStore = () => {
  return {
    type: ORDERSTORE_LOAD
  };
}

export const loadOrderStoreSuccess = (data) => {
  return {
    type: ORDERSTORE_LOAD_SUCCESS,
    payload: data
  };
}


export const loadOrderStoreFailed = () => {
  return {
    type: ORDERSTORE_LOAD_FAILED
  };
}


export const addOrderStore = (data) => {
  return {
    type: ORDERSTORE_ADD,
    payload: data
  };
}


export const addOrderStoreSuccess = (filter) => {
  return {
    type: ORDERSTORE_ADD_SUCCESS,
    message: 'OrderStore added successfully.',
    payload: filter
  };
}

export const addOrderStoreFailed = () => {
  return {
    type: ORDERSTORE_ADD_FAILED
  };
}

export const updateOrderStore = (data) => {
  return {
    type: ORDERSTORE_UPDATE,
    payload: data
  };
}


export const updateOrderStoreSuccess = (data) => {
  return {
    type: ORDERSTORE_UPDATE_SUCCESS,
    payload: data
  };
}

export const updateOrderStoreFailed = (data) => {
  return {
    type: ORDERSTORE_UPDATE_FAILED,
    payload: data
  };
}

export const deleteOrderStore = (data) => {
  return {
    type: ORDERSTORE_DELETE,
    payload: data
  };
}

export const deleteOrderStoreSuccess = () => {
  return {
    type: ORDERSTORE_DELETE_SUCCESS,
    message: 'OrderStore deleted successfully.'
  };
}

export const deleteOrderStoreFailed = () => {
  return {
    type: ORDERSTORE_DELETE_FAILED
  };
}

export const deleteAllOrderStores = (data) => {
  return {
    type: ORDERSTORE_DELETE_ALL,
    payload: data
  };
}

export const deleteAllOrderStoresSuccess = () => {
  return {
    type: ORDERSTORE_DELETE_ALL_SUCCESS,
    message: 'OrderStore all deleted successfully.'
  };
}

export const deleteAllOrderStoresFailed = () => {
  return {
    type: ORDERSTORE_DELETE_ALL_FAILED
  };
}

export const exportExcelOrderStores = (filter) => {
  return {
    type: ORDERSTORES_EXPORT_EXCEL,
    payload: filter
  }
}

export const exportExcelOrderStoresSuccess = (data) => {
  return {
    type: ORDERSTORES_EXPORT_EXCEL_SUCCESS,
    payload: data
  };
}

export const exportExcelOrderStoresFailed = () => {
  return {
    type: ORDERSTORES_EXPORT_EXCEL_FAILED
  };
}

export const reportOrderStores = (filter) => {
  return {
    type: ORDERSTORES_REPORT,
    payload: filter
  }
}

export const reportOrderStoresSuccess = (data) => {
  return {
    type: ORDERSTORES_REPORT_SUCCESS,
    payload: data
  };
}
export const reportOrderStoresFailed = () => {
  return {
    type: ORDERSTORES_REPORT_FAILED
  };
}