import {
  ORDERSTORES_SELECT_ALL,
  ORDERSTORES_UNSELECT_ALL,
  ORDERSTORE_SELECTED,
  ORDERSTORES_LOAD,
  ORDERSTORES_LOAD_SUCCESS,
  ORDERSTORES_LOAD_FAILED,
  ORDERSTORE_LOAD,
  ORDERSTORE_LOAD_SUCCESS,
  ORDERSTORE_LOAD_FAILED,
  ORDERSTORE_ADD,
  ORDERSTORE_ADD_SUCCESS,
  ORDERSTORE_ADD_FAILED,
  ORDERSTORE_UPDATE,
  ORDERSTORE_UPDATE_SUCCESS,
  ORDERSTORE_UPDATE_FAILED,
  ORDERSTORE_DELETE,
  ORDERSTORE_DELETE_SUCCESS,
  ORDERSTORE_DELETE_FAILED,
  ORDERSTORE_DELETE_ALL,
  ORDERSTORE_DELETE_ALL_SUCCESS,
  ORDERSTORE_DELETE_ALL_FAILED,
  ORDERSTORES_EXPORT_EXCEL,
  ORDERSTORES_EXPORT_EXCEL_SUCCESS,
  ORDERSTORES_EXPORT_EXCEL_FAILED,
  ORDERSTORES_REPORT,
  ORDERSTORES_REPORT_SUCCESS,
  ORDERSTORES_REPORT_FAILED
} from '../constants/ActionTypes';
import { PAGE_SIZES } from '../constants/AppConstant';

const INIT_STATE = {
  paging: {
    records: [],
    offset: 0,
    limit: PAGE_SIZES,
    totalRecords: 0
  },
  reportData: [],
  selectedOrderStore: null,
  selectedOrderStoreId: null,
  message: null,
  messageType: 'info',
  filter: {
    offset: 0,
    limit: PAGE_SIZES,
    orderBy: 'id',
    descending: true,
    filterGroups: null
  },
  hideLoading: true
};

export default (state = INIT_STATE, action) => {
  switch (action.type) {
    case ORDERSTORES_SELECT_ALL:
      return {
        ...state,
        paging: {
          ...state.paging,
          records: state.paging.records.map(data => {
            data.selected = true;
            return data;
          })
        }
      }
    case ORDERSTORES_UNSELECT_ALL:
      return {
        ...state,
        paging: {
          ...state.paging,
          records: state.paging.records.map(data => {
            data.selected = false;
            return data;
          })
        }
      }
    case ORDERSTORE_SELECTED:
      return {
        ...state,
        selectedOrderStore: action.payload,
        selectedOrderStoreId: action.payload.id
      };
    case ORDERSTORES_LOAD:
      return {
        ...state,
        filter: action.payload ? action.payload : state.filter
      };
    case ORDERSTORES_LOAD_SUCCESS:
      return {
        ...state,
        paging: action.payload
      };
    case ORDERSTORES_LOAD_FAILED:
      return {
        ...state
      };
    case ORDERSTORE_LOAD:
      return {
        ...state
      };
    case ORDERSTORE_LOAD_SUCCESS:
      return {
        ...state
      };
    case ORDERSTORE_LOAD_FAILED:
      return {
        ...state
      };
    case ORDERSTORE_ADD:
      return {
        ...state,
        hideLoading: false
      };
    case ORDERSTORE_ADD_SUCCESS:
      return {
        ...state,
        hideLoading: true
      };
    case ORDERSTORE_ADD_FAILED:
      return {
        ...state,
        hideLoading: true
      };
    case ORDERSTORE_UPDATE:
      return {
        ...state,
        hideLoading: false
      };
    case ORDERSTORE_UPDATE_SUCCESS:
      return {
        ...state,
        hideLoading: true
      };
    case ORDERSTORE_UPDATE_FAILED:
      return {
        ...state,
        hideLoading: true
      };
    case ORDERSTORE_DELETE:
      return {
        ...state
      };
    case ORDERSTORE_DELETE_SUCCESS:
      return {
        ...state,
        message: action.message
      };
    case ORDERSTORE_DELETE_FAILED:
      return {
        ...state
      };
    case ORDERSTORE_DELETE_ALL:
      return {
        ...state
      };
    case ORDERSTORE_DELETE_ALL_SUCCESS:
      return {
        ...state,
        message: action.message
      };
    case ORDERSTORE_DELETE_ALL_FAILED:
      return {
        ...state
      };
    case ORDERSTORES_EXPORT_EXCEL:
      return {
        ...state,
        excelBlob: null
      };
    case ORDERSTORES_EXPORT_EXCEL_SUCCESS:
      return {
        ...state,
        excelBlob: action.payload
      };
    case ORDERSTORES_EXPORT_EXCEL_FAILED:
      return {
        ...state,
        excelBlob: null
      };
    case ORDERSTORES_REPORT:
      return {
        ...state,
        reportData: []
      };
    case ORDERSTORES_REPORT_SUCCESS:
      return {
        ...state,
        reportData: action.payload
      };
    case ORDERSTORES_REPORT_FAILED:
      return {
        ...state,
        reportData: []
      };
    default:
      return state;
  }
}
