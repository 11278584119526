import {
    STORES_SELECT_ALL,
    STORES_UNSELECT_ALL,
    STORE_SELECTED,
    STORES_LOAD,
    STORES_LOAD_SUCCESS,
    STORES_LOAD_FAILED,
    STORE_LOAD,
    STORE_LOAD_SUCCESS,
    STORE_LOAD_FAILED,
    STORE_ADD,
    STORE_ADD_SUCCESS,
    STORE_ADD_FAILED,
    STORE_UPDATE,
    STORE_UPDATE_SUCCESS,
    STORE_UPDATE_FAILED,
    STORE_DELETE,
    STORE_DELETE_SUCCESS,
    STORE_DELETE_FAILED,
    STORE_DELETE_ALL,
    STORE_DELETE_ALL_SUCCESS,
    STORE_DELETE_ALL_FAILED,
    STORES_EXPORT_EXCEL,
    STORES_EXPORT_EXCEL_SUCCESS,
    STORES_EXPORT_EXCEL_FAILED,
    STORES_REPORT,
    STORES_REPORT_SUCCESS,
    STORES_REPORT_FAILED
  } from '../constants/ActionTypes';
  
  export const selectAllStores = () => {
    return {
      type: STORES_SELECT_ALL
    }
  }
  
  export const unselectAllStores = () => {
    return {
      type: STORES_UNSELECT_ALL
    }
  }
  
  export const selectStore = data => {
    return {
      type: STORE_SELECTED,
      payload: data
    }
  }
  
  export const loadStores = (data) => {
    return {
      type: STORES_LOAD,
      payload: data
    };
  }
  
  export const loadStoresSuccess = (data) => {
    return {
      type: STORES_LOAD_SUCCESS,
      payload: data
    };
  }
  
  export const loadStoresFailed = () => {
    return {
      type: STORES_LOAD_FAILED
    };
  }
  
  export const loadStore = () => {
    return {
      type: STORE_LOAD
    };
  }
  
  export const loadStoreSuccess = (data) => {
    return {
      type: STORE_LOAD_SUCCESS,
      payload: data
    };
  }
  
  
  export const loadStoreFailed = () => {
    return {
      type: STORE_LOAD_FAILED
    };
  }
  
  
  export const addStore = (data) => {
    return {
      type: STORE_ADD,
      payload: data
    };
  }
  
  
  export const addStoreSuccess = (filter) => {
    return {
      type: STORE_ADD_SUCCESS,
      message: 'Store added successfully.',
      payload: filter
    };
  }
  
  export const addStoreFailed = () => {
    return {
      type: STORE_ADD_FAILED
    };
  }
  
  export const updateStore = (data) => {
    return {
      type: STORE_UPDATE,
      payload: data
    };
  }
  
  
  export const updateStoreSuccess = (data) => {
    return {
      type: STORE_UPDATE_SUCCESS,
      payload: data
    };
  }
  
  export const updateStoreFailed = (data) => {
    return {
      type: STORE_UPDATE_FAILED,
      payload: data
    };
  }
  
  export const deleteStore = (data) => {
    return {
      type: STORE_DELETE,
      payload: data
    };
  }
  
  export const deleteStoreSuccess = () => {
    return {
      type: STORE_DELETE_SUCCESS,
      message: 'Store deleted successfully.'
    };
  }
  
  export const deleteStoreFailed = () => {
    return {
      type: STORE_DELETE_FAILED
    };
  }
  
  export const deleteAllStores = (data) => {
    return {
      type: STORE_DELETE_ALL,
      payload: data
    };
  }
  
  export const deleteAllStoresSuccess = () => {
    return {
      type: STORE_DELETE_ALL_SUCCESS,
      message: 'Store all deleted successfully.'
    };
  }
  
  export const deleteAllStoresFailed = () => {
    return {
      type: STORE_DELETE_ALL_FAILED
    };
  }
  
  export const exportExcelStores = (filter) => {
    return {
      type: STORES_EXPORT_EXCEL,
      payload: filter
    }
  }
  
  export const exportExcelStoresSuccess = (data) => {
    return {
      type: STORES_EXPORT_EXCEL_SUCCESS,
      payload: data
    };
  }
  
  export const exportExcelStoresFailed = () => {
    return {
      type: STORES_EXPORT_EXCEL_FAILED
    };
  }
  
  export const reportStores = (filter) => {
    return {
      type: STORES_REPORT,
      payload: filter
    }
  }
  
  export const reportStoresSuccess = (data) => {
    return {
      type: STORES_REPORT_SUCCESS,
      payload: data
    };
  }
  export const reportStoresFailed = () => {
    return {
      type: STORES_REPORT_FAILED
    };
  }