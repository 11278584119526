import { all, call, fork, put, takeEvery } from "redux-saga/effects";
import Api, { patchData } from './Api';
import { GET_ALL_SIZE } from '../constants/AppConstant';

import {
  loadStores as loadStoresAction,
  loadStoresSuccess,
  addStoreSuccess,
  updateStoreSuccess,
  exportExcelStoresSuccess,
  reportStoresSuccess
} from '../actions/Store';

import {
  STORES_LOAD,
  STORE_ADD,
  STORE_UPDATE,
  STORE_DELETE,
  STORE_DELETE_ALL,
  STORES_EXPORT_EXCEL,
  STORES_REPORT
} from '../constants/ActionTypes';

import {
  userSignOut
} from '../actions/Auth';

export const getStoreById = (id, callback) => {
  Api().get('store/' + id).then(response => {
    callback(response.data);
  })
}

export const getAllStores = (callback, params) => {
  if (!params) {
    params = {
      limit: GET_ALL_SIZE
    }
  }

  Api().post('store/search', params)
    .then(response => {
      callback(response.data.records);        
    })
}

export const getStoresByCompany = (companyId, callback) => {
  Api().post('store/search', {
    limit: GET_ALL_SIZE,
    filterGroups: [{
      filters: [{
        property: 'companyId',
        operator: 'equal',
        value: companyId
      }]
    }]
  }).then(response => {
    callback(response.data.records);    
  })
}

export const getMaxStoreCodeSorted = (callback) => {
  Api().post('store/search', { offset: 0,
    limit: 1,
    orderBy: "codeSorted",
    descending: true
  })
    .then(response => {
      let codeSorted = 0;
      let records = response.data.records;
      if (records.length) {
        codeSorted = records[0].codeSorted;
      }
      callback(codeSorted);
    })
}

const loadStoresRequest = async (params) => {
  if (params.filterGroups && params.filterGroups.length) {
    return await Api().post(`store/search`, params)
      .then(data => data)
      .catch(error => error);
  }

  return await Api().get(`store`, { params })
    .then(data => data)
    .catch(error => error);
}

const addStoreRequest = async (payload) =>
  await Api().post(`store`, payload)
    .then(data => data)
    .catch(error => error);

const updateStoreRequest = async (payload) =>
  await Api().patch(`store/${payload.id}`, patchData(payload))
    .then(data => data)
    .catch(error => error);

export const deleteStoreRequest = async (id) =>
  await Api().delete(`store/${id}`)
    .then(data => data)
    .catch(error => error);

function* loadStores({ payload }) {
  try {
    const data = yield call(loadStoresRequest, payload);
    data.status == 401 || data.response && data.response.status == 401 ? yield put(userSignOut()) :
    yield put(loadStoresSuccess(data.data));
  } catch (error) {
    //yield put(showAuthMessage(error));
  }
}

function* processLoadStores() {
  yield takeEvery(STORES_LOAD, loadStores);
}

function* loadStore({ selectedStoreId }) {
  try {
    const data = yield call(loadStoresRequest, selectedStoreId);
    data.status == 401 || data.response && data.response.status == 401 ? yield put(userSignOut()) :
    yield put(loadStoresSuccess(data.data));
  } catch (error) {
    //yield put(showAuthMessage(error));
  }
}

function* processLoadStore() {
  yield takeEvery(STORES_LOAD, loadStore);
}


function* addStore({ payload }) {
  try {
    const data = yield call(addStoreRequest, payload.model);
    data.status == 401 || data.response && data.response.status == 401 ? yield put(userSignOut()) :
    yield put(addStoreSuccess(payload.filter));
  } catch (error) {
    //yield put(showAuthMessage(error));
  }
}

function* processAddStore() {
  yield takeEvery(STORE_ADD, addStore);
}


function* updateStore({ payload }) {
  try {
    const data = yield call(updateStoreRequest, payload.model);
    data.status == 401 || data.response && data.response.status == 401 ? yield put(userSignOut()) :
    yield put(updateStoreSuccess(payload.filter));
  } catch (error) {
    //yield put(showAuthMessage(error));
  }
}

function* processUpdateStore() {
  yield takeEvery(STORE_UPDATE, updateStore);
}

function* deleteStore({ payload }) {
  try {
    const data = yield call(deleteStoreRequest, payload.model.id);
    data.status == 401 || data.response && data.response.status == 401 ? yield put(userSignOut()) :
    yield put(loadStoresAction(payload.filter));
  } catch (error) {
    //yield put(showAuthMessage(error));
  }
}

function* deleteAllStores({ payload }) {
  try {
    for (let i = 0; i < payload.models.length; ++i) {
      const data = yield call(deleteStoreRequest, payload.models[i].id);
      if (data.status == 401 || data.response && data.response.status == 401) { yield put(userSignOut()); break; }
    }

    yield put(loadStoresAction(payload.filter));
  } catch (error) {
    //yield put(showAuthMessage(error));
  }
}

function* processDeleteStore() {
  yield takeEvery(STORE_DELETE, deleteStore);
}

function* processDeleteAllStores() {
  yield takeEvery(STORE_DELETE_ALL, deleteAllStores);
}

const exportStoresRequest = async (params) => {
  return await Api({responseType: 'blob'}).post(`store/export/excel`, params)
    .then(data => data)
    .catch(error => error);
}


function* exportStores({ payload }) {
  try {
    const data = yield call(exportStoresRequest, payload);
    data.status == 401 || data.response && data.response.status == 401 ? yield put(userSignOut()) :
    yield put(exportExcelStoresSuccess(data.data));
  } catch (error) {
    //yield put(showAuthMessage(error));
  }
}

function* processExportStores() {
  yield takeEvery(STORES_EXPORT_EXCEL, exportStores);
}

const reportStoresRequest = async (params) => {
  return await Api().post(`store/report`, params)
    .then(data => data)
    .catch(error => error);
}


function* reportStores({ payload }) {
  try {
    if (!payload) { payload = {} }
    const data = yield call(reportStoresRequest, payload);
    data.status == 401 || data.response && data.response.status == 401 ? yield put(userSignOut()) :
    yield put(reportStoresSuccess(data.data));
  } catch (error) {
    //yield put(showAuthMessage(error));
  }
}

function* processReportStores() {
  yield takeEvery(STORES_REPORT, reportStores);
}

export default function* StoreSagas() {
  yield all([fork(processLoadStores),
  fork(processLoadStore),
  fork(processAddStore),
  fork(processUpdateStore),
  fork(processDeleteAllStores),
  fork(processDeleteStore),
  fork(processExportStores),
  fork(processReportStores)
]);
}
