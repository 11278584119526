import {
    ORDERSTOREPRODUCTS_SELECT_ALL,
    ORDERSTOREPRODUCTS_UNSELECT_ALL,
    ORDERSTOREPRODUCT_SELECTED,
    ORDERSTOREPRODUCTS_LOAD,
    ORDERSTOREPRODUCTS_LOAD_SUCCESS,
    ORDERSTOREPRODUCTS_LOAD_FAILED,
    ORDERSTOREPRODUCT_LOAD,
    ORDERSTOREPRODUCT_LOAD_SUCCESS,
    ORDERSTOREPRODUCT_LOAD_FAILED,
    ORDERSTOREPRODUCT_ADD,
    ORDERSTOREPRODUCT_ADD_SUCCESS,
    ORDERSTOREPRODUCT_ADD_FAILED,
    ORDERSTOREPRODUCT_UPDATE,
    ORDERSTOREPRODUCT_UPDATE_SUCCESS,
    ORDERSTOREPRODUCT_UPDATE_FAILED,
    ORDERSTOREPRODUCT_DELETE,
    ORDERSTOREPRODUCT_DELETE_SUCCESS,
    ORDERSTOREPRODUCT_DELETE_FAILED,
    ORDERSTOREPRODUCT_DELETE_ALL,
    ORDERSTOREPRODUCT_DELETE_ALL_SUCCESS,
    ORDERSTOREPRODUCT_DELETE_ALL_FAILED,
    ORDERSTOREPRODUCTS_EXPORT_EXCEL,
    ORDERSTOREPRODUCTS_EXPORT_EXCEL_SUCCESS,
    ORDERSTOREPRODUCTS_EXPORT_EXCEL_FAILED,
    ORDERSTOREPRODUCTS_REPORT,
    ORDERSTOREPRODUCTS_REPORT_SUCCESS,
    ORDERSTOREPRODUCTS_REPORT_FAILED
  } from '../constants/ActionTypes';
  
  export const selectAllOrderStoreProducts = () => {
    return {
      type: ORDERSTOREPRODUCTS_SELECT_ALL
    }
  }
  
  export const unselectAllOrderStoreProducts = () => {
    return {
      type: ORDERSTOREPRODUCTS_UNSELECT_ALL
    }
  }
  
  export const selectOrderStoreProduct = data => {
    return {
      type: ORDERSTOREPRODUCT_SELECTED,
      payload: data
    }
  }
  
  export const loadOrderStoreProducts = (data) => {
    return {
      type: ORDERSTOREPRODUCTS_LOAD,
      payload: data
    };
  }
  
  export const loadOrderStoreProductsSuccess = (data) => {
    return {
      type: ORDERSTOREPRODUCTS_LOAD_SUCCESS,
      payload: data
    };
  }
  
  export const loadOrderStoreProductsFailed = () => {
    return {
      type: ORDERSTOREPRODUCTS_LOAD_FAILED
    };
  }
  
  export const loadOrderStoreProduct = () => {
    return {
      type: ORDERSTOREPRODUCT_LOAD
    };
  }
  
  export const loadOrderStoreProductSuccess = (data) => {
    return {
      type: ORDERSTOREPRODUCT_LOAD_SUCCESS,
      payload: data
    };
  }
  
  
  export const loadOrderStoreProductFailed = () => {
    return {
      type: ORDERSTOREPRODUCT_LOAD_FAILED
    };
  }
  
  
  export const addOrderStoreProduct = (data) => {
    return {
      type: ORDERSTOREPRODUCT_ADD,
      payload: data
    };
  }
  
  
  export const addOrderStoreProductSuccess = (filter) => {
    return {
      type: ORDERSTOREPRODUCT_ADD_SUCCESS,
      message: 'OrderStoreProduct added successfully.',
      payload: filter
    };
  }
  
  export const addOrderStoreProductFailed = () => {
    return {
      type: ORDERSTOREPRODUCT_ADD_FAILED
    };
  }
  
  export const updateOrderStoreProduct = (data) => {
    return {
      type: ORDERSTOREPRODUCT_UPDATE,
      payload: data
    };
  }
  
  
  export const updateOrderStoreProductSuccess = (data) => {
    return {
      type: ORDERSTOREPRODUCT_UPDATE_SUCCESS,
      payload: data
    };
  }
  
  export const updateOrderStoreProductFailed = (data) => {
    return {
      type: ORDERSTOREPRODUCT_UPDATE_FAILED,
      payload: data
    };
  }
  
  export const deleteOrderStoreProduct = (data) => {
    return {
      type: ORDERSTOREPRODUCT_DELETE,
      payload: data
    };
  }
  
  export const deleteOrderStoreProductSuccess = () => {
    return {
      type: ORDERSTOREPRODUCT_DELETE_SUCCESS,
      message: 'OrderStoreProduct deleted successfully.'
    };
  }
  
  export const deleteOrderStoreProductFailed = () => {
    return {
      type: ORDERSTOREPRODUCT_DELETE_FAILED
    };
  }
  
  export const deleteAllOrderStoreProducts = (data) => {
    return {
      type: ORDERSTOREPRODUCT_DELETE_ALL,
      payload: data
    };
  }
  
  export const deleteAllOrderStoreProductsSuccess = () => {
    return {
      type: ORDERSTOREPRODUCT_DELETE_ALL_SUCCESS,
      message: 'OrderStoreProduct all deleted successfully.'
    };
  }
  
  export const deleteAllOrderStoreProductsFailed = () => {
    return {
      type: ORDERSTOREPRODUCT_DELETE_ALL_FAILED
    };
  }
  
  export const exportExcelOrderStoreProducts = (filter) => {
    return {
      type: ORDERSTOREPRODUCTS_EXPORT_EXCEL,
      payload: filter
    }
  }
  
  export const exportExcelOrderStoreProductsSuccess = (data) => {
    return {
      type: ORDERSTOREPRODUCTS_EXPORT_EXCEL_SUCCESS,
      payload: data
    };
  }
  
  export const exportExcelOrderStoreProductsFailed = () => {
    return {
      type: ORDERSTOREPRODUCTS_EXPORT_EXCEL_FAILED
    };
  }
  
  export const reportOrderStoreProducts = (filter) => {
    return {
      type: ORDERSTOREPRODUCTS_REPORT,
      payload: filter
    }
  }
  
  export const reportOrderStoreProductsSuccess = (data) => {
    return {
      type: ORDERSTOREPRODUCTS_REPORT_SUCCESS,
      payload: data
    };
  }
  export const reportOrderStoreProductsFailed = () => {
    return {
      type: ORDERSTOREPRODUCTS_REPORT_FAILED
    };
  }