import { all, call, fork, put, takeEvery } from "redux-saga/effects";
import Api, { patchData } from './Api';
import { GET_ALL_SIZE } from '../constants/AppConstant';

import {
  loadCategories as loadCategoriesAction,
  loadCategoriesSuccess,
  addCategorySuccess,
  updateCategorySuccess,
  exportExcelCategoriesSuccess,
  reportCategoriesSuccess
} from '../actions/Category';

import {
  CATEGORIES_LOAD,
  CATEGORY_ADD,
  CATEGORY_UPDATE,
  CATEGORY_DELETE,
  CATEGORY_DELETE_ALL,
  CATEGORIES_EXPORT_EXCEL,
  CATEGORIES_REPORT
} from '../constants/ActionTypes';

import {
  userSignOut
} from '../actions/Auth';

export const getCategoryById = (id, callback) => {
  Api().get('category/' + id).then(response => {
    callback(response.data);
  })
}

export const getAllCategories = (callback, params) => {
  if (!params) {
    params = {
      limit: GET_ALL_SIZE
    }
  }

  Api().post('category/search', params)
    .then(response => {
      callback(response.data.records);        
    })
}

export const getCategoriesByCompany = (companyId, callback) => {
  Api().post('category/search', {
    limit: GET_ALL_SIZE,
    filterGroups: [{
      filters: [{
        property: 'companyId',
        operator: 'equal',
        value: companyId
      }]
    }]
  }).then(response => {
    callback(response.data.records);    
  })
}

export const getCategoriesByProvider = (type, provider, callback) => {
  Api().post('category/search', {
    limit: GET_ALL_SIZE,
    filterGroups: [{
      filters: [{
        property: 'providerId',
        operator: 'equal',
        value: type === 'firstLoad' ? provider.id : provider.providerId
      }]
    }]
  }).then(response => {
    callback(response.data.records);    
  })
}

export const getMaxCategoryCodeSorted = (type, callback) => {
  Api().post('category/search', { offset: 0,
    limit: 1,
    categoryBy: "codeSorted",
    descending: true,
    filterGroups: [{
      filters: [{
        property: 'type',
        operator: '=',
        value: type
      }]
    }]
  })
    .then(response => {
      let codeSorted = 0;
      let records = response.data.records;
      if (records.length) {
        codeSorted = records[0].codeSorted;
      }
      callback(codeSorted);
    })
}

const loadCategoriesRequest = async (params) => {
  if (params.filterGroups && params.filterGroups.length) {
    return await Api().post(`category/search`, params)
      .then(data => data)
      .catch(error => error);
  }

  return await Api().get(`category`, { params })
    .then(data => data)
    .catch(error => error);
}

const addCategoryRequest = async (payload) =>
  await Api().post(`category`, payload)
    .then(data => data)
    .catch(error => error);

const updateCategoryRequest = async (payload) =>
  await Api().patch(`category/${payload.id}`, patchData(payload))
    .then(data => data)
    .catch(error => error);

export const deleteCategoryRequest = async (id) =>
  await Api().delete(`category/${id}`)
    .then(data => data)
    .catch(error => error);

function* loadCategories({ payload }) {
  try {
    const data = yield call(loadCategoriesRequest, payload);
    data.status == 401 || data.response && data.response.status == 401 ? yield put(userSignOut()) :
    yield put(loadCategoriesSuccess(data.data));
  } catch (error) {
    //yield put(showAuthMessage(error));
  }
}

function* processLoadCategories() {
  yield takeEvery(CATEGORIES_LOAD, loadCategories);
}

function* loadCategory({ selectedCategoryId }) {
  try {
    const data = yield call(loadCategoriesRequest, selectedCategoryId);
    data.status == 401 || data.response && data.response.status == 401 ? yield put(userSignOut()) :
    yield put(loadCategoriesSuccess(data.data));
  } catch (error) {
    //yield put(showAuthMessage(error));
  }
}

function* processLoadCategory() {
  yield takeEvery(CATEGORIES_LOAD, loadCategory);
}


function* addCategory({ payload }) {
  try {
    const data = yield call(addCategoryRequest, payload.model);
    data.status == 401 || data.response && data.response.status == 401 ? yield put(userSignOut()) :
    yield put(addCategorySuccess(payload.filter));
  } catch (error) {
    //yield put(showAuthMessage(error));
  }
}

function* processAddCategory() {
  yield takeEvery(CATEGORY_ADD, addCategory);
}


function* updateCategory({ payload }) {
  try {
    const data = yield call(updateCategoryRequest, payload.model);
    data.status == 401 || data.response && data.response.status == 401 ? yield put(userSignOut()) :
    yield put(updateCategorySuccess(payload.filter));
  } catch (error) {
    //yield put(showAuthMessage(error));
  }
}

function* processUpdateCategory() {
  yield takeEvery(CATEGORY_UPDATE, updateCategory);
}

function* deleteCategory({ payload }) {
  try {
    const data = yield call(deleteCategoryRequest, payload.model.id);
    data.status == 401 || data.response && data.response.status == 401 ? yield put(userSignOut()) :
    yield put(loadCategoriesAction(payload.filter));
  } catch (error) {
    //yield put(showAuthMessage(error));
  }
}

function* deleteAllCategories({ payload }) {
  try {
    for (let i = 0; i < payload.models.length; ++i) {
      const data = yield call(deleteCategoryRequest, payload.models[i].id);
      if (data.status == 401 || data.response && data.response.status == 401) { yield put(userSignOut()); break; }
    }

    yield put(loadCategoriesAction(payload.filter));
  } catch (error) {
    //yield put(showAuthMessage(error));
  }
}

function* processDeleteCategory() {
  yield takeEvery(CATEGORY_DELETE, deleteCategory);
}

function* processDeleteAllCategories() {
  yield takeEvery(CATEGORY_DELETE_ALL, deleteAllCategories);
}

const exportCategoriesRequest = async (params) => {
  return await Api({responseType: 'blob'}).post(`category/export/excel`, params)
    .then(data => data)
    .catch(error => error);
}


function* exportCategories({ payload }) {
  try {
    const data = yield call(exportCategoriesRequest, payload);
    data.status == 401 || data.response && data.response.status == 401 ? yield put(userSignOut()) :
    yield put(exportExcelCategoriesSuccess(data.data));
  } catch (error) {
    //yield put(showAuthMessage(error));
  }
}

function* processExportCategories() {
  yield takeEvery(CATEGORIES_EXPORT_EXCEL, exportCategories);
}

const reportCategoriesRequest = async (params) => {
  return await Api().post(`category/report`, params)
    .then(data => data)
    .catch(error => error);
}


function* reportCategories({ payload }) {
  try {
    if (!payload) { payload = {} }
    const data = yield call(reportCategoriesRequest, payload);
    data.status == 401 || data.response && data.response.status == 401 ? yield put(userSignOut()) :
    yield put(reportCategoriesSuccess(data.data));
  } catch (error) {
    //yield put(showAuthMessage(error));
  }
}

function* processReportCategories() {
  yield takeEvery(CATEGORIES_REPORT, reportCategories);
}

export default function* CategorySagas() {
  yield all([fork(processLoadCategories),
  fork(processLoadCategory),
  fork(processAddCategory),
  fork(processUpdateCategory),
  fork(processDeleteAllCategories),
  fork(processDeleteCategory),
  fork(processExportCategories),
  fork(processReportCategories)
]);
}
