import {
  ACCOUNTLEDGERS_SELECT_ALL,
  ACCOUNTLEDGERS_UNSELECT_ALL,
  ACCOUNTLEDGER_SELECTED,
  ACCOUNTLEDGERS_LOAD,
  ACCOUNTLEDGERS_LOAD_SUCCESS,
  ACCOUNTLEDGERS_LOAD_FAILED,
  ACCOUNTLEDGER_LOAD,
  ACCOUNTLEDGER_LOAD_SUCCESS,
  ACCOUNTLEDGER_LOAD_FAILED,
  ACCOUNTLEDGER_ADD,
  ACCOUNTLEDGER_ADD_SUCCESS,
  ACCOUNTLEDGER_ADD_FAILED,
  ACCOUNTLEDGER_UPDATE,
  ACCOUNTLEDGER_UPDATE_SUCCESS,
  ACCOUNTLEDGER_UPDATE_FAILED,
  ACCOUNTLEDGER_DELETE,
  ACCOUNTLEDGER_DELETE_SUCCESS,
  ACCOUNTLEDGER_DELETE_FAILED,
  ACCOUNTLEDGER_DELETE_ALL,
  ACCOUNTLEDGER_DELETE_ALL_SUCCESS,
  ACCOUNTLEDGER_DELETE_ALL_FAILED,
  ACCOUNTLEDGERS_EXPORT_EXCEL,
  ACCOUNTLEDGERS_EXPORT_EXCEL_SUCCESS,
  ACCOUNTLEDGERS_EXPORT_EXCEL_FAILED,
  ACCOUNTLEDGERS_REPORT,
  ACCOUNTLEDGERS_REPORT_SUCCESS,
  ACCOUNTLEDGERS_REPORT_FAILED,
  ACCOUNTLEDGERS_REPORTSUM,
  ACCOUNTLEDGERS_REPORTSUM_SUCCESS,
  ACCOUNTLEDGERS_REPORTSUM_FAILED
} from '../constants/ActionTypes';

export const selectAllAccountLedgers = () => {
  return {
    type: ACCOUNTLEDGERS_SELECT_ALL
  }
}

export const unselectAllAccountLedgers = () => {
  return {
    type: ACCOUNTLEDGERS_UNSELECT_ALL
  }
}

export const selectAccountLedger = data => {
  return {
    type: ACCOUNTLEDGER_SELECTED,
    payload: data
  }
}

export const loadAccountLedgers = (data) => {
  return {
    type: ACCOUNTLEDGERS_LOAD,
    payload: data
  };
}

export const loadAccountLedgersSuccess = (data) => {
  return {
    type: ACCOUNTLEDGERS_LOAD_SUCCESS,
    payload: data
  };
}

export const loadAccountLedgersFailed = () => {
  return {
    type: ACCOUNTLEDGERS_LOAD_FAILED
  };
}

export const loadAccountLedger = () => {
  return {
    type: ACCOUNTLEDGER_LOAD
  };
}

export const loadAccountLedgerSuccess = (data) => {
  return {
    type: ACCOUNTLEDGER_LOAD_SUCCESS,
    payload: data
  };
}


export const loadAccountLedgerFailed = () => {
  return {
    type: ACCOUNTLEDGER_LOAD_FAILED
  };
}


export const addAccountLedger = (data) => {
  return {
    type: ACCOUNTLEDGER_ADD,
    payload: data
  };
}


export const addAccountLedgerSuccess = (filter) => {
  return {
    type: ACCOUNTLEDGER_ADD_SUCCESS,
    message: 'AccountLedger added successfully.',
    payload: filter
  };
}

export const addAccountLedgerFailed = () => {
  return {
    type: ACCOUNTLEDGER_ADD_FAILED
  };
}

export const updateAccountLedger = (data) => {
  return {
    type: ACCOUNTLEDGER_UPDATE,
    payload: data
  };
}


export const updateAccountLedgerSuccess = (data) => {
  return {
    type: ACCOUNTLEDGER_UPDATE_SUCCESS,
    payload: data
  };
}

export const updateAccountLedgerFailed = (data) => {
  return {
    type: ACCOUNTLEDGER_UPDATE_FAILED,
    payload: data
  };
}

export const deleteAccountLedger = (data) => {
  return {
    type: ACCOUNTLEDGER_DELETE,
    payload: data
  };
}

export const deleteAccountLedgerSuccess = () => {
  return {
    type: ACCOUNTLEDGER_DELETE_SUCCESS,
    message: 'AccountLedger deleted successfully.'
  };
}

export const deleteAccountLedgerFailed = () => {
  return {
    type: ACCOUNTLEDGER_DELETE_FAILED
  };
}

export const deleteAllAccountLedgers = (data) => {
  return {
    type: ACCOUNTLEDGER_DELETE_ALL,
    payload: data
  };
}

export const deleteAllAccountLedgersSuccess = () => {
  return {
    type: ACCOUNTLEDGER_DELETE_ALL_SUCCESS,
    message: 'AccountLedger all deleted successfully.'
  };
}

export const deleteAllAccountLedgersFailed = () => {
  return {
    type: ACCOUNTLEDGER_DELETE_ALL_FAILED
  };
}

export const exportExcelAccountLedgers = (filter) => {
  return {
    type:ACCOUNTLEDGERS_EXPORT_EXCEL,
    payload: filter
  } 
}

export const exportExcelAccountLedgersSuccess = (data) => {
  return {
    type:ACCOUNTLEDGERS_EXPORT_EXCEL_SUCCESS,
    payload: data
  };
}

export const exportExcelAccountLedgersFailed = () => {
  return {
    type:ACCOUNTLEDGERS_EXPORT_EXCEL_FAILED
  };
}

export const reportAccountLedgers = (filter) => {
  return {
    type: ACCOUNTLEDGERS_REPORT,
    payload: filter
  }
}

export const reportAccountLedgersSuccess = (data) => {
  return {
    type: ACCOUNTLEDGERS_REPORT_SUCCESS,
    payload: data
  };
}

export const reportAccountLedgersFailed = () => {
  return {
    type: ACCOUNTLEDGERS_REPORT_FAILED
  };
}

export const reportSumAccountLedgersSuccess = (data) => {
  return {
    type: ACCOUNTLEDGERS_REPORTSUM_SUCCESS,
    payload: data
  };
}