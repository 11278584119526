import { all, call, fork, put, takeEvery } from "redux-saga/effects";
import Api, { patchData } from './Api';
import { GET_ALL_SIZE } from '../constants/AppConstant';

import {
  loadOrderProducts as loadOrderProductsAction,
  loadOrderProductsSuccess,
  addOrderProductSuccess,
  updateOrderProductSuccess,
  exportExcelOrderProductsSuccess,
  reportOrderProductsSuccess
} from '../actions/OrderProduct';

import {
  ORDERPRODUCTS_LOAD,
  ORDERPRODUCT_ADD,
  ORDERPRODUCT_UPDATE,
  ORDERPRODUCT_DELETE,
  ORDERPRODUCT_DELETE_ALL,
  ORDERPRODUCTS_EXPORT_EXCEL,
  ORDERPRODUCTS_REPORT
} from '../constants/ActionTypes';

import {
  userSignOut
} from '../actions/Auth';

export const getOrderProductById = (id, callback) => {
  Api().get('order-product/' + id).then(response => {
    callback(response.data);
  })
}

export const getOrderProductsTotalMoney = (callback, params) => {
  Api().post('order-product/total-money', params)
    .then(response => {
      callback(response.data);
    })
}

export const getAllOrderProducts = (callback, params) => {
  if (!params) {
    params = {
      limit: GET_ALL_SIZE
    }
  }

  Api().post('order-product/search', params)
    .then(response => {
      callback(response.data.records);        
    })
}

export const getOrderProductsByCompany = (companyId, callback) => {
  Api().post('order-product/search', {
    limit: GET_ALL_SIZE,
    filterGroups: [{
      filters: [{
        property: 'companyId',
        operator: 'equal',
        value: companyId
      }]
    }]
  }).then(response => {
    callback(response.data.records);    
  })
}

export const getMaxOrderProductCodeSorted = (callback) => {
  Api().post('order-product/search', { offset: 0,
    limit: 1,
    orderBy: "codeSorted",
    descending: true
  })
    .then(response => {
      let codeSorted = 0;
      let records = response.data.records;
      if (records.length) {
        codeSorted = records[0].codeSorted;
      }
      callback(codeSorted);
    })
}

const loadOrderProductsRequest = async (params) => {
  if (params.filterGroups && params.filterGroups.length) {
    return await Api().post(`order-product/search`, params)
      .then(data => data)
      .catch(error => error);
  }

  return await Api().get(`order-product`, { params })
    .then(data => data)
    .catch(error => error);
}

const addOrderProductRequest = async (payload) =>
  await Api().post(`order-product`, payload)
    .then(data => data)
    .catch(error => error);

const updateOrderProductRequest = async (payload) =>
  await Api().patch(`order-product/${payload.id}`, patchData(payload))
    .then(data => data)
    .catch(error => error);

export const deleteOrderProductRequest = async (id) =>
  await Api().delete(`order-product/${id}`)
    .then(data => data)
    .catch(error => error);

function* loadOrderProducts({ payload }) {
  try {
    const data = yield call(loadOrderProductsRequest, payload);
    data.status == 401 || data.response && data.response.status == 401 ? yield put(userSignOut()) :
    yield put(loadOrderProductsSuccess(data.data));
  } catch (error) {
    //yield put(showAuthMessage(error));
  }
}

function* processLoadOrderProducts() {
  yield takeEvery(ORDERPRODUCTS_LOAD, loadOrderProducts);
}

function* loadOrderProduct({ selectedOrderProductId }) {
  try {
    const data = yield call(loadOrderProductsRequest, selectedOrderProductId);
    data.status == 401 || data.response && data.response.status == 401 ? yield put(userSignOut()) :
    yield put(loadOrderProductsSuccess(data.data));
  } catch (error) {
    //yield put(showAuthMessage(error));
  }
}

function* processLoadOrderProduct() {
  yield takeEvery(ORDERPRODUCTS_LOAD, loadOrderProduct);
}


function* addOrderProduct({ payload }) {
  try {
    const data = yield call(addOrderProductRequest, payload.model);
    data.status == 401 || data.response && data.response.status == 401 ? yield put(userSignOut()) :
    yield put(addOrderProductSuccess(payload.filter));
  } catch (error) {
    //yield put(showAuthMessage(error));
  }
}

function* processAddOrderProduct() {
  yield takeEvery(ORDERPRODUCT_ADD, addOrderProduct);
}


function* updateOrderProduct({ payload }) {
  try {
    const data = yield call(updateOrderProductRequest, payload.model);
    data.status == 401 || data.response && data.response.status == 401 ? yield put(userSignOut()) :
    yield put(updateOrderProductSuccess(payload.filter));
  } catch (error) {
    //yield put(showAuthMessage(error));
  }
}

function* processUpdateOrderProduct() {
  yield takeEvery(ORDERPRODUCT_UPDATE, updateOrderProduct);
}

function* deleteOrderProduct({ payload }) {
  try {
    const data = yield call(deleteOrderProductRequest, payload.model.id);
    data.status == 401 || data.response && data.response.status == 401 ? yield put(userSignOut()) :
    yield put(loadOrderProductsAction(payload.filter));
  } catch (error) {
    //yield put(showAuthMessage(error));
  }
}

function* deleteAllOrderProducts({ payload }) {
  try {
    for (let i = 0; i < payload.models.length; ++i) {
      const data = yield call(deleteOrderProductRequest, payload.models[i].id);
      if (data.status == 401 || data.response && data.response.status == 401) { yield put(userSignOut()); break; }
    }

    yield put(loadOrderProductsAction(payload.filter));
  } catch (error) {
    //yield put(showAuthMessage(error));
  }
}

function* processDeleteOrderProduct() {
  yield takeEvery(ORDERPRODUCT_DELETE, deleteOrderProduct);
}

function* processDeleteAllOrderProducts() {
  yield takeEvery(ORDERPRODUCT_DELETE_ALL, deleteAllOrderProducts);
}

const exportOrderProductsRequest = async (params) => {
  return await Api({responseType: 'blob'}).post(`order-product/export/excel`, params)
    .then(data => data)
    .catch(error => error);
}


function* exportOrderProducts({ payload }) {
  try {
    const data = yield call(exportOrderProductsRequest, payload);
    data.status == 401 || data.response && data.response.status == 401 ? yield put(userSignOut()) :
    yield put(exportExcelOrderProductsSuccess(data.data));
  } catch (error) {
    //yield put(showAuthMessage(error));
  }
}

function* processExportOrderProducts() {
  yield takeEvery(ORDERPRODUCTS_EXPORT_EXCEL, exportOrderProducts);
}

const reportOrderProductsRequest = async (params) => {
  return await Api().post(`order-product/report`, params)
    .then(data => data)
    .catch(error => error);
}


function* reportOrderProducts({ payload }) {
  try {
    if (!payload) { payload = {} }
    const data = yield call(reportOrderProductsRequest, payload);
    data.status == 401 || data.response && data.response.status == 401 ? yield put(userSignOut()) :
    yield put(reportOrderProductsSuccess(data.data));
  } catch (error) {
    //yield put(showAuthMessage(error));
  }
}

function* processReportOrderProducts() {
  yield takeEvery(ORDERPRODUCTS_REPORT, reportOrderProducts);
}

export default function* OrderProductSagas() {
  yield all([fork(processLoadOrderProducts),
  fork(processLoadOrderProduct),
  fork(processAddOrderProduct),
  fork(processUpdateOrderProduct),
  fork(processDeleteAllOrderProducts),
  fork(processDeleteOrderProduct),
  fork(processExportOrderProducts),
  fork(processReportOrderProducts)
]);
}
