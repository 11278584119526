import { all, call, fork, put, takeEvery } from "redux-saga/effects";
import Api, { patchData } from './Api';
import { GET_ALL_SIZE } from '../constants/AppConstant';

import {
  loadOrderStores as loadOrderStoresAction,
  loadOrderStoresSuccess,
  addOrderStoreSuccess,
  updateOrderStoreSuccess,
  exportExcelOrderStoresSuccess,
  reportOrderStoresSuccess
} from '../actions/OrderStore';

import {
  ORDERSTORES_LOAD,
  ORDERSTORE_ADD,
  ORDERSTORE_UPDATE,
  ORDERSTORE_DELETE,
  ORDERSTORE_DELETE_ALL,
  ORDERSTORES_EXPORT_EXCEL,
  ORDERSTORES_REPORT
} from '../constants/ActionTypes';

import {
  userSignOut
} from '../actions/Auth';

export const getOrderStoreById = (id, callback) => {
  Api().get('order-store/' + id).then(response => {
    callback(response.data);
  })
}

export const getAllOrderStores = (callback, params) => {
  if (!params) {
    params = {
      limit: GET_ALL_SIZE
    }
  }

  Api().post('order-store/search', params)
    .then(response => {
      callback(response.data.records);        
    })
}

export const changeOrderStoreStatus = (model, callback) => {
  Api().post('order-store/change-status', model).then(response => {
    callback(response);
  })
}

export const getOrderStoresByCompany = (companyId, callback) => {
  Api().post('order-store/search', {
    limit: GET_ALL_SIZE,
    filterGroups: [{
      filters: [{
        property: 'companyId',
        operator: 'equal',
        value: companyId
      }]
    }]
  }).then(response => {
    callback(response.data.records);    
  })
}

export const getMaxOrderStoreCodeSorted = (type, callback) => {
  Api().post('order-store/search', { offset: 0,
    limit: 1,
    orderBy: "codeSorted",
    descending: true,
    filterGroups: [{
      filters: [{
        property: 'type',
        operator: '=',
        value: type
      }]
    }]
  })
    .then(response => {
      let codeSorted = 0;
      let records = response.data.records;
      if (records.length) {
        codeSorted = records[0].codeSorted;
      }
      callback(codeSorted);
    })
}

const loadOrderStoresRequest = async (params) => {
  if (params.filterGroups && params.filterGroups.length) {
    return await Api().post(`order-store/search`, params)
      .then(data => data)
      .catch(error => error);
  }

  return await Api().get(`order-store`, { params })
    .then(data => data)
    .catch(error => error);
}

const addOrderStoreRequest = async (payload) =>
  await Api().post(`order-store/add`, payload)
    .then(data => data)
    .catch(error => error);

const updateOrderStoreRequest = async (payload) =>
  await Api().patch(`order-store/update/${payload.id}`, patchData(payload))
    .then(data => data)
    .catch(error => error);

export const deleteOrderStoreRequest = async (id) =>
  await Api().delete(`order-store/${id}`)
    .then(data => data)
    .catch(error => error);

function* loadOrderStores({ payload }) {
  try {
    const data = yield call(loadOrderStoresRequest, payload);
    data.status == 401 || data.response && data.response.status == 401 ? yield put(userSignOut()) :
    yield put(loadOrderStoresSuccess(data.data));
  } catch (error) {
    //yield put(showAuthMessage(error));
  }
}

function* processLoadOrderStores() {
  yield takeEvery(ORDERSTORES_LOAD, loadOrderStores);
}

function* loadOrderStore({ selectedOrderStoreId }) {
  try {
    const data = yield call(loadOrderStoresRequest, selectedOrderStoreId);
    data.status == 401 || data.response && data.response.status == 401 ? yield put(userSignOut()) :
    yield put(loadOrderStoresSuccess(data.data));
  } catch (error) {
    //yield put(showAuthMessage(error));
  }
}

function* processLoadOrderStore() {
  yield takeEvery(ORDERSTORES_LOAD, loadOrderStore);
}


function* addOrderStore({ payload }) {
  try {
    const data = yield call(addOrderStoreRequest, payload.model);
    data.status == 401 || data.response && data.response.status == 401 ? yield put(userSignOut()) :
    yield put(addOrderStoreSuccess(payload.filter));
  } catch (error) {
    //yield put(showAuthMessage(error));
  }
}

function* processAddOrderStore() {
  yield takeEvery(ORDERSTORE_ADD, addOrderStore);
}


function* updateOrderStore({ payload }) {
  try {
    const data = yield call(updateOrderStoreRequest, payload.model);
    data.status == 401 || data.response && data.response.status == 401 ? yield put(userSignOut()) :
    yield put(updateOrderStoreSuccess(payload.filter));
  } catch (error) {
    //yield put(showAuthMessage(error));
  }
}

function* processUpdateOrderStore() {
  yield takeEvery(ORDERSTORE_UPDATE, updateOrderStore);
}

function* deleteOrderStore({ payload }) {
  try {
    const data = yield call(deleteOrderStoreRequest, payload.model.id);
    data.status == 401 || data.response && data.response.status == 401 ? yield put(userSignOut()) :
    yield put(loadOrderStoresAction(payload.filter));
  } catch (error) {
    //yield put(showAuthMessage(error));
  }
}

function* deleteAllOrderStores({ payload }) {
  try {
    for (let i = 0; i < payload.models.length; ++i) {
      const data = yield call(deleteOrderStoreRequest, payload.models[i].id);
      if (data.status == 401 || data.response && data.response.status == 401) { yield put(userSignOut()); break; }
    }

    yield put(loadOrderStoresAction(payload.filter));
  } catch (error) {
    //yield put(showAuthMessage(error));
  }
}

function* processDeleteOrderStore() {
  yield takeEvery(ORDERSTORE_DELETE, deleteOrderStore);
}

function* processDeleteAllOrderStores() {
  yield takeEvery(ORDERSTORE_DELETE_ALL, deleteAllOrderStores);
}

const exportOrderStoresRequest = async (params) => {
  return await Api({responseType: 'blob'}).post(`order-store/export/excel`, params)
    .then(data => data)
    .catch(error => error);
}


function* exportOrderStores({ payload }) {
  try {
    const data = yield call(exportOrderStoresRequest, payload);
    data.status == 401 || data.response && data.response.status == 401 ? yield put(userSignOut()) :
    yield put(exportExcelOrderStoresSuccess(data.data));
  } catch (error) {
    //yield put(showAuthMessage(error));
  }
}

function* processExportOrderStores() {
  yield takeEvery(ORDERSTORES_EXPORT_EXCEL, exportOrderStores);
}

const reportOrderStoresRequest = async (params) => {
  return await Api().post(`order-store/report`, params)
    .then(data => data)
    .catch(error => error);
}


function* reportOrderStores({ payload }) {
  try {
    if (!payload) { payload = {} }
    const data = yield call(reportOrderStoresRequest, payload);
    data.status == 401 || data.response && data.response.status == 401 ? yield put(userSignOut()) :
    yield put(reportOrderStoresSuccess(data.data));
  } catch (error) {
    //yield put(showAuthMessage(error));
  }
}

function* processReportOrderStores() {
  yield takeEvery(ORDERSTORES_REPORT, reportOrderStores);
}

export default function* OrderStoreSagas() {
  yield all([fork(processLoadOrderStores),
  fork(processLoadOrderStore),
  fork(processAddOrderStore),
  fork(processUpdateOrderStore),
  fork(processDeleteAllOrderStores),
  fork(processDeleteOrderStore),
  fork(processExportOrderStores),
  fork(processReportOrderStores)
]);
}
