// Customizer const
export const TOGGLE_COLLAPSED_NAV = 'TOGGLE_COLLAPSE_MENU';
export const WINDOW_WIDTH = 'WINDOW-WIDTH';
export const SWITCH_LANGUAGE = 'SWITCH-LANGUAGE';

export const SHOW_MESSAGE = 'SHOW_MESSAGE';
export const HIDE_MESSAGE = 'HIDE_MESSAGE';
export const ON_SHOW_LOADER = 'ON_SHOW_LOADER';
export const ON_HIDE_LOADER = 'ON_HIDE_LOADER';
export const SHOW_LOADING = 'SHOW_LOADING';
export const HIDE_LOADING = 'HIDE_LOADING';
export const SAVE_USER = 'SAVE_USER';
export const SAVE_USER_SUCCESS = 'SAVE_USER_SUCCESS';
export const SAVE_EMPLOYEE = 'SAVE_EMPLOYEE';
export const SAVE_EMPLOYEE_SUCCESS = 'SAVE_EMPLOYEE_SUCCESS';
export const DIRECT_DATA = 'DIRECT_DATA';
export const CLEAR_DIRECT_DATA = 'CLEAR_DIRECT_DATA';

//Auth const
export const SIGNUP_USER = 'SIGNUP_USER';
export const SIGNUP_USER_SUCCESS = 'SIGNUP_USER_SUCCESS';
export const SIGNIN_GOOGLE_USER = 'SIGNIN_GOOGLE_USER';
export const SIGNIN_GOOGLE_USER_SUCCESS = 'SIGNIN_GOOGLE_USER_SUCCESS';
export const SIGNIN_FACEBOOK_USER = 'SIGNIN_FACEBOOK_USER';
export const SIGNIN_FACEBOOK_USER_SUCCESS = 'SIGNIN_FACEBOOK_USER_SUCCESS';
export const SIGNIN_TWITTER_USER = 'SIGNIN_TWITTER_USER';
export const SIGNIN_TWITTER_USER_SUCCESS = 'SIGNIN_TWITTER_USER_SUCCESS';
export const SIGNIN_GITHUB_USER = 'SIGNIN_GITHUB_USER';
export const SIGNIN_GITHUB_USER_SUCCESS = 'signin_github_user_success';
export const SIGNIN_USER = 'SIGNIN_USER';
export const SIGNIN_USER_SUCCESS = 'SIGNIN_USER_SUCCESS';
export const SIGNOUT_USER = 'SIGNOUT_USER';
export const SIGNOUT_USER_SUCCESS = 'SIGNOUT_USER_SUCCESS';
export const INIT_URL = 'INIT_URL';

// Setting const
export const SETTINGS_SELECT_ALL = 'SETTINGS_SELECT_ALL';
export const SETTINGS_UNSELECT_ALL = 'SETTINGS_UNSELECT_ALL';
export const SETTING_SELECTED = 'SETTING_SELECTED';
export const SETTING_LOAD = 'SETTING_LOAD';
export const SETTING_LOAD_SUCCESS = 'SETTING_LOAD_SUCCESS';
export const SETTING_LOAD_FAILED = 'SETTING_LOAD_FAILED';
export const SETTINGS_LOAD = 'SETTINGS_LOAD';
export const SETTINGS_LOAD_SUCCESS = 'SETTINGS_LOAD_SUCCESS';
export const SETTINGS_LOAD_FAILED = 'SETTINGS_LOAD_FAILED';
export const SETTING_ADD = 'SETTING_ADD';
export const SETTING_ADD_SUCCESS = 'SETTING_ADD_SUCCESS';
export const SETTING_ADD_FAILED = 'SETTING_ADD_FAILED';
export const SETTING_UPDATE = 'SETTING_UPDATE';
export const SETTING_UPDATE_SUCCESS = 'SETTING_UPDATE_SUCCESS';
export const SETTING_UPDATE_FAILED = 'SETTING_UPDATE_FAILED';
export const SETTING_DELETE = 'SETTING_DELETE';
export const SETTING_DELETE_SUCCESS = 'SETTING_DELETE_SUCCESS';
export const SETTING_DELETE_FAILED = 'SETTING_DELETE_FAILED';
export const SETTING_DELETE_ALL = 'SETTING_DELETE_ALL';
export const SETTING_DELETE_ALL_SUCCESS = 'SETTING_DELETE_ALL_SUCCESS';
export const SETTING_DELETE_ALL_FAILED = 'SETTING_DELETE_ALL_FAILED';
export const SETTINGS_EXPORT_EXCEL = 'SETTINGS_EXPORT_EXCEL';
export const SETTINGS_EXPORT_EXCEL_SUCCESS = 'SETTINGS_EXPORT_EXCEL_SUCCESS';
export const SETTINGS_EXPORT_EXCEL_FAILED = 'SETTINGS_EXPORT_EXCEL_FAILED';

// Company const
export const COMPANIES_SELECT_ALL = 'COMPANIES_SELECT_ALL';
export const COMPANIES_UNSELECT_ALL = 'COMPANIES_UNSELECT_ALL';
export const COMPANY_SELECTED = 'COMPANY_SELECTED';
export const COMPANY_LOAD = 'COMPANY_LOAD';
export const COMPANY_LOAD_SUCCESS = 'COMPANY_LOAD_SUCCESS';
export const COMPANY_LOAD_FAILED = 'COMPANY_LOAD_FAILED';
export const COMPANIES_LOAD = 'COMPANIES_LOAD';
export const COMPANIES_LOAD_SUCCESS = 'COMPANIES_LOAD_SUCCESS';
export const COMPANIES_LOAD_FAILED = 'COMPANIES_LOAD_FAILED';
export const COMPANY_ADD = 'COMPANY_ADD';
export const COMPANY_ADD_SUCCESS = 'COMPANY_ADD_SUCCESS';
export const COMPANY_ADD_FAILED = 'COMPANY_ADD_FAILED';
export const COMPANY_UPDATE = 'COMPANY_UPDATE';
export const COMPANY_UPDATE_SUCCESS = 'COMPANY_UPDATE_SUCCESS';
export const COMPANY_UPDATE_FAILED = 'COMPANY_UPDATE_FAILED';
export const COMPANY_DELETE = 'COMPANY_DELETE';
export const COMPANY_DELETE_SUCCESS = 'COMPANY_DELETE_SUCCESS';
export const COMPANY_DELETE_FAILED = 'COMPANY_DELETE_FAILED';
export const COMPANY_DELETE_ALL = 'COMPANY_DELETE_ALL';
export const COMPANY_DELETE_ALL_SUCCESS = 'COMPANY_DELETE_ALL_SUCCESS';
export const COMPANY_DELETE_ALL_FAILED = 'COMPANY_DELETE_ALL_FAILED';
export const COMPANIES_EXPORT_EXCEL = 'COMPANIES_EXPORT_EXCEL';
export const COMPANIES_EXPORT_EXCEL_SUCCESS = 'COMPANIES_EXPORT_EXCEL_SUCCESS';
export const COMPANIES_EXPORT_EXCEL_FAILED = 'COMPANIES_EXPORT_EXCEL_FAILED';

// Department const
export const DEPARTMENTS_SELECT_ALL = 'DEPARTMENTS_SELECT_ALL';
export const DEPARTMENTS_UNSELECT_ALL = 'DEPARTMENTS_UNSELECT_ALL';
export const DEPARTMENT_SELECTED = 'DEPARTMENT_SELECTED';
export const DEPARTMENT_LOAD = 'DEPARTMENT_LOAD';
export const DEPARTMENT_LOAD_SUCCESS = 'DEPARTMENT_LOAD_SUCCESS';
export const DEPARTMENT_LOAD_FAILED = 'DEPARTMENT_LOAD_FAILED';
export const DEPARTMENTS_LOAD = 'DEPARTMENTS_LOAD';
export const DEPARTMENTS_LOAD_SUCCESS = 'DEPARTMENTS_LOAD_SUCCESS';
export const DEPARTMENTS_LOAD_FAILED = 'DEPARTMENTS_LOAD_FAILED';
export const DEPARTMENT_ADD = 'DEPARTMENT_ADD';
export const DEPARTMENT_ADD_SUCCESS = 'DEPARTMENT_ADD_SUCCESS';
export const DEPARTMENT_ADD_FAILED = 'DEPARTMENT_ADD_FAILED';
export const DEPARTMENT_UPDATE = 'DEPARTMENT_UPDATE';
export const DEPARTMENT_UPDATE_SUCCESS = 'DEPARTMENT_UPDATE_SUCCESS';
export const DEPARTMENT_UPDATE_FAILED = 'DEPARTMENT_UPDATE_FAILED';
export const DEPARTMENT_DELETE = 'DEPARTMENT_DELETE';
export const DEPARTMENT_DELETE_SUCCESS = 'DEPARTMENT_DELETE_SUCCESS';
export const DEPARTMENT_DELETE_FAILED = 'DEPARTMENT_DELETE_FAILED';
export const DEPARTMENT_DELETE_ALL = 'DEPARTMENT_DELETE_ALL';
export const DEPARTMENT_DELETE_ALL_SUCCESS = 'DEPARTMENT_DELETE_ALL_SUCCESS';
export const DEPARTMENT_DELETE_ALL_FAILED = 'DEPARTMENT_DELETE_ALL_FAILED';
export const DEPARTMENTS_EXPORT_EXCEL = 'DEPARTMENTS_EXPORT_EXCEL';
export const DEPARTMENTS_EXPORT_EXCEL_SUCCESS = 'DEPARTMENTS_EXPORT_EXCEL_SUCCESS';
export const DEPARTMENTS_EXPORT_EXCEL_FAILED = 'DEPARTMENTS_EXPORT_EXCEL_FAILED';

// Employee const
export const EMPLOYEES_SELECT_ALL = 'EMPLOYEES_SELECT_ALL';
export const EMPLOYEES_UNSELECT_ALL = 'EMPLOYEES_UNSELECT_ALL';
export const EMPLOYEE_SELECTED = 'EMPLOYEE_SELECTED';
export const EMPLOYEE_LOAD = 'EMPLOYEE_LOAD';
export const EMPLOYEE_LOAD_SUCCESS = 'EMPLOYEE_LOAD_SUCCESS';
export const EMPLOYEE_LOAD_FAILED = 'EMPLOYEE_LOAD_FAILED';
export const EMPLOYEES_LOAD = 'EMPLOYEES_LOAD';
export const EMPLOYEES_LOAD_SUCCESS = 'EMPLOYEES_LOAD_SUCCESS';
export const EMPLOYEES_LOAD_FAILED = 'EMPLOYEES_LOAD_FAILED';
export const EMPLOYEE_ADD = 'EMPLOYEE_ADD';
export const EMPLOYEE_ADD_SUCCESS = 'EMPLOYEE_ADD_SUCCESS';
export const EMPLOYEE_ADD_FAILED = 'EMPLOYEE_ADD_FAILED';
export const EMPLOYEE_UPDATE = 'EMPLOYEE_UPDATE';
export const EMPLOYEE_UPDATE_SUCCESS = 'EMPLOYEE_UPDATE_SUCCESS';
export const EMPLOYEE_UPDATE_FAILED = 'EMPLOYEE_UPDATE_FAILED';
export const EMPLOYEE_DELETE = 'EMPLOYEE_DELETE';
export const EMPLOYEE_DELETE_SUCCESS = 'EMPLOYEE_DELETE_SUCCESS';
export const EMPLOYEE_DELETE_FAILED = 'EMPLOYEE_DELETE_FAILED';
export const EMPLOYEE_DELETE_ALL = 'EMPLOYEE_DELETE_ALL';
export const EMPLOYEE_DELETE_ALL_SUCCESS = 'EMPLOYEE_DELETE_ALL_SUCCESS';
export const EMPLOYEE_DELETE_ALL_FAILED = 'EMPLOYEE_DELETE_ALL_FAILED';
export const EMPLOYEES_EXPORT_EXCEL = 'EMPLOYEES_EXPORT_EXCEL';
export const EMPLOYEES_EXPORT_EXCEL_SUCCESS = 'EMPLOYEES_EXPORT_EXCEL_SUCCESS';
export const EMPLOYEES_EXPORT_EXCEL_FAILED = 'EMPLOYEES_EXPORT_EXCEL_FAILED';

// Personal paper const
export const PERSONALPAPERS_SELECT_ALL = 'PERSONALPAPERS_SELECT_ALL';
export const PERSONALPAPERS_UNSELECT_ALL = 'PERSONALPAPERS_UNSELECT_ALL';
export const PERSONALPAPER_SELECTED = 'PERSONALPAPER_SELECTED';
export const PERSONALPAPER_LOAD = 'PERSONALPAPER_LOAD';
export const PERSONALPAPER_LOAD_SUCCESS = 'PERSONALPAPER_LOAD_SUCCESS';
export const PERSONALPAPER_LOAD_FAILED = 'PERSONALPAPER_LOAD_FAILED';
export const PERSONALPAPERS_LOAD = 'PERSONALPAPERS_LOAD';
export const PERSONALPAPERS_LOAD_SUCCESS = 'PERSONALPAPERS_LOAD_SUCCESS';
export const PERSONALPAPERS_LOAD_FAILED = 'PERSONALPAPERS_LOAD_FAILED';
export const PERSONALPAPER_ADD = 'PERSONALPAPER_ADD';
export const PERSONALPAPER_ADD_SUCCESS = 'PERSONALPAPER_ADD_SUCCESS';
export const PERSONALPAPER_ADD_FAILED = 'PERSONALPAPER_ADD_FAILED';
export const PERSONALPAPER_UPDATE = 'PERSONALPAPER_UPDATE';
export const PERSONALPAPER_UPDATE_SUCCESS = 'PERSONALPAPER_UPDATE_SUCCESS';
export const PERSONALPAPER_UPDATE_FAILED = 'PERSONALPAPER_UPDATE_FAILED';
export const PERSONALPAPER_DELETE = 'PERSONALPAPER_DELETE';
export const PERSONALPAPER_DELETE_SUCCESS = 'PERSONALPAPER_DELETE_SUCCESS';
export const PERSONALPAPER_DELETE_FAILED = 'PERSONALPAPER_DELETE_FAILED';
export const PERSONALPAPER_DELETE_ALL = 'PERSONALPAPER_DELETE_ALL';
export const PERSONALPAPER_DELETE_ALL_SUCCESS = 'PERSONALPAPER_DELETE_ALL_SUCCESS';
export const PERSONALPAPER_DELETE_ALL_FAILED = 'PERSONALPAPER_DELETE_ALL_FAILED';
export const PERSONALPAPERS_EXPORT_EXCEL = 'PERSONALPAPERS_EXPORT_EXCEL';
export const PERSONALPAPERS_EXPORT_EXCEL_SUCCESS = 'PERSONALPAPERS_EXPORT_EXCEL_SUCCESS';
export const PERSONALPAPERS_EXPORT_EXCEL_FAILED = 'PERSONALPAPERS_EXPORT_EXCEL_FAILED';

// Work location const
export const WORKLOCATIONS_SELECT_ALL = 'WORKLOCATIONS_SELECT_ALL';
export const WORKLOCATIONS_UNSELECT_ALL = 'WORKLOCATIONS_UNSELECT_ALL';
export const WORKLOCATION_SELECTED = 'WORKLOCATION_SELECTED';
export const WORKLOCATION_LOAD = 'WORKLOCATION_LOAD';
export const WORKLOCATION_LOAD_SUCCESS = 'WORKLOCATION_LOAD_SUCCESS';
export const WORKLOCATION_LOAD_FAILED = 'WORKLOCATION_LOAD_FAILED';
export const WORKLOCATIONS_LOAD = 'WORKLOCATIONS_LOAD';
export const WORKLOCATIONS_LOAD_SUCCESS = 'WORKLOCATIONS_LOAD_SUCCESS';
export const WORKLOCATIONS_LOAD_FAILED = 'WORKLOCATIONS_LOAD_FAILED';
export const WORKLOCATION_ADD = 'WORKLOCATION_ADD';
export const WORKLOCATION_ADD_SUCCESS = 'WORKLOCATION_ADD_SUCCESS';
export const WORKLOCATION_ADD_FAILED = 'WORKLOCATION_ADD_FAILED';
export const WORKLOCATION_UPDATE = 'WORKLOCATION_UPDATE';
export const WORKLOCATION_UPDATE_SUCCESS = 'WORKLOCATION_UPDATE_SUCCESS';
export const WORKLOCATION_UPDATE_FAILED = 'WORKLOCATION_UPDATE_FAILED';
export const WORKLOCATION_DELETE = 'WORKLOCATION_DELETE';
export const WORKLOCATION_DELETE_SUCCESS = 'WORKLOCATION_DELETE_SUCCESS';
export const WORKLOCATION_DELETE_FAILED = 'WORKLOCATION_DELETE_FAILED';
export const WORKLOCATION_DELETE_ALL = 'WORKLOCATION_DELETE_ALL';
export const WORKLOCATION_DELETE_ALL_SUCCESS = 'WORKLOCATION_DELETE_ALL_SUCCESS';
export const WORKLOCATION_DELETE_ALL_FAILED = 'WORKLOCATION_DELETE_ALL_FAILED';
export const WORKLOCATIONS_EXPORT_EXCEL = 'WORKLOCATIONS_EXPORT_EXCEL';
export const WORKLOCATIONS_EXPORT_EXCEL_SUCCESS = 'WORKLOCATIONS_EXPORT_EXCEL_SUCCESS';
export const WORKLOCATIONS_EXPORT_EXCEL_FAILED = 'WORKLOCATIONS_EXPORT_EXCEL_FAILED';

// Leave Track const
export const LEAVETRACKS_SELECT_ALL = 'LEAVETRACKS_SELECT_ALL';
export const LEAVETRACKS_UNSELECT_ALL = 'LEAVETRACKS_UNSELECT_ALL';
export const LEAVETRACK_SELECTED = 'LEAVETRACK_SELECTED';
export const LEAVETRACK_LOAD = 'LEAVETRACK_LOAD';
export const LEAVETRACK_LOAD_SUCCESS = 'LEAVETRACK_LOAD_SUCCESS';
export const LEAVETRACK_LOAD_FAILED = 'LEAVETRACK_LOAD_FAILED';
export const LEAVETRACKS_LOAD = 'LEAVETRACKS_LOAD';
export const LEAVETRACKS_LOAD_SUCCESS = 'LEAVETRACKS_LOAD_SUCCESS';
export const LEAVETRACKS_LOAD_FAILED = 'LEAVETRACKS_LOAD_FAILED';
export const LEAVETRACK_ADD = 'LEAVETRACK_ADD';
export const LEAVETRACK_ADD_SUCCESS = 'LEAVETRACK_ADD_SUCCESS';
export const LEAVETRACK_ADD_FAILED = 'LEAVETRACK_ADD_FAILED';
export const LEAVETRACK_UPDATE = 'LEAVETRACK_UPDATE';
export const LEAVETRACK_UPDATE_SUCCESS = 'LEAVETRACK_UPDATE_SUCCESS';
export const LEAVETRACK_UPDATE_FAILED = 'LEAVETRACK_UPDATE_FAILED';
export const LEAVETRACK_DELETE = 'LEAVETRACK_DELETE';
export const LEAVETRACK_DELETE_SUCCESS = 'LEAVETRACK_DELETE_SUCCESS';
export const LEAVETRACK_DELETE_FAILED = 'LEAVETRACK_DELETE_FAILED';
export const LEAVETRACK_DELETE_ALL = 'LEAVETRACK_DELETE_ALL';
export const LEAVETRACK_DELETE_ALL_SUCCESS = 'LEAVETRACK_DELETE_ALL_SUCCESS';
export const LEAVETRACK_DELETE_ALL_FAILED = 'LEAVETRACK_DELETE_ALL_FAILED';
export const LEAVETRACKS_EXPORT_EXCEL = 'LEAVETRACKS_EXPORT_EXCEL';
export const LEAVETRACKS_EXPORT_EXCEL_SUCCESS = 'LEAVETRACKS_EXPORT_EXCEL_SUCCESS';
export const LEAVETRACKS_EXPORT_EXCEL_FAILED = 'LEAVETRACKS_EXPORT_EXCEL_FAILED';

// Leave Track History const
export const LEAVETRACKHISTORIES_SELECT_ALL = 'LEAVETRACKHISTORIES_SELECT_ALL';
export const LEAVETRACKHISTORIES_UNSELECT_ALL = 'LEAVETRACKHISTORIES_UNSELECT_ALL';
export const LEAVETRACKHISTORY_SELECTED = 'LEAVETRACKHISTORY_SELECTED';
export const LEAVETRACKHISTORY_LOAD = 'LEAVETRACKHISTORY_LOAD';
export const LEAVETRACKHISTORY_LOAD_SUCCESS = 'LEAVETRACKHISTORY_LOAD_SUCCESS';
export const LEAVETRACKHISTORY_LOAD_FAILED = 'LEAVETRACKHISTORY_LOAD_FAILED';
export const LEAVETRACKHISTORIES_LOAD = 'LEAVETRACKHISTORIES_LOAD';
export const LEAVETRACKHISTORIES_LOAD_SUCCESS = 'LEAVETRACKHISTORIES_LOAD_SUCCESS';
export const LEAVETRACKHISTORIES_LOAD_FAILED = 'LEAVETRACKHISTORIES_LOAD_FAILED';
export const LEAVETRACKHISTORY_ADD = 'LEAVETRACKHISTORY_ADD';
export const LEAVETRACKHISTORY_ADD_SUCCESS = 'LEAVETRACKHISTORY_ADD_SUCCESS';
export const LEAVETRACKHISTORY_ADD_FAILED = 'LEAVETRACKHISTORY_ADD_FAILED';
export const LEAVETRACKHISTORY_UPDATE = 'LEAVETRACKHISTORY_UPDATE';
export const LEAVETRACKHISTORY_UPDATE_SUCCESS = 'LEAVETRACKHISTORY_UPDATE_SUCCESS';
export const LEAVETRACKHISTORY_UPDATE_FAILED = 'LEAVETRACKHISTORY_UPDATE_FAILED';
export const LEAVETRACKHISTORY_DELETE = 'LEAVETRACKHISTORY_DELETE';
export const LEAVETRACKHISTORY_DELETE_SUCCESS = 'LEAVETRACKHISTORY_DELETE_SUCCESS';
export const LEAVETRACKHISTORY_DELETE_FAILED = 'LEAVETRACKHISTORY_DELETE_FAILED';
export const LEAVETRACKHISTORY_DELETE_ALL = 'LEAVETRACKHISTORY_DELETE_ALL';
export const LEAVETRACKHISTORY_DELETE_ALL_SUCCESS = 'LEAVETRACKHISTORY_DELETE_ALL_SUCCESS';
export const LEAVETRACKHISTORY_DELETE_ALL_FAILED = 'LEAVETRACKHISTORY_DELETE_ALL_FAILED';
export const LEAVETRACKHISTORIES_EXPORT_EXCEL = 'LEAVETRACKHISTORIES_EXPORT_EXCEL';
export const LEAVETRACKHISTORIES_EXPORT_EXCEL_SUCCESS = 'LEAVETRACKHISTORIES_EXPORT_EXCEL_SUCCESS';
export const LEAVETRACKHISTORIES_EXPORT_EXCEL_FAILED = 'LEAVETRACKHISTORIES_EXPORT_EXCEL_FAILED';

// Customer const
export const CUSTOMERS_SELECT_ALL = 'CUSTOMERS_SELECT_ALL';
export const CUSTOMERS_UNSELECT_ALL = 'CUSTOMERS_UNSELECT_ALL';
export const CUSTOMER_SELECTED = 'CUSTOMER_SELECTED';
export const CUSTOMER_LOAD = 'CUSTOMER_LOAD';
export const CUSTOMER_LOAD_SUCCESS = 'CUSTOMER_LOAD_SUCCESS';
export const CUSTOMER_LOAD_FAILED = 'CUSTOMER_LOAD_FAILED';
export const CUSTOMERS_LOAD = 'CUSTOMERS_LOAD';
export const CUSTOMERS_LOAD_SUCCESS = 'CUSTOMERS_LOAD_SUCCESS';
export const CUSTOMERS_LOAD_FAILED = 'CUSTOMERS_LOAD_FAILED';
export const CUSTOMER_ADD = 'CUSTOMER_ADD';
export const CUSTOMER_ADD_SUCCESS = 'CUSTOMER_ADD_SUCCESS';
export const CUSTOMER_ADD_FAILED = 'CUSTOMER_ADD_FAILED';
export const CUSTOMER_UPDATE = 'CUSTOMER_UPDATE';
export const CUSTOMER_UPDATE_SUCCESS = 'CUSTOMER_UPDATE_SUCCESS';
export const CUSTOMER_UPDATE_FAILED = 'CUSTOMER_UPDATE_FAILED';
export const CUSTOMER_DELETE = 'CUSTOMER_DELETE';
export const CUSTOMER_DELETE_SUCCESS = 'CUSTOMER_DELETE_SUCCESS';
export const CUSTOMER_DELETE_FAILED = 'CUSTOMER_DELETE_FAILED';
export const CUSTOMER_DELETE_ALL = 'CUSTOMER_DELETE_ALL';
export const CUSTOMER_DELETE_ALL_SUCCESS = 'CUSTOMER_DELETE_ALL_SUCCESS';
export const CUSTOMER_DELETE_ALL_FAILED = 'CUSTOMER_DELETE_ALL_FAILED';
export const CUSTOMERS_EXPORT_EXCEL = 'CUSTOMERS_EXPORT_EXCEL';
export const CUSTOMERS_EXPORT_EXCEL_SUCCESS = 'CUSTOMERS_EXPORT_EXCEL_SUCCESS';
export const CUSTOMERS_EXPORT_EXCEL_FAILED = 'CUSTOMERS_EXPORT_EXCEL_FAILED';
export const CUSTOMERS_REPORT = 'CUSTOMERS_REPORT';
export const CUSTOMERS_REPORT_SUCCESS = 'CUSTOMERS_REPORT_SUCCESS';
export const CUSTOMERS_REPORT_FAILED = 'CUSTOMERS_REPORT_FAILED';


// CustomerReg const
export const CUSTOMERREGS_SELECT_ALL = 'CUSTOMERREGS_SELECT_ALL';
export const CUSTOMERREGS_UNSELECT_ALL = 'CUSTOMERREGS_UNSELECT_ALL';
export const CUSTOMERREG_SELECTED = 'CUSTOMERREG_SELECTED';
export const CUSTOMERREG_LOAD = 'CUSTOMERREG_LOAD';
export const CUSTOMERREG_LOAD_SUCCESS = 'CUSTOMERREG_LOAD_SUCCESS';
export const CUSTOMERREG_LOAD_FAILED = 'CUSTOMERREG_LOAD_FAILED';
export const CUSTOMERREGS_LOAD = 'CUSTOMERREGS_LOAD';
export const CUSTOMERREGS_LOAD_SUCCESS = 'CUSTOMERREGS_LOAD_SUCCESS';
export const CUSTOMERREGS_LOAD_FAILED = 'CUSTOMERREGS_LOAD_FAILED';
export const CUSTOMERREG_ADD = 'CUSTOMERREG_ADD';
export const CUSTOMERREG_ADD_SUCCESS = 'CUSTOMERREG_ADD_SUCCESS';
export const CUSTOMERREG_ADD_FAILED = 'CUSTOMERREG_ADD_FAILED';
export const CUSTOMERREG_UPDATE = 'CUSTOMERREG_UPDATE';
export const CUSTOMERREG_UPDATE_SUCCESS = 'CUSTOMERREG_UPDATE_SUCCESS';
export const CUSTOMERREG_UPDATE_FAILED = 'CUSTOMERREG_UPDATE_FAILED';
export const CUSTOMERREG_DELETE = 'CUSTOMERREG_DELETE';
export const CUSTOMERREG_DELETE_SUCCESS = 'CUSTOMERREG_DELETE_SUCCESS';
export const CUSTOMERREG_DELETE_FAILED = 'CUSTOMERREG_DELETE_FAILED';
export const CUSTOMERREG_DELETE_ALL = 'CUSTOMERREG_DELETE_ALL';
export const CUSTOMERREG_DELETE_ALL_SUCCESS = 'CUSTOMERREG_DELETE_ALL_SUCCESS';
export const CUSTOMERREG_DELETE_ALL_FAILED = 'CUSTOMERREG_DELETE_ALL_FAILED';
export const CUSTOMERREGS_EXPORT_EXCEL = 'CUSTOMERREGS_EXPORT_EXCEL';
export const CUSTOMERREGS_EXPORT_EXCEL_SUCCESS = 'CUSTOMERREGS_EXPORT_EXCEL_SUCCESS';
export const CUSTOMERREGS_EXPORT_EXCEL_FAILED = 'CUSTOMERREGS_EXPORT_EXCEL_FAILED';
export const CUSTOMERREGS_REPORT = 'CUSTOMERREGS_REPORT';
export const CUSTOMERREGS_REPORT_SUCCESS = 'CUSTOMERREGS_REPORT_SUCCESS';
export const CUSTOMERREGS_REPORT_FAILED = 'CUSTOMERREGS_REPORT_FAILED';

// Payment const
export const PAYMENTS_SELECT_ALL = 'PAYMENTS_SELECT_ALL';
export const PAYMENTS_UNSELECT_ALL = 'PAYMENTS_UNSELECT_ALL';
export const PAYMENT_SELECTED = 'PAYMENT_SELECTED';
export const PAYMENT_LOAD = 'PAYMENT_LOAD';
export const PAYMENT_LOAD_SUCCESS = 'PAYMENT_LOAD_SUCCESS';
export const PAYMENT_LOAD_FAILED = 'PAYMENT_LOAD_FAILED';
export const PAYMENTS_LOAD = 'PAYMENTS_LOAD';
export const PAYMENTS_LOAD_SUCCESS = 'PAYMENTS_LOAD_SUCCESS';
export const PAYMENTS_LOAD_FAILED = 'PAYMENTS_LOAD_FAILED';
export const PAYMENT_ADD = 'PAYMENT_ADD';
export const PAYMENT_ADD_SUCCESS = 'PAYMENT_ADD_SUCCESS';
export const PAYMENT_ADD_FAILED = 'PAYMENT_ADD_FAILED';
export const PAYMENT_UPDATE = 'PAYMENT_UPDATE';
export const PAYMENT_UPDATE_SUCCESS = 'PAYMENT_UPDATE_SUCCESS';
export const PAYMENT_UPDATE_FAILED = 'PAYMENT_UPDATE_FAILED';
export const PAYMENT_DELETE = 'PAYMENT_DELETE';
export const PAYMENT_DELETE_SUCCESS = 'PAYMENT_DELETE_SUCCESS';
export const PAYMENT_DELETE_FAILED = 'PAYMENT_DELETE_FAILED';
export const PAYMENT_DELETE_ALL = 'PAYMENT_DELETE_ALL';
export const PAYMENT_DELETE_ALL_SUCCESS = 'PAYMENT_DELETE_ALL_SUCCESS';
export const PAYMENT_DELETE_ALL_FAILED = 'PAYMENT_DELETE_ALL_FAILED';
export const PAYMENTS_EXPORT_EXCEL = 'PAYMENTS_EXPORT_EXCEL';
export const PAYMENTS_EXPORT_EXCEL_SUCCESS = 'PAYMENTS_EXPORT_EXCEL_SUCCESS';
export const PAYMENTS_EXPORT_EXCEL_FAILED = 'PAYMENTS_EXPORT_EXCEL_FAILED';

// Contract const
export const CONTRACTS_SELECT_ALL = 'CONTRACTS_SELECT_ALL';
export const CONTRACTS_UNSELECT_ALL = 'CONTRACTS_UNSELECT_ALL';
export const CONTRACT_SELECTED = 'CONTRACT_SELECTED';
export const CONTRACT_LOAD = 'CONTRACT_LOAD';
export const CONTRACT_LOAD_SUCCESS = 'CONTRACT_LOAD_SUCCESS';
export const CONTRACT_LOAD_FAILED = 'CONTRACT_LOAD_FAILED';
export const CONTRACTS_LOAD = 'CONTRACTS_LOAD';
export const CONTRACTS_LOAD_SUCCESS = 'CONTRACTS_LOAD_SUCCESS';
export const CONTRACTS_LOAD_FAILED = 'CONTRACTS_LOAD_FAILED';
export const CONTRACT_ADD = 'CONTRACT_ADD';
export const CONTRACT_ADD_SUCCESS = 'CONTRACT_ADD_SUCCESS';
export const CONTRACT_ADD_FAILED = 'CONTRACT_ADD_FAILED';
export const CONTRACT_UPDATE = 'CONTRACT_UPDATE';
export const CONTRACT_UPDATE_SUCCESS = 'CONTRACT_UPDATE_SUCCESS';
export const CONTRACT_UPDATE_FAILED = 'CONTRACT_UPDATE_FAILED';
export const CONTRACT_DELETE = 'CONTRACT_DELETE';
export const CONTRACT_DELETE_SUCCESS = 'CONTRACT_DELETE_SUCCESS';
export const CONTRACT_DELETE_FAILED = 'CONTRACT_DELETE_FAILED';
export const CONTRACT_DELETE_ALL = 'CONTRACT_DELETE_ALL';
export const CONTRACT_DELETE_ALL_SUCCESS = 'CONTRACT_DELETE_ALL_SUCCESS';
export const CONTRACT_DELETE_ALL_FAILED = 'CONTRACT_DELETE_ALL_FAILED';
export const CONTRACTS_EXPORT_EXCEL = 'CONTRACTS_EXPORT_EXCEL';
export const CONTRACTS_EXPORT_EXCEL_SUCCESS = 'CONTRACTS_EXPORT_EXCEL_SUCCESS';
export const CONTRACTS_EXPORT_EXCEL_FAILED = 'CONTRACTS_EXPORT_EXCEL_FAILED';

// Quotation const
export const QUOTATIONS_SELECT_ALL = 'QUOTATIONS_SELECT_ALL';
export const QUOTATIONS_UNSELECT_ALL = 'QUOTATIONS_UNSELECT_ALL';
export const QUOTATION_SELECTED = 'QUOTATION_SELECTED';
export const QUOTATION_LOAD = 'QUOTATION_LOAD';
export const QUOTATION_LOAD_SUCCESS = 'QUOTATION_LOAD_SUCCESS';
export const QUOTATION_LOAD_FAILED = 'QUOTATION_LOAD_FAILED';
export const QUOTATIONS_LOAD = 'QUOTATIONS_LOAD';
export const QUOTATIONS_LOAD_SUCCESS = 'QUOTATIONS_LOAD_SUCCESS';
export const QUOTATIONS_LOAD_FAILED = 'QUOTATIONS_LOAD_FAILED';
export const QUOTATION_ADD = 'QUOTATION_ADD';
export const QUOTATION_ADD_SUCCESS = 'QUOTATION_ADD_SUCCESS';
export const QUOTATION_ADD_FAILED = 'QUOTATION_ADD_FAILED';
export const QUOTATION_UPDATE = 'QUOTATION_UPDATE';
export const QUOTATION_UPDATE_SUCCESS = 'QUOTATION_UPDATE_SUCCESS';
export const QUOTATION_UPDATE_FAILED = 'QUOTATION_UPDATE_FAILED';
export const QUOTATION_DELETE = 'QUOTATION_DELETE';
export const QUOTATION_DELETE_SUCCESS = 'QUOTATION_DELETE_SUCCESS';
export const QUOTATION_DELETE_FAILED = 'QUOTATION_DELETE_FAILED';
export const QUOTATION_DELETE_ALL = 'QUOTATION_DELETE_ALL';
export const QUOTATION_DELETE_ALL_SUCCESS = 'QUOTATION_DELETE_ALL_SUCCESS';
export const QUOTATION_DELETE_ALL_FAILED = 'QUOTATION_DELETE_ALL_FAILED';
export const QUOTATIONS_EXPORT_EXCEL = 'QUOTATIONS_EXPORT_EXCEL';
export const QUOTATIONS_EXPORT_EXCEL_SUCCESS = 'QUOTATIONS_EXPORT_EXCEL_SUCCESS';
export const QUOTATIONS_EXPORT_EXCEL_FAILED = 'QUOTATIONS_EXPORT_EXCEL_FAILED';

// Role const
export const ROLES_SELECT_ALL = 'ROLES_SELECT_ALL';
export const ROLES_UNSELECT_ALL = 'ROLES_UNSELECT_ALL';
export const ROLE_SELECTED = 'ROLE_SELECTED';
export const ROLE_LOAD = 'ROLE_LOAD';
export const ROLE_LOAD_SUCCESS = 'ROLE_LOAD_SUCCESS';
export const ROLE_LOAD_FAILED = 'ROLE_LOAD_FAILED';
export const ROLES_LOAD = 'ROLES_LOAD';
export const ROLES_LOAD_SUCCESS = 'ROLES_LOAD_SUCCESS';
export const ROLES_LOAD_FAILED = 'ROLES_LOAD_FAILED';
export const ROLE_ADD = 'ROLE_ADD';
export const ROLE_ADD_SUCCESS = 'ROLE_ADD_SUCCESS';
export const ROLE_ADD_FAILED = 'ROLE_ADD_FAILED';
export const ROLE_UPDATE = 'ROLE_UPDATE';
export const ROLE_UPDATE_SUCCESS = 'ROLE_UPDATE_SUCCESS';
export const ROLE_UPDATE_FAILED = 'ROLE_UPDATE_FAILED';
export const ROLE_DELETE = 'ROLE_DELETE';
export const ROLE_DELETE_SUCCESS = 'ROLE_DELETE_SUCCESS';
export const ROLE_DELETE_FAILED = 'ROLE_DELETE_FAILED';
export const ROLE_DELETE_ALL = 'ROLE_DELETE_ALL';
export const ROLE_DELETE_ALL_SUCCESS = 'ROLE_DELETE_ALL_SUCCESS';
export const ROLE_DELETE_ALL_FAILED = 'ROLE_DELETE_ALL_FAILED';
export const ROLES_EXPORT_EXCEL = 'ROLES_EXPORT_EXCEL';
export const ROLES_EXPORT_EXCEL_SUCCESS = 'ROLES_EXPORT_EXCEL_SUCCESS';
export const ROLES_EXPORT_EXCEL_FAILED = 'ROLES_EXPORT_EXCEL_FAILED';

// User const
export const USER_SEARCH = 'USER_SEARCH';
export const USER_SEARCH_SUCCESS = 'USER_SEARCH_SUCCESS';
export const USER_ASSIGN_ROLES = 'USER_ASSIGN_ROLES';
export const USER_ASSIGN_ROLES_SUCCESS = 'USER_ASSIGN_ROLES_SUCCESS';
export const USER_ASSIGN_EMPLOYEE = 'USER_ASSIGN_EMPLOYEE';
export const USER_ASSIGN_EMPLOYEE_SUCCESS = 'USER_ASSIGN_EMPLOYEE_SUCCESS';
export const USER_LOCK = 'USER_LOCK';
export const USER_LOCK_SUCCESS = 'USER_LOCK_SUCCESS';
export const USER_UNLOCK = 'USER_UNLOCK';
export const USER_UNLOCK_SUCCESS = 'USER_UNLOCK_SUCCESS';
export const USER_CHANGE_LOCK_RELOAD = 'USER_CHANGE_LOCK_RELOAD';

// Job const
export const JOBS_SELECT_ALL = 'JOBS_SELECT_ALL';
export const JOBS_UNSELECT_ALL = 'JOBS_UNSELECT_ALL';
export const JOB_SELECTED = 'JOB_SELECTED';
export const JOB_LOAD = 'JOB_LOAD';
export const JOB_LOAD_SUCCESS = 'JOB_LOAD_SUCCESS';
export const JOB_LOAD_FAILED = 'JOB_LOAD_FAILED';
export const JOBS_LOAD = 'JOBS_LOAD';
export const JOBS_LOAD_SUCCESS = 'JOBS_LOAD_SUCCESS';
export const JOBS_LOAD_FAILED = 'JOBS_LOAD_FAILED';
export const JOB_ADD = 'JOB_ADD';
export const JOB_ADD_SUCCESS = 'JOB_ADD_SUCCESS';
export const JOB_ADD_FAILED = 'JOB_ADD_FAILED';
export const JOB_UPDATE = 'JOB_UPDATE';
export const JOB_UPDATE_SUCCESS = 'JOB_UPDATE_SUCCESS';
export const JOB_UPDATE_FAILED = 'JOB_UPDATE_FAILED';
export const JOB_DELETE = 'JOB_DELETE';
export const JOB_DELETE_SUCCESS = 'JOB_DELETE_SUCCESS';
export const JOB_DELETE_FAILED = 'JOB_DELETE_FAILED';
export const JOB_DELETE_ALL = 'JOB_DELETE_ALL';
export const JOB_DELETE_ALL_SUCCESS = 'JOB_DELETE_ALL_SUCCESS';
export const JOB_DELETE_ALL_FAILED = 'JOB_DELETE_ALL_FAILED';
export const JOBS_EXPORT_EXCEL = 'JOBS_EXPORT_EXCEL';
export const JOBS_EXPORT_EXCEL_SUCCESS = 'JOBS_EXPORT_EXCEL_SUCCESS';
export const JOBS_EXPORT_EXCEL_FAILED = 'JOBS_EXPORT_EXCEL_FAILED';

// Mail const
export const MAILS_SELECT_ALL = 'MAILS_SELECT_ALL';
export const MAILS_UNSELECT_ALL = 'MAILS_UNSELECT_ALL';
export const MAIL_SELECTED = 'MAIL_SELECTED';
export const MAIL_LOAD = 'MAIL_LOAD';
export const MAIL_LOAD_SUCCESS = 'MAIL_LOAD_SUCCESS';
export const MAIL_LOAD_FAILED = 'MAIL_LOAD_FAILED';
export const MAILS_LOAD = 'MAILS_LOAD';
export const MAILS_LOAD_SUCCESS = 'MAILS_LOAD_SUCCESS';
export const MAILS_LOAD_FAILED = 'MAILS_LOAD_FAILED';
export const MAIL_ADD = 'MAIL_ADD';
export const MAIL_ADD_SUCCESS = 'MAIL_ADD_SUCCESS';
export const MAIL_ADD_FAILED = 'MAIL_ADD_FAILED';
export const MAIL_UPDATE = 'MAIL_UPDATE';
export const MAIL_UPDATE_SUCCESS = 'MAIL_UPDATE_SUCCESS';
export const MAIL_UPDATE_FAILED = 'MAIL_UPDATE_FAILED';
export const MAIL_DELETE = 'MAIL_DELETE';
export const MAIL_DELETE_SUCCESS = 'MAIL_DELETE_SUCCESS';
export const MAIL_DELETE_FAILED = 'MAIL_DELETE_FAILED';
export const MAIL_DELETE_ALL = 'MAIL_DELETE_ALL';
export const MAIL_DELETE_ALL_SUCCESS = 'MAIL_DELETE_ALL_SUCCESS';
export const MAIL_DELETE_ALL_FAILED = 'MAIL_DELETE_ALL_FAILED';
export const MAILS_EXPORT_EXCEL = 'MAILS_EXPORT_EXCEL';
export const MAILS_EXPORT_EXCEL_SUCCESS = 'MAILS_EXPORT_EXCEL_SUCCESS';
export const MAILS_EXPORT_EXCEL_FAILED = 'MAILS_EXPORT_EXCEL_FAILED';

// MailToUser const
export const MAILTOUSERS_SELECT_ALL = 'MAILTOUSERS_SELECT_ALL';
export const MAILTOUSERS_UNSELECT_ALL = 'MAILTOUSERS_UNSELECT_ALL';
export const MAILTOUSER_SELECTED = 'MAILTOUSER_SELECTED';
export const MAILTOUSER_LOAD = 'MAILTOUSER_LOAD';
export const MAILTOUSER_LOAD_SUCCESS = 'MAILTOUSER_LOAD_SUCCESS';
export const MAILTOUSER_LOAD_FAILED = 'MAILTOUSER_LOAD_FAILED';
export const MAILTOUSERS_LOAD = 'MAILTOUSERS_LOAD';
export const MAILTOUSERS_LOAD_SUCCESS = 'MAILTOUSERS_LOAD_SUCCESS';
export const MAILTOUSERS_LOAD_FAILED = 'MAILTOUSERS_LOAD_FAILED';
export const MAILTOUSER_ADD = 'MAILTOUSER_ADD';
export const MAILTOUSER_ADD_SUCCESS = 'MAILTOUSER_ADD_SUCCESS';
export const MAILTOUSER_ADD_FAILED = 'MAILTOUSER_ADD_FAILED';
export const MAILTOUSER_UPDATE = 'MAILTOUSER_UPDATE';
export const MAILTOUSER_UPDATE_SUCCESS = 'MAILTOUSER_UPDATE_SUCCESS';
export const MAILTOUSER_UPDATE_FAILED = 'MAILTOUSER_UPDATE_FAILED';
export const MAILTOUSER_DELETE = 'MAILTOUSER_DELETE';
export const MAILTOUSER_DELETE_SUCCESS = 'MAILTOUSER_DELETE_SUCCESS';
export const MAILTOUSER_DELETE_FAILED = 'MAILTOUSER_DELETE_FAILED';
export const MAILTOUSER_DELETE_ALL = 'MAILTOUSER_DELETE_ALL';
export const MAILTOUSER_DELETE_ALL_SUCCESS = 'MAILTOUSER_DELETE_ALL_SUCCESS';
export const MAILTOUSER_DELETE_ALL_FAILED = 'MAILTOUSER_DELETE_ALL_FAILED';
export const MAILTOUSERS_EXPORT_EXCEL = 'MAILTOUSERS_EXPORT_EXCEL';
export const MAILTOUSERS_EXPORT_EXCEL_SUCCESS = 'MAILTOUSERS_EXPORT_EXCEL_SUCCESS';
export const MAILTOUSERS_EXPORT_EXCEL_FAILED = 'MAILTOUSERS_EXPORT_EXCEL_FAILED';

// Template const
export const TEMPLATES_SELECT_ALL = 'TEMPLATES_SELECT_ALL';
export const TEMPLATES_UNSELECT_ALL = 'TEMPLATES_UNSELECT_ALL';
export const TEMPLATE_SELECTED = 'TEMPLATE_SELECTED';
export const TEMPLATE_LOAD = 'TEMPLATE_LOAD';
export const TEMPLATE_LOAD_SUCCESS = 'TEMPLATE_LOAD_SUCCESS';
export const TEMPLATE_LOAD_FAILED = 'TEMPLATE_LOAD_FAILED';
export const TEMPLATES_LOAD = 'TEMPLATES_LOAD';
export const TEMPLATES_LOAD_SUCCESS = 'TEMPLATES_LOAD_SUCCESS';
export const TEMPLATES_LOAD_FAILED = 'TEMPLATES_LOAD_FAILED';
export const TEMPLATE_ADD = 'TEMPLATE_ADD';
export const TEMPLATE_ADD_SUCCESS = 'TEMPLATE_ADD_SUCCESS';
export const TEMPLATE_ADD_FAILED = 'TEMPLATE_ADD_FAILED';
export const TEMPLATE_UPDATE = 'TEMPLATE_UPDATE';
export const TEMPLATE_UPDATE_SUCCESS = 'TEMPLATE_UPDATE_SUCCESS';
export const TEMPLATE_UPDATE_FAILED = 'TEMPLATE_UPDATE_FAILED';
export const TEMPLATE_DELETE = 'TEMPLATE_DELETE';
export const TEMPLATE_DELETE_SUCCESS = 'TEMPLATE_DELETE_SUCCESS';
export const TEMPLATE_DELETE_FAILED = 'TEMPLATE_DELETE_FAILED';
export const TEMPLATE_DELETE_ALL = 'TEMPLATE_DELETE_ALL';
export const TEMPLATE_DELETE_ALL_SUCCESS = 'TEMPLATE_DELETE_ALL_SUCCESS';
export const TEMPLATE_DELETE_ALL_FAILED = 'TEMPLATE_DELETE_ALL_FAILED';
export const TEMPLATES_EXPORT_EXCEL = 'TEMPLATES_EXPORT_EXCEL';
export const TEMPLATES_EXPORT_EXCEL_SUCCESS = 'TEMPLATES_EXPORT_EXCEL_SUCCESS';
export const TEMPLATES_EXPORT_EXCEL_FAILED = 'TEMPLATES_EXPORT_EXCEL_FAILED';

// INVOICE const
export const INVOICES_SELECT_ALL = 'INVOICES_SELECT_ALL';
export const INVOICES_UNSELECT_ALL = 'INVOICES_UNSELECT_ALL';
export const INVOICE_SELECTED = 'INVOICE_SELECTED';
export const INVOICE_LOAD = 'INVOICE_LOAD';
export const INVOICE_LOAD_SUCCESS = 'INVOICE_LOAD_SUCCESS';
export const INVOICE_LOAD_FAILED = 'INVOICE_LOAD_FAILED';
export const INVOICES_LOAD = 'INVOICES_LOAD';
export const INVOICES_LOAD_SUCCESS = 'INVOICES_LOAD_SUCCESS';
export const INVOICES_LOAD_FAILED = 'INVOICES_LOAD_FAILED';
export const INVOICE_ADD = 'INVOICE_ADD';
export const INVOICE_ADD_SUCCESS = 'INVOICE_ADD_SUCCESS';
export const INVOICE_ADD_FAILED = 'INVOICE_ADD_FAILED';
export const INVOICE_UPDATE = 'INVOICE_UPDATE';
export const INVOICE_UPDATE_SUCCESS = 'INVOICE_UPDATE_SUCCESS';
export const INVOICE_UPDATE_FAILED = 'INVOICE_UPDATE_FAILED';
export const INVOICE_DELETE = 'INVOICE_DELETE';
export const INVOICE_DELETE_SUCCESS = 'INVOICE_DELETE_SUCCESS';
export const INVOICE_DELETE_FAILED = 'INVOICE_DELETE_FAILED';
export const INVOICE_DELETE_ALL = 'INVOICE_DELETE_ALL';
export const INVOICE_DELETE_ALL_SUCCESS = 'INVOICE_DELETE_ALL_SUCCESS';
export const INVOICE_DELETE_ALL_FAILED = 'INVOICE_DELETE_ALL_FAILED';
export const INVOICES_EXPORT_EXCEL = 'INVOICES_EXPORT_EXCEL';
export const INVOICES_EXPORT_EXCEL_SUCCESS = 'INVOICES_EXPORT_EXCEL_SUCCESS';
export const INVOICES_EXPORT_EXCEL_FAILED = 'INVOICES_EXPORT_EXCEL_FAILED';
export const INVOICES_REPORT = 'INVOICES_REPORT';
export const INVOICES_REPORT_SUCCESS = 'INVOICES_REPORT_SUCCESS';
export const INVOICES_REPORT_FAILED = 'INVOICES_REPORT_FAILED';
export const INVOICES_SET_FILTER = 'INVOICES_SET_FILTER';

// Serial number const
export const SERIALNUMBERS_SELECT_ALL = 'SERIALNUMBERS_SELECT_ALL';
export const SERIALNUMBERS_UNSELECT_ALL = 'SERIALNUMBERS_UNSELECT_ALL';
export const SERIALNUMBER_SELECTED = 'SERIALNUMBER_SELECTED';
export const SERIALNUMBER_LOAD = 'SERIALNUMBER_LOAD';
export const SERIALNUMBER_LOAD_SUCCESS = 'SERIALNUMBER_LOAD_SUCCESS';
export const SERIALNUMBER_LOAD_FAILED = 'SERIALNUMBER_LOAD_FAILED';
export const SERIALNUMBERS_LOAD = 'SERIALNUMBERS_LOAD';
export const SERIALNUMBERS_LOAD_SUCCESS = 'SERIALNUMBERS_LOAD_SUCCESS';
export const SERIALNUMBERS_LOAD_FAILED = 'SERIALNUMBERS_LOAD_FAILED';
export const SERIALNUMBER_ADD = 'SERIALNUMBER_ADD';
export const SERIALNUMBER_ADD_SUCCESS = 'SERIALNUMBER_ADD_SUCCESS';
export const SERIALNUMBER_ADD_FAILED = 'SERIALNUMBER_ADD_FAILED';
export const SERIALNUMBER_UPDATE = 'SERIALNUMBER_UPDATE';
export const SERIALNUMBER_UPDATE_SUCCESS = 'SERIALNUMBER_UPDATE_SUCCESS';
export const SERIALNUMBER_UPDATE_FAILED = 'SERIALNUMBER_UPDATE_FAILED';
export const SERIALNUMBER_DELETE = 'SERIALNUMBER_DELETE';
export const SERIALNUMBER_DELETE_SUCCESS = 'SERIALNUMBER_DELETE_SUCCESS';
export const SERIALNUMBER_DELETE_FAILED = 'SERIALNUMBER_DELETE_FAILED';
export const SERIALNUMBER_DELETE_ALL = 'SERIALNUMBER_DELETE_ALL';
export const SERIALNUMBER_DELETE_ALL_SUCCESS = 'SERIALNUMBER_DELETE_ALL_SUCCESS';
export const SERIALNUMBER_DELETE_ALL_FAILED = 'SERIALNUMBER_DELETE_ALL_FAILED';
export const SERIALNUMBERS_EXPORT_EXCEL = 'SERIALNUMBERS_EXPORT_EXCEL';
export const SERIALNUMBERS_EXPORT_EXCEL_SUCCESS = 'SERIALNUMBERS_EXPORT_EXCEL_SUCCESS';
export const SERIALNUMBERS_EXPORT_EXCEL_FAILED = 'SERIALNUMBERS_EXPORT_EXCEL_FAILED';

// Album const
export const ALBUMS_SELECT_ALL = 'ALBUMS_SELECT_ALL';
export const ALBUMS_UNSELECT_ALL = 'ALBUMS_UNSELECT_ALL';
export const ALBUM_SELECTED = 'ALBUM_SELECTED';
export const ALBUM_LOAD = 'ALBUM_LOAD';
export const ALBUM_LOAD_SUCCESS = 'ALBUM_LOAD_SUCCESS';
export const ALBUM_LOAD_FAILED = 'ALBUM_LOAD_FAILED';
export const ALBUMS_LOAD = 'ALBUMS_LOAD';
export const ALBUMS_LOAD_SUCCESS = 'ALBUMS_LOAD_SUCCESS';
export const ALBUMS_LOAD_FAILED = 'ALBUMS_LOAD_FAILED';
export const ALBUM_ADD = 'ALBUM_ADD';
export const ALBUM_ADD_SUCCESS = 'ALBUM_ADD_SUCCESS';
export const ALBUM_ADD_FAILED = 'ALBUM_ADD_FAILED';
export const ALBUM_UPDATE = 'ALBUM_UPDATE';
export const ALBUM_UPDATE_SUCCESS = 'ALBUM_UPDATE_SUCCESS';
export const ALBUM_UPDATE_FAILED = 'ALBUM_UPDATE_FAILED';
export const ALBUM_DELETE = 'ALBUM_DELETE';
export const ALBUM_DELETE_SUCCESS = 'ALBUM_DELETE_SUCCESS';
export const ALBUM_DELETE_FAILED = 'ALBUM_DELETE_FAILED';
export const ALBUM_DELETE_ALL = 'ALBUM_DELETE_ALL';
export const ALBUM_DELETE_ALL_SUCCESS = 'ALBUM_DELETE_ALL_SUCCESS';
export const ALBUM_DELETE_ALL_FAILED = 'ALBUM_DELETE_ALL_FAILED';
export const ALBUMS_EXPORT_EXCEL = 'ALBUMS_EXPORT_EXCEL';
export const ALBUMS_EXPORT_EXCEL_SUCCESS = 'ALBUMS_EXPORT_EXCEL_SUCCESS';
export const ALBUMS_EXPORT_EXCEL_FAILED = 'ALBUMS_EXPORT_EXCEL_FAILED';

// Media const
export const MEDIAS_SELECT_ALL = 'MEDIAS_SELECT_ALL';
export const MEDIAS_UNSELECT_ALL = 'MEDIAS_UNSELECT_ALL';
export const MEDIA_SELECTED = 'MEDIA_SELECTED';
export const MEDIA_LOAD = 'MEDIA_LOAD';
export const MEDIA_LOAD_SUCCESS = 'MEDIA_LOAD_SUCCESS';
export const MEDIA_LOAD_FAILED = 'MEDIA_LOAD_FAILED';
export const MEDIAS_LOAD = 'MEDIAS_LOAD';
export const MEDIAS_LOAD_SUCCESS = 'MEDIAS_LOAD_SUCCESS';
export const MEDIAS_LOAD_FAILED = 'MEDIAS_LOAD_FAILED';
export const MEDIA_ADD = 'MEDIA_ADD';
export const MEDIA_ADD_SUCCESS = 'MEDIA_ADD_SUCCESS';
export const MEDIA_ADD_FAILED = 'MEDIA_ADD_FAILED';
export const MEDIA_UPDATE = 'MEDIA_UPDATE';
export const MEDIA_UPDATE_SUCCESS = 'MEDIA_UPDATE_SUCCESS';
export const MEDIA_UPDATE_FAILED = 'MEDIA_UPDATE_FAILED';
export const MEDIA_DELETE = 'MEDIA_DELETE';
export const MEDIA_DELETE_SUCCESS = 'MEDIA_DELETE_SUCCESS';
export const MEDIA_DELETE_FAILED = 'MEDIA_DELETE_FAILED';
export const MEDIA_DELETE_ALL = 'MEDIA_DELETE_ALL';
export const MEDIA_DELETE_ALL_SUCCESS = 'MEDIA_DELETE_ALL_SUCCESS';
export const MEDIA_DELETE_ALL_FAILED = 'MEDIA_DELETE_ALL_FAILED';
export const MEDIAS_EXPORT_EXCEL = 'MEDIAS_EXPORT_EXCEL';
export const MEDIAS_EXPORT_EXCEL_SUCCESS = 'MEDIAS_EXPORT_EXCEL_SUCCESS';
export const MEDIAS_EXPORT_EXCEL_FAILED = 'MEDIAS_EXPORT_EXCEL_FAILED';

// Library const
export const LIBRARIES_SELECT_ALL = 'LIBRARIES_SELECT_ALL';
export const LIBRARIES_UNSELECT_ALL = 'LIBRARIES_UNSELECT_ALL';
export const LIBRARY_SELECTED = 'LIBRARY_SELECTED';
export const LIBRARY_LOAD = 'LIBRARY_LOAD';
export const LIBRARY_LOAD_SUCCESS = 'LIBRARY_LOAD_SUCCESS';
export const LIBRARY_LOAD_FAILED = 'LIBRARY_LOAD_FAILED';
export const LIBRARIES_LOAD = 'LIBRARIES_LOAD';
export const LIBRARIES_LOAD_SUCCESS = 'LIBRARIES_LOAD_SUCCESS';
export const LIBRARIES_LOAD_FAILED = 'LIBRARIES_LOAD_FAILED';
export const LIBRARY_ADD = 'LIBRARY_ADD';
export const LIBRARY_ADD_SUCCESS = 'LIBRARY_ADD_SUCCESS';
export const LIBRARY_ADD_FAILED = 'LIBRARY_ADD_FAILED';
export const LIBRARY_UPDATE = 'LIBRARY_UPDATE';
export const LIBRARY_UPDATE_SUCCESS = 'LIBRARY_UPDATE_SUCCESS';
export const LIBRARY_UPDATE_FAILED = 'LIBRARY_UPDATE_FAILED';
export const LIBRARY_DELETE = 'LIBRARY_DELETE';
export const LIBRARY_DELETE_SUCCESS = 'LIBRARY_DELETE_SUCCESS';
export const LIBRARY_DELETE_FAILED = 'LIBRARY_DELETE_FAILED';
export const LIBRARY_DELETE_ALL = 'LIBRARY_DELETE_ALL';
export const LIBRARY_DELETE_ALL_SUCCESS = 'LIBRARY_DELETE_ALL_SUCCESS';
export const LIBRARY_DELETE_ALL_FAILED = 'LIBRARY_DELETE_ALL_FAILED';
export const LIBRARIES_EXPORT_EXCEL = 'LIBRARIES_EXPORT_EXCEL';
export const LIBRARIES_EXPORT_EXCEL_SUCCESS = 'LIBRARIES_EXPORT_EXCEL_SUCCESS';
export const LIBRARIES_EXPORT_EXCEL_FAILED = 'LIBRARIES_EXPORT_EXCEL_FAILED';

// Notification const
export const NOTIFICATIONS_SELECT_ALL = 'NOTIFICATIONS_SELECT_ALL';
export const NOTIFICATIONS_UNSELECT_ALL = 'NOTIFICATIONS_UNSELECT_ALL';
export const NOTIFICATION_SELECTED = 'NOTIFICATION_SELECTED';
export const NOTIFICATION_LOAD = 'NOTIFICATION_LOAD';
export const NOTIFICATION_LOAD_SUCCESS = 'NOTIFICATION_LOAD_SUCCESS';
export const NOTIFICATION_LOAD_FAILED = 'NOTIFICATION_LOAD_FAILED';
export const NOTIFICATIONS_LOAD = 'NOTIFICATIONS_LOAD';
export const NOTIFICATIONS_LOAD_SUCCESS = 'NOTIFICATIONS_LOAD_SUCCESS';
export const NOTIFICATIONS_LOAD_FAILED = 'NOTIFICATIONS_LOAD_FAILED';
export const NOTIFICATION_ADD = 'NOTIFICATION_ADD';
export const NOTIFICATION_ADD_SUCCESS = 'NOTIFICATION_ADD_SUCCESS';
export const NOTIFICATION_ADD_FAILED = 'NOTIFICATION_ADD_FAILED';
export const NOTIFICATION_UPDATE = 'NOTIFICATION_UPDATE';
export const NOTIFICATION_UPDATE_SUCCESS = 'NOTIFICATION_UPDATE_SUCCESS';
export const NOTIFICATION_UPDATE_FAILED = 'NOTIFICATION_UPDATE_FAILED';
export const NOTIFICATION_DELETE = 'NOTIFICATION_DELETE';
export const NOTIFICATION_DELETE_SUCCESS = 'NOTIFICATION_DELETE_SUCCESS';
export const NOTIFICATION_DELETE_FAILED = 'NOTIFICATION_DELETE_FAILED';
export const NOTIFICATION_DELETE_ALL = 'NOTIFICATION_DELETE_ALL';
export const NOTIFICATION_DELETE_ALL_SUCCESS = 'NOTIFICATION_DELETE_ALL_SUCCESS';
export const NOTIFICATION_DELETE_ALL_FAILED = 'NOTIFICATION_DELETE_ALL_FAILED';
export const NOTIFICATIONS_EXPORT_EXCEL = 'NOTIFICATIONS_EXPORT_EXCEL';
export const NOTIFICATIONS_EXPORT_EXCEL_SUCCESS = 'NOTIFICATIONS_EXPORT_EXCEL_SUCCESS';
export const NOTIFICATIONS_EXPORT_EXCEL_FAILED = 'NOTIFICATIONS_EXPORT_EXCEL_FAILED';
export const NOTIFICATIONS_COUNT = 'NOTIFICATIONS_COUNT';
export const NOTIFICATIONS_COUNT_SUCCESS = 'NOTIFICATIONS_COUNT_SUCCESS';

// Partner const
export const PARTNERS_SELECT_ALL = 'PARTNERS_SELECT_ALL';
export const PARTNERS_UNSELECT_ALL = 'PARTNERS_UNSELECT_ALL';
export const PARTNER_SELECTED = 'PARTNER_SELECTED';
export const PARTNER_LOAD = 'PARTNER_LOAD';
export const PARTNER_LOAD_SUCCESS = 'PARTNER_LOAD_SUCCESS';
export const PARTNER_LOAD_FAILED = 'PARTNER_LOAD_FAILED';
export const PARTNERS_LOAD = 'PARTNERS_LOAD';
export const PARTNERS_LOAD_SUCCESS = 'PARTNERS_LOAD_SUCCESS';
export const PARTNERS_LOAD_FAILED = 'PARTNERS_LOAD_FAILED';
export const PARTNER_ADD = 'PARTNER_ADD';
export const PARTNER_ADD_SUCCESS = 'PARTNER_ADD_SUCCESS';
export const PARTNER_ADD_FAILED = 'PARTNER_ADD_FAILED';
export const PARTNER_UPDATE = 'PARTNER_UPDATE';
export const PARTNER_UPDATE_SUCCESS = 'PARTNER_UPDATE_SUCCESS';
export const PARTNER_UPDATE_FAILED = 'PARTNER_UPDATE_FAILED';
export const PARTNER_DELETE = 'PARTNER_DELETE';
export const PARTNER_DELETE_SUCCESS = 'PARTNER_DELETE_SUCCESS';
export const PARTNER_DELETE_FAILED = 'PARTNER_DELETE_FAILED';
export const PARTNER_DELETE_ALL = 'PARTNER_DELETE_ALL';
export const PARTNER_DELETE_ALL_SUCCESS = 'PARTNER_DELETE_ALL_SUCCESS';
export const PARTNER_DELETE_ALL_FAILED = 'PARTNER_DELETE_ALL_FAILED';
export const PARTNERS_EXPORT_EXCEL = 'PARTNERS_EXPORT_EXCEL';
export const PARTNERS_EXPORT_EXCEL_SUCCESS = 'PARTNERS_EXPORT_EXCEL_SUCCESS';
export const PARTNERS_EXPORT_EXCEL_FAILED = 'PARTNERS_EXPORT_EXCEL_FAILED';

// Project const
export const PROJECTS_SELECT_ALL = 'PROJECTS_SELECT_ALL';
export const PROJECTS_UNSELECT_ALL = 'PROJECTS_UNSELECT_ALL';
export const PROJECT_SELECTED = 'PROJECT_SELECTED';
export const PROJECT_LOAD = 'PROJECT_LOAD';
export const PROJECT_LOAD_SUCCESS = 'PROJECT_LOAD_SUCCESS';
export const PROJECT_LOAD_FAILED = 'PROJECT_LOAD_FAILED';
export const PROJECTS_LOAD = 'PROJECTS_LOAD';
export const PROJECTS_LOAD_SUCCESS = 'PROJECTS_LOAD_SUCCESS';
export const PROJECTS_LOAD_FAILED = 'PROJECTS_LOAD_FAILED';
export const PROJECT_ADD = 'PROJECT_ADD';
export const PROJECT_ADD_SUCCESS = 'PROJECT_ADD_SUCCESS';
export const PROJECT_ADD_FAILED = 'PROJECT_ADD_FAILED';
export const PROJECT_UPDATE = 'PROJECT_UPDATE';
export const PROJECT_UPDATE_SUCCESS = 'PROJECT_UPDATE_SUCCESS';
export const PROJECT_UPDATE_FAILED = 'PROJECT_UPDATE_FAILED';
export const PROJECT_DELETE = 'PROJECT_DELETE';
export const PROJECT_DELETE_SUCCESS = 'PROJECT_DELETE_SUCCESS';
export const PROJECT_DELETE_FAILED = 'PROJECT_DELETE_FAILED';
export const PROJECT_DELETE_ALL = 'PROJECT_DELETE_ALL';
export const PROJECT_DELETE_ALL_SUCCESS = 'PROJECT_DELETE_ALL_SUCCESS';
export const PROJECT_DELETE_ALL_FAILED = 'PROJECT_DELETE_ALL_FAILED';
export const PROJECTS_EXPORT_EXCEL = 'PROJECTS_EXPORT_EXCEL';
export const PROJECTS_EXPORT_EXCEL_SUCCESS = 'PROJECTS_EXPORT_EXCEL_SUCCESS';
export const PROJECTS_EXPORT_EXCEL_FAILED = 'PROJECTS_EXPORT_EXCEL_FAILED';

// Document const
export const DOCUMENTS_SELECT_ALL = 'DOCUMENTS_SELECT_ALL';
export const DOCUMENTS_UNSELECT_ALL = 'DOCUMENTS_UNSELECT_ALL';
export const DOCUMENT_SELECTED = 'DOCUMENT_SELECTED';
export const DOCUMENT_LOAD = 'DOCUMENT_LOAD';
export const DOCUMENT_LOAD_SUCCESS = 'DOCUMENT_LOAD_SUCCESS';
export const DOCUMENT_LOAD_FAILED = 'DOCUMENT_LOAD_FAILED';
export const DOCUMENTS_LOAD = 'DOCUMENTS_LOAD';
export const DOCUMENTS_LOAD_SUCCESS = 'DOCUMENTS_LOAD_SUCCESS';
export const DOCUMENTS_LOAD_FAILED = 'DOCUMENTS_LOAD_FAILED';
export const DOCUMENT_ADD = 'DOCUMENT_ADD';
export const DOCUMENT_ADD_SUCCESS = 'DOCUMENT_ADD_SUCCESS';
export const DOCUMENT_ADD_FAILED = 'DOCUMENT_ADD_FAILED';
export const DOCUMENT_UPDATE = 'DOCUMENT_UPDATE';
export const DOCUMENT_UPDATE_SUCCESS = 'DOCUMENT_UPDATE_SUCCESS';
export const DOCUMENT_UPDATE_FAILED = 'DOCUMENT_UPDATE_FAILED';
export const DOCUMENT_DELETE = 'DOCUMENT_DELETE';
export const DOCUMENT_DELETE_SUCCESS = 'DOCUMENT_DELETE_SUCCESS';
export const DOCUMENT_DELETE_FAILED = 'DOCUMENT_DELETE_FAILED';
export const DOCUMENT_DELETE_ALL = 'DOCUMENT_DELETE_ALL';
export const DOCUMENT_DELETE_ALL_SUCCESS = 'DOCUMENT_DELETE_ALL_SUCCESS';
export const DOCUMENT_DELETE_ALL_FAILED = 'DOCUMENT_DELETE_ALL_FAILED';
export const DOCUMENTS_EXPORT_EXCEL = 'DOCUMENTS_EXPORT_EXCEL';
export const DOCUMENTS_EXPORT_EXCEL_SUCCESS = 'DOCUMENTS_EXPORT_EXCEL_SUCCESS';
export const DOCUMENTS_EXPORT_EXCEL_FAILED = 'DOCUMENTS_EXPORT_EXCEL_FAILED';

// DocumentCategory const
export const DOCUMENTCATEGORIES_SELECT_ALL = 'DOCUMENTCATEGORIES_SELECT_ALL';
export const DOCUMENTCATEGORIES_UNSELECT_ALL = 'DOCUMENTCATEGORIES_UNSELECT_ALL';
export const DOCUMENTCATEGORY_SELECTED = 'DOCUMENTCATEGORY_SELECTED';
export const DOCUMENTCATEGORY_LOAD = 'DOCUMENTCATEGORY_LOAD';
export const DOCUMENTCATEGORY_LOAD_SUCCESS = 'DOCUMENTCATEGORY_LOAD_SUCCESS';
export const DOCUMENTCATEGORY_LOAD_FAILED = 'DOCUMENTCATEGORY_LOAD_FAILED';
export const DOCUMENTCATEGORIES_LOAD = 'DOCUMENTCATEGORIES_LOAD';
export const DOCUMENTCATEGORIES_LOAD_SUCCESS = 'DOCUMENTCATEGORIES_LOAD_SUCCESS';
export const DOCUMENTCATEGORIES_LOAD_FAILED = 'DOCUMENTCATEGORIES_LOAD_FAILED';
export const DOCUMENTCATEGORY_ADD = 'DOCUMENTCATEGORY_ADD';
export const DOCUMENTCATEGORY_ADD_SUCCESS = 'DOCUMENTCATEGORY_ADD_SUCCESS';
export const DOCUMENTCATEGORY_ADD_FAILED = 'DOCUMENTCATEGORY_ADD_FAILED';
export const DOCUMENTCATEGORY_UPDATE = 'DOCUMENTCATEGORY_UPDATE';
export const DOCUMENTCATEGORY_UPDATE_SUCCESS = 'DOCUMENTCATEGORY_UPDATE_SUCCESS';
export const DOCUMENTCATEGORY_UPDATE_FAILED = 'DOCUMENTCATEGORY_UPDATE_FAILED';
export const DOCUMENTCATEGORY_DELETE = 'DOCUMENTCATEGORY_DELETE';
export const DOCUMENTCATEGORY_DELETE_SUCCESS = 'DOCUMENTCATEGORY_DELETE_SUCCESS';
export const DOCUMENTCATEGORY_DELETE_FAILED = 'DOCUMENTCATEGORY_DELETE_FAILED';
export const DOCUMENTCATEGORY_DELETE_ALL = 'DOCUMENTCATEGORY_DELETE_ALL';
export const DOCUMENTCATEGORY_DELETE_ALL_SUCCESS = 'DOCUMENTCATEGORY_DELETE_ALL_SUCCESS';
export const DOCUMENTCATEGORY_DELETE_ALL_FAILED = 'DOCUMENTCATEGORY_DELETE_ALL_FAILED';
export const DOCUMENTCATEGORIES_EXPORT_EXCEL = 'DOCUMENTCATEGORIES_EXPORT_EXCEL';
export const DOCUMENTCATEGORIES_EXPORT_EXCEL_SUCCESS = 'DOCUMENTCATEGORIES_EXPORT_EXCEL_SUCCESS';
export const DOCUMENTCATEGORIES_EXPORT_EXCEL_FAILED = 'DOCUMENTCATEGORIES_EXPORT_EXCEL_FAILED';

// Personal mail const
export const PERSONALMAILS_SELECT_ALL = 'PERSONALMAILS_SELECT_ALL';
export const PERSONALMAILS_UNSELECT_ALL = 'PERSONALMAILS_UNSELECT_ALL';
export const PERSONALMAIL_SELECTED = 'PERSONALMAIL_SELECTED';
export const PERSONALMAIL_LOAD = 'PERSONALMAIL_LOAD';
export const PERSONALMAIL_LOAD_SUCCESS = 'PERSONALMAIL_LOAD_SUCCESS';
export const PERSONALMAIL_LOAD_FAILED = 'PERSONALMAIL_LOAD_FAILED';
export const PERSONALMAILS_LOAD = 'PERSONALMAILS_LOAD';
export const PERSONALMAILS_LOAD_SUCCESS = 'PERSONALMAILS_LOAD_SUCCESS';
export const PERSONALMAILS_LOAD_FAILED = 'PERSONALMAILS_LOAD_FAILED';
export const PERSONALMAIL_ADD = 'PERSONALMAIL_ADD';
export const PERSONALMAIL_ADD_SUCCESS = 'PERSONALMAIL_ADD_SUCCESS';
export const PERSONALMAIL_ADD_FAILED = 'PERSONALMAIL_ADD_FAILED';
export const PERSONALMAIL_UPDATE = 'PERSONALMAIL_UPDATE';
export const PERSONALMAIL_UPDATE_SUCCESS = 'PERSONALMAIL_UPDATE_SUCCESS';
export const PERSONALMAIL_UPDATE_FAILED = 'PERSONALMAIL_UPDATE_FAILED';
export const PERSONALMAIL_DELETE = 'PERSONALMAIL_DELETE';
export const PERSONALMAIL_DELETE_SUCCESS = 'PERSONALMAIL_DELETE_SUCCESS';
export const PERSONALMAIL_DELETE_FAILED = 'PERSONALMAIL_DELETE_FAILED';
export const PERSONALMAIL_DELETE_ALL = 'PERSONALMAIL_DELETE_ALL';
export const PERSONALMAIL_DELETE_ALL_SUCCESS = 'PERSONALMAIL_DELETE_ALL_SUCCESS';
export const PERSONALMAIL_DELETE_ALL_FAILED = 'PERSONALMAIL_DELETE_ALL_FAILED';
export const PERSONALMAILS_EXPORT_EXCEL = 'PERSONALMAILS_EXPORT_EXCEL';
export const PERSONALMAILS_EXPORT_EXCEL_SUCCESS = 'PERSONALMAILS_EXPORT_EXCEL_SUCCESS';
export const PERSONALMAILS_EXPORT_EXCEL_FAILED = 'PERSONALMAILS_EXPORT_EXCEL_FAILED';

// SalarySetting const
export const SALARYSETTINGS_SELECT_ALL = 'SALARYSETTINGS_SELECT_ALL';
export const SALARYSETTINGS_UNSELECT_ALL = 'SALARYSETTINGS_UNSELECT_ALL';
export const SALARYSETTING_SELECTED = 'SALARYSETTING_SELECTED';
export const SALARYSETTING_LOAD = 'SALARYSETTING_LOAD';
export const SALARYSETTING_LOAD_SUCCESS = 'SALARYSETTING_LOAD_SUCCESS';
export const SALARYSETTING_LOAD_FAILED = 'SALARYSETTING_LOAD_FAILED';
export const SALARYSETTINGS_LOAD = 'SALARYSETTINGS_LOAD';
export const SALARYSETTINGS_LOAD_SUCCESS = 'SALARYSETTINGS_LOAD_SUCCESS';
export const SALARYSETTINGS_LOAD_FAILED = 'SALARYSETTINGS_LOAD_FAILED';
export const SALARYSETTING_ADD = 'SALARYSETTING_ADD';
export const SALARYSETTING_ADD_SUCCESS = 'SALARYSETTING_ADD_SUCCESS';
export const SALARYSETTING_ADD_FAILED = 'SALARYSETTING_ADD_FAILED';
export const SALARYSETTING_UPDATE = 'SALARYSETTING_UPDATE';
export const SALARYSETTING_UPDATE_SUCCESS = 'SALARYSETTING_UPDATE_SUCCESS';
export const SALARYSETTING_UPDATE_FAILED = 'SALARYSETTING_UPDATE_FAILED';
export const SALARYSETTING_DELETE = 'SALARYSETTING_DELETE';
export const SALARYSETTING_DELETE_SUCCESS = 'SALARYSETTING_DELETE_SUCCESS';
export const SALARYSETTING_DELETE_FAILED = 'SALARYSETTING_DELETE_FAILED';
export const SALARYSETTING_DELETE_ALL = 'SALARYSETTING_DELETE_ALL';
export const SALARYSETTING_DELETE_ALL_SUCCESS = 'SALARYSETTING_DELETE_ALL_SUCCESS';
export const SALARYSETTING_DELETE_ALL_FAILED = 'SALARYSETTING_DELETE_ALL_FAILED';
export const SALARYSETTINGS_EXPORT_EXCEL = 'SALARYSETTINGS_EXPORT_EXCEL';
export const SALARYSETTINGS_EXPORT_EXCEL_SUCCESS = 'SALARYSETTINGS_EXPORT_EXCEL_SUCCESS';
export const SALARYSETTINGS_EXPORT_EXCEL_FAILED = 'SALARYSETTINGS_EXPORT_EXCEL_FAILED';

// SalaryHistory const
export const SALARYHISTORIES_SELECT_ALL = 'SALARYHISTORIES_SELECT_ALL';
export const SALARYHISTORIES_UNSELECT_ALL = 'SALARYHISTORIES_UNSELECT_ALL';
export const SALARYHISTORY_SELECTED = 'SALARYHISTORY_SELECTED';
export const SALARYHISTORY_LOAD = 'SALARYHISTORY_LOAD';
export const SALARYHISTORY_LOAD_SUCCESS = 'SALARYHISTORY_LOAD_SUCCESS';
export const SALARYHISTORY_LOAD_FAILED = 'SALARYHISTORY_LOAD_FAILED';
export const SALARYHISTORIES_LOAD = 'SALARYHISTORIES_LOAD';
export const SALARYHISTORIES_LOAD_SUCCESS = 'SALARYHISTORIES_LOAD_SUCCESS';
export const SALARYHISTORIES_LOAD_FAILED = 'SALARYHISTORIES_LOAD_FAILED';
export const SALARYHISTORY_ADD = 'SALARYHISTORY_ADD';
export const SALARYHISTORY_ADD_SUCCESS = 'SALARYHISTORY_ADD_SUCCESS';
export const SALARYHISTORY_ADD_FAILED = 'SALARYHISTORY_ADD_FAILED';
export const SALARYHISTORY_UPDATE = 'SALARYHISTORY_UPDATE';
export const SALARYHISTORY_UPDATE_SUCCESS = 'SALARYHISTORY_UPDATE_SUCCESS';
export const SALARYHISTORY_UPDATE_FAILED = 'SALARYHISTORY_UPDATE_FAILED';
export const SALARYHISTORY_DELETE = 'SALARYHISTORY_DELETE';
export const SALARYHISTORY_DELETE_SUCCESS = 'SALARYHISTORY_DELETE_SUCCESS';
export const SALARYHISTORY_DELETE_FAILED = 'SALARYHISTORY_DELETE_FAILED';
export const SALARYHISTORY_DELETE_ALL = 'SALARYHISTORY_DELETE_ALL';
export const SALARYHISTORY_DELETE_ALL_SUCCESS = 'SALARYHISTORY_DELETE_ALL_SUCCESS';
export const SALARYHISTORY_DELETE_ALL_FAILED = 'SALARYHISTORY_DELETE_ALL_FAILED';
export const SALARYHISTORIES_EXPORT_EXCEL = 'SALARYHISTORIES_EXPORT_EXCEL';
export const SALARYHISTORIES_EXPORT_EXCEL_SUCCESS = 'SALARYHISTORIES_EXPORT_EXCEL_SUCCESS';
export const SALARYHISTORIES_EXPORT_EXCEL_FAILED = 'SALARYHISTORIES_EXPORT_EXCEL_FAILED';

// Approval const
export const APPROVALS_SELECT_ALL = 'APPROVALS_SELECT_ALL';
export const APPROVALS_UNSELECT_ALL = 'APPROVALS_UNSELECT_ALL';
export const APPROVAL_SELECTED = 'APPROVAL_SELECTED';
export const APPROVAL_LOAD = 'APPROVAL_LOAD';
export const APPROVAL_LOAD_SUCCESS = 'APPROVAL_LOAD_SUCCESS';
export const APPROVAL_LOAD_FAILED = 'APPROVAL_LOAD_FAILED';
export const APPROVALS_LOAD = 'APPROVALS_LOAD';
export const APPROVALS_LOAD_SUCCESS = 'APPROVALS_LOAD_SUCCESS';
export const APPROVALS_LOAD_FAILED = 'APPROVALS_LOAD_FAILED';
export const APPROVAL_ADD = 'APPROVAL_ADD';
export const APPROVAL_ADD_SUCCESS = 'APPROVAL_ADD_SUCCESS';
export const APPROVAL_ADD_FAILED = 'APPROVAL_ADD_FAILED';
export const APPROVAL_UPDATE = 'APPROVAL_UPDATE';
export const APPROVAL_UPDATE_SUCCESS = 'APPROVAL_UPDATE_SUCCESS';
export const APPROVAL_UPDATE_FAILED = 'APPROVAL_UPDATE_FAILED';
export const APPROVAL_DELETE = 'APPROVAL_DELETE';
export const APPROVAL_DELETE_SUCCESS = 'APPROVAL_DELETE_SUCCESS';
export const APPROVAL_DELETE_FAILED = 'APPROVAL_DELETE_FAILED';
export const APPROVAL_DELETE_ALL = 'APPROVAL_DELETE_ALL';
export const APPROVAL_DELETE_ALL_SUCCESS = 'APPROVAL_DELETE_ALL_SUCCESS';
export const APPROVAL_DELETE_ALL_FAILED = 'APPROVAL_DELETE_ALL_FAILED';
export const APPROVALS_EXPORT_EXCEL = 'APPROVALS_EXPORT_EXCEL';
export const APPROVALS_EXPORT_EXCEL_SUCCESS = 'APPROVALS_EXPORT_EXCEL_SUCCESS';
export const APPROVALS_EXPORT_EXCEL_FAILED = 'APPROVALS_EXPORT_EXCEL_FAILED';

// AdvanceSalary const
export const ADVANCESALARIES_SELECT_ALL = 'ADVANCESALARIES_SELECT_ALL';
export const ADVANCESALARIES_UNSELECT_ALL = 'ADVANCESALARIES_UNSELECT_ALL';
export const ADVANCESALARY_SELECTED = 'ADVANCESALARY_SELECTED';
export const ADVANCESALARY_LOAD = 'ADVANCESALARY_LOAD';
export const ADVANCESALARY_LOAD_SUCCESS = 'ADVANCESALARY_LOAD_SUCCESS';
export const ADVANCESALARY_LOAD_FAILED = 'ADVANCESALARY_LOAD_FAILED';
export const ADVANCESALARIES_LOAD = 'ADVANCESALARIES_LOAD';
export const ADVANCESALARIES_LOAD_SUCCESS = 'ADVANCESALARIES_LOAD_SUCCESS';
export const ADVANCESALARIES_LOAD_FAILED = 'ADVANCESALARIES_LOAD_FAILED';
export const ADVANCESALARY_ADD = 'ADVANCESALARY_ADD';
export const ADVANCESALARY_ADD_SUCCESS = 'ADVANCESALARY_ADD_SUCCESS';
export const ADVANCESALARY_ADD_FAILED = 'ADVANCESALARY_ADD_FAILED';
export const ADVANCESALARY_UPDATE = 'ADVANCESALARY_UPDATE';
export const ADVANCESALARY_UPDATE_SUCCESS = 'ADVANCESALARY_UPDATE_SUCCESS';
export const ADVANCESALARY_UPDATE_FAILED = 'ADVANCESALARY_UPDATE_FAILED';
export const ADVANCESALARY_DELETE = 'ADVANCESALARY_DELETE';
export const ADVANCESALARY_DELETE_SUCCESS = 'ADVANCESALARY_DELETE_SUCCESS';
export const ADVANCESALARY_DELETE_FAILED = 'ADVANCESALARY_DELETE_FAILED';
export const ADVANCESALARY_DELETE_ALL = 'ADVANCESALARY_DELETE_ALL';
export const ADVANCESALARY_DELETE_ALL_SUCCESS = 'ADVANCESALARY_DELETE_ALL_SUCCESS';
export const ADVANCESALARY_DELETE_ALL_FAILED = 'ADVANCESALARY_DELETE_ALL_FAILED';
export const ADVANCESALARIES_EXPORT_EXCEL = 'ADVANCESALARIES_EXPORT_EXCEL';
export const ADVANCESALARIES_EXPORT_EXCEL_SUCCESS = 'ADVANCESALARIES_EXPORT_EXCEL_SUCCESS';
export const ADVANCESALARIES_EXPORT_EXCEL_FAILED = 'ADVANCESALARIES_EXPORT_EXCEL_FAILED';

// Ledgers const
export const LEDGERS_SELECT_ALL = 'LEDGERS_SELECT_ALL';
export const LEDGERS_UNSELECT_ALL = 'LEDGERS_UNSELECT_ALL';
export const LEDGER_SELECTED = 'LEDGER_SELECTED';
export const LEDGER_LOAD = 'LEDGER_LOAD';
export const LEDGER_LOAD_SUCCESS = 'LEDGER_LOAD_SUCCESS';
export const LEDGER_LOAD_FAILED = 'LEDGER_LOAD_FAILED';
export const LEDGERS_LOAD = 'LEDGERS_LOAD';
export const LEDGERS_LOAD_SUCCESS = 'LEDGERS_LOAD_SUCCESS';
export const LEDGERS_LOAD_FAILED = 'LEDGERS_LOAD_FAILED';
export const LEDGER_ADD = 'LEDGER_ADD';
export const LEDGER_ADD_SUCCESS = 'LEDGER_ADD_SUCCESS';
export const LEDGER_ADD_FAILED = 'LEDGER_ADD_FAILED';
export const LEDGER_UPDATE = 'LEDGER_UPDATE';
export const LEDGER_UPDATE_SUCCESS = 'LEDGER_UPDATE_SUCCESS';
export const LEDGER_UPDATE_FAILED = 'LEDGER_UPDATE_FAILED';
export const LEDGER_DELETE = 'LEDGER_DELETE';
export const LEDGER_DELETE_SUCCESS = 'LEDGER_DELETE_SUCCESS';
export const LEDGER_DELETE_FAILED = 'LEDGER_DELETE_FAILED';
export const LEDGER_DELETE_ALL = 'LEDGER_DELETE_ALL';
export const LEDGER_DELETE_ALL_SUCCESS = 'LEDGER_DELETE_ALL_SUCCESS';
export const LEDGER_DELETE_ALL_FAILED = 'LEDGER_DELETE_ALL_FAILED';
export const LEDGERS_EXPORT_EXCEL = 'LEDGERS_EXPORT_EXCEL';
export const LEDGERS_EXPORT_EXCEL_SUCCESS = 'LEDGERS_EXPORT_EXCEL_SUCCESS';
export const LEDGERS_EXPORT_EXCEL_FAILED = 'LEDGERS_EXPORT_EXCEL_FAILED';
export const LEDGERS_REPORT = 'LEDGERS_REPORT';
export const LEDGERS_REPORT_SUCCESS = 'LEDGERS_REPORT_SUCCESS';
export const LEDGERS_REPORT_FAILED = 'LEDGERS_REPORT_FAILED';
export const LEDGERS_REPORTSUM = 'LEDGERS_REPORTSUM';
export const LEDGERS_REPORTSUM_SUCCESS = 'LEDGERS_REPORTSUM_SUCCESS';
export const LEDGERS_REPORTSUM_FAILED = 'LEDGERS_REPORTSUM_FAILED';

// PaymentVoucher const
export const PAYMENTVOUCHERS_SELECT_ALL = 'PAYMENTVOUCHERS_SELECT_ALL';
export const PAYMENTVOUCHERS_UNSELECT_ALL = 'PAYMENTVOUCHERS_UNSELECT_ALL';
export const PAYMENTVOUCHER_SELECTED = 'PAYMENTVOUCHER_SELECTED';
export const PAYMENTVOUCHER_LOAD = 'PAYMENTVOUCHER_LOAD';
export const PAYMENTVOUCHER_LOAD_SUCCESS = 'PAYMENTVOUCHER_LOAD_SUCCESS';
export const PAYMENTVOUCHER_LOAD_FAILED = 'PAYMENTVOUCHER_LOAD_FAILED';
export const PAYMENTVOUCHERS_LOAD = 'PAYMENTVOUCHERS_LOAD';
export const PAYMENTVOUCHERS_LOAD_SUCCESS = 'PAYMENTVOUCHERS_LOAD_SUCCESS';
export const PAYMENTVOUCHERS_LOAD_FAILED = 'PAYMENTVOUCHERS_LOAD_FAILED';
export const PAYMENTVOUCHER_ADD = 'PAYMENTVOUCHER_ADD';
export const PAYMENTVOUCHER_ADD_SUCCESS = 'PAYMENTVOUCHER_ADD_SUCCESS';
export const PAYMENTVOUCHER_ADD_FAILED = 'PAYMENTVOUCHER_ADD_FAILED';
export const PAYMENTVOUCHER_UPDATE = 'PAYMENTVOUCHER_UPDATE';
export const PAYMENTVOUCHER_UPDATE_SUCCESS = 'PAYMENTVOUCHER_UPDATE_SUCCESS';
export const PAYMENTVOUCHER_UPDATE_FAILED = 'PAYMENTVOUCHER_UPDATE_FAILED';
export const PAYMENTVOUCHER_DELETE = 'PAYMENTVOUCHER_DELETE';
export const PAYMENTVOUCHER_DELETE_SUCCESS = 'PAYMENTVOUCHER_DELETE_SUCCESS';
export const PAYMENTVOUCHER_DELETE_FAILED = 'PAYMENTVOUCHER_DELETE_FAILED';
export const PAYMENTVOUCHER_DELETE_ALL = 'PAYMENTVOUCHER_DELETE_ALL';
export const PAYMENTVOUCHER_DELETE_ALL_SUCCESS = 'PAYMENTVOUCHER_DELETE_ALL_SUCCESS';
export const PAYMENTVOUCHER_DELETE_ALL_FAILED = 'PAYMENTVOUCHER_DELETE_ALL_FAILED';
export const PAYMENTVOUCHERS_EXPORT_EXCEL = 'PAYMENTVOUCHERS_EXPORT_EXCEL';
export const PAYMENTVOUCHERS_EXPORT_EXCEL_SUCCESS = 'PAYMENTVOUCHERS_EXPORT_EXCEL_SUCCESS';
export const PAYMENTVOUCHERS_EXPORT_EXCEL_FAILED = 'PAYMENTVOUCHERS_EXPORT_EXCEL_FAILED';
export const PAYMENTVOUCHERS_REPORT = 'PAYMENTVOUCHERS_REPORT';
export const PAYMENTVOUCHERS_REPORT_SUCCESS = 'PAYMENTVOUCHERS_REPORT_SUCCESS';
export const PAYMENTVOUCHERS_REPORT_FAILED = 'PAYMENTVOUCHERS_REPORT_FAILED';

// ReceiptVoucher const
export const RECEIPTVOUCHERS_SELECT_ALL = 'RECEIPTVOUCHERS_SELECT_ALL';
export const RECEIPTVOUCHERS_UNSELECT_ALL = 'RECEIPTVOUCHERS_UNSELECT_ALL';
export const RECEIPTVOUCHER_SELECTED = 'RECEIPTVOUCHER_SELECTED';
export const RECEIPTVOUCHER_LOAD = 'RECEIPTVOUCHER_LOAD';
export const RECEIPTVOUCHER_LOAD_SUCCESS = 'RECEIPTVOUCHER_LOAD_SUCCESS';
export const RECEIPTVOUCHER_LOAD_FAILED = 'RECEIPTVOUCHER_LOAD_FAILED';
export const RECEIPTVOUCHERS_LOAD = 'RECEIPTVOUCHERS_LOAD';
export const RECEIPTVOUCHERS_LOAD_SUCCESS = 'RECEIPTVOUCHERS_LOAD_SUCCESS';
export const RECEIPTVOUCHERS_LOAD_FAILED = 'RECEIPTVOUCHERS_LOAD_FAILED';
export const RECEIPTVOUCHER_ADD = 'RECEIPTVOUCHER_ADD';
export const RECEIPTVOUCHER_ADD_SUCCESS = 'RECEIPTVOUCHER_ADD_SUCCESS';
export const RECEIPTVOUCHER_ADD_FAILED = 'RECEIPTVOUCHER_ADD_FAILED';
export const RECEIPTVOUCHER_UPDATE = 'RECEIPTVOUCHER_UPDATE';
export const RECEIPTVOUCHER_UPDATE_SUCCESS = 'RECEIPTVOUCHER_UPDATE_SUCCESS';
export const RECEIPTVOUCHER_UPDATE_FAILED = 'RECEIPTVOUCHER_UPDATE_FAILED';
export const RECEIPTVOUCHER_DELETE = 'RECEIPTVOUCHER_DELETE';
export const RECEIPTVOUCHER_DELETE_SUCCESS = 'RECEIPTVOUCHER_DELETE_SUCCESS';
export const RECEIPTVOUCHER_DELETE_FAILED = 'RECEIPTVOUCHER_DELETE_FAILED';
export const RECEIPTVOUCHER_DELETE_ALL = 'RECEIPTVOUCHER_DELETE_ALL';
export const RECEIPTVOUCHER_DELETE_ALL_SUCCESS = 'RECEIPTVOUCHER_DELETE_ALL_SUCCESS';
export const RECEIPTVOUCHER_DELETE_ALL_FAILED = 'RECEIPTVOUCHER_DELETE_ALL_FAILED';
export const RECEIPTVOUCHERS_EXPORT_EXCEL = 'RECEIPTVOUCHERS_EXPORT_EXCEL';
export const RECEIPTVOUCHERS_EXPORT_EXCEL_SUCCESS = 'RECEIPTVOUCHERS_EXPORT_EXCEL_SUCCESS';
export const RECEIPTVOUCHERS_EXPORT_EXCEL_FAILED = 'RECEIPTVOUCHERS_EXPORT_EXCEL_FAILED';
export const RECEIPTVOUCHERS_REPORT = 'RECEIPTVOUCHERS_REPORT';
export const RECEIPTVOUCHERS_REPORT_SUCCESS = 'RECEIPTVOUCHERS_REPORT_SUCCESS';
export const RECEIPTVOUCHERS_REPORT_FAILED = 'RECEIPTVOUCHERS_REPORT_FAILED';

// Product const
export const PRODUCTS_SELECT_ALL = 'PRODUCTS_SELECT_ALL';
export const PRODUCTS_UNSELECT_ALL = 'PRODUCTS_UNSELECT_ALL';
export const PRODUCT_SELECTED = 'PRODUCT_SELECTED';
export const PRODUCT_LOAD = 'PRODUCT_LOAD';
export const PRODUCT_LOAD_SUCCESS = 'PRODUCT_LOAD_SUCCESS';
export const PRODUCT_LOAD_FAILED = 'PRODUCT_LOAD_FAILED';
export const PRODUCTS_LOAD = 'PRODUCTS_LOAD';
export const PRODUCTS_LOAD_SUCCESS = 'PRODUCTS_LOAD_SUCCESS';
export const PRODUCTS_LOAD_FAILED = 'PRODUCTS_LOAD_FAILED';
export const PRODUCT_ADD = 'PRODUCT_ADD';
export const PRODUCT_ADD_SUCCESS = 'PRODUCT_ADD_SUCCESS';
export const PRODUCT_ADD_FAILED = 'PRODUCT_ADD_FAILED';
export const PRODUCT_UPDATE = 'PRODUCT_UPDATE';
export const PRODUCT_UPDATE_SUCCESS = 'PRODUCT_UPDATE_SUCCESS';
export const PRODUCT_UPDATE_FAILED = 'PRODUCT_UPDATE_FAILED';
export const PRODUCT_DELETE = 'PRODUCT_DELETE';
export const PRODUCT_DELETE_SUCCESS = 'PRODUCT_DELETE_SUCCESS';
export const PRODUCT_DELETE_FAILED = 'PRODUCT_DELETE_FAILED';
export const PRODUCT_DELETE_ALL = 'PRODUCT_DELETE_ALL';
export const PRODUCT_DELETE_ALL_SUCCESS = 'PRODUCT_DELETE_ALL_SUCCESS';
export const PRODUCT_DELETE_ALL_FAILED = 'PRODUCT_DELETE_ALL_FAILED';
export const PRODUCTS_EXPORT_EXCEL = 'PRODUCTS_EXPORT_EXCEL';
export const PRODUCTS_EXPORT_EXCEL_SUCCESS = 'PRODUCTS_EXPORT_EXCEL_SUCCESS';
export const PRODUCTS_EXPORT_EXCEL_FAILED = 'PRODUCTS_EXPORT_EXCEL_FAILED';

// ProductImportVoucher const
export const PRODUCTIMPORTVOUCHERS_SELECT_ALL = 'PRODUCTIMPORTVOUCHERS_SELECT_ALL';
export const PRODUCTIMPORTVOUCHERS_UNSELECT_ALL = 'PRODUCTIMPORTVOUCHERS_UNSELECT_ALL';
export const PRODUCTIMPORTVOUCHER_SELECTED = 'PRODUCTIMPORTVOUCHER_SELECTED';
export const PRODUCTIMPORTVOUCHER_LOAD = 'PRODUCTIMPORTVOUCHER_LOAD';
export const PRODUCTIMPORTVOUCHER_LOAD_SUCCESS = 'PRODUCTIMPORTVOUCHER_LOAD_SUCCESS';
export const PRODUCTIMPORTVOUCHER_LOAD_FAILED = 'PRODUCTIMPORTVOUCHER_LOAD_FAILED';
export const PRODUCTIMPORTVOUCHERS_LOAD = 'PRODUCTIMPORTVOUCHERS_LOAD';
export const PRODUCTIMPORTVOUCHERS_LOAD_SUCCESS = 'PRODUCTIMPORTVOUCHERS_LOAD_SUCCESS';
export const PRODUCTIMPORTVOUCHERS_LOAD_FAILED = 'PRODUCTIMPORTVOUCHERS_LOAD_FAILED';
export const PRODUCTIMPORTVOUCHER_ADD = 'PRODUCTIMPORTVOUCHER_ADD';
export const PRODUCTIMPORTVOUCHER_ADD_SUCCESS = 'PRODUCTIMPORTVOUCHER_ADD_SUCCESS';
export const PRODUCTIMPORTVOUCHER_ADD_FAILED = 'PRODUCTIMPORTVOUCHER_ADD_FAILED';
export const PRODUCTIMPORTVOUCHER_UPDATE = 'PRODUCTIMPORTVOUCHER_UPDATE';
export const PRODUCTIMPORTVOUCHER_UPDATE_SUCCESS = 'PRODUCTIMPORTVOUCHER_UPDATE_SUCCESS';
export const PRODUCTIMPORTVOUCHER_UPDATE_FAILED = 'PRODUCTIMPORTVOUCHER_UPDATE_FAILED';
export const PRODUCTIMPORTVOUCHER_DELETE = 'PRODUCTIMPORTVOUCHER_DELETE';
export const PRODUCTIMPORTVOUCHER_DELETE_SUCCESS = 'PRODUCTIMPORTVOUCHER_DELETE_SUCCESS';
export const PRODUCTIMPORTVOUCHER_DELETE_FAILED = 'PRODUCTIMPORTVOUCHER_DELETE_FAILED';
export const PRODUCTIMPORTVOUCHER_DELETE_ALL = 'PRODUCTIMPORTVOUCHER_DELETE_ALL';
export const PRODUCTIMPORTVOUCHER_DELETE_ALL_SUCCESS = 'PRODUCTIMPORTVOUCHER_DELETE_ALL_SUCCESS';
export const PRODUCTIMPORTVOUCHER_DELETE_ALL_FAILED = 'PRODUCTIMPORTVOUCHER_DELETE_ALL_FAILED';
export const PRODUCTIMPORTVOUCHERS_EXPORT_EXCEL = 'PRODUCTIMPORTVOUCHERS_EXPORT_EXCEL';
export const PRODUCTIMPORTVOUCHERS_EXPORT_EXCEL_SUCCESS = 'PRODUCTIMPORTVOUCHERS_EXPORT_EXCEL_SUCCESS';
export const PRODUCTIMPORTVOUCHERS_EXPORT_EXCEL_FAILED = 'PRODUCTIMPORTVOUCHERS_EXPORT_EXCEL_FAILED';

// ProductExportVoucher const
export const PRODUCTEXPORTVOUCHERS_SELECT_ALL = 'PRODUCTEXPORTVOUCHERS_SELECT_ALL';
export const PRODUCTEXPORTVOUCHERS_UNSELECT_ALL = 'PRODUCTEXPORTVOUCHERS_UNSELECT_ALL';
export const PRODUCTEXPORTVOUCHER_SELECTED = 'PRODUCTEXPORTVOUCHER_SELECTED';
export const PRODUCTEXPORTVOUCHER_LOAD = 'PRODUCTEXPORTVOUCHER_LOAD';
export const PRODUCTEXPORTVOUCHER_LOAD_SUCCESS = 'PRODUCTEXPORTVOUCHER_LOAD_SUCCESS';
export const PRODUCTEXPORTVOUCHER_LOAD_FAILED = 'PRODUCTEXPORTVOUCHER_LOAD_FAILED';
export const PRODUCTEXPORTVOUCHERS_LOAD = 'PRODUCTEXPORTVOUCHERS_LOAD';
export const PRODUCTEXPORTVOUCHERS_LOAD_SUCCESS = 'PRODUCTEXPORTVOUCHERS_LOAD_SUCCESS';
export const PRODUCTEXPORTVOUCHERS_LOAD_FAILED = 'PRODUCTEXPORTVOUCHERS_LOAD_FAILED';
export const PRODUCTEXPORTVOUCHER_ADD = 'PRODUCTEXPORTVOUCHER_ADD';
export const PRODUCTEXPORTVOUCHER_ADD_SUCCESS = 'PRODUCTEXPORTVOUCHER_ADD_SUCCESS';
export const PRODUCTEXPORTVOUCHER_ADD_FAILED = 'PRODUCTEXPORTVOUCHER_ADD_FAILED';
export const PRODUCTEXPORTVOUCHER_UPDATE = 'PRODUCTEXPORTVOUCHER_UPDATE';
export const PRODUCTEXPORTVOUCHER_UPDATE_SUCCESS = 'PRODUCTEXPORTVOUCHER_UPDATE_SUCCESS';
export const PRODUCTEXPORTVOUCHER_UPDATE_FAILED = 'PRODUCTEXPORTVOUCHER_UPDATE_FAILED';
export const PRODUCTEXPORTVOUCHER_DELETE = 'PRODUCTEXPORTVOUCHER_DELETE';
export const PRODUCTEXPORTVOUCHER_DELETE_SUCCESS = 'PRODUCTEXPORTVOUCHER_DELETE_SUCCESS';
export const PRODUCTEXPORTVOUCHER_DELETE_FAILED = 'PRODUCTEXPORTVOUCHER_DELETE_FAILED';
export const PRODUCTEXPORTVOUCHER_DELETE_ALL = 'PRODUCTEXPORTVOUCHER_DELETE_ALL';
export const PRODUCTEXPORTVOUCHER_DELETE_ALL_SUCCESS = 'PRODUCTEXPORTVOUCHER_DELETE_ALL_SUCCESS';
export const PRODUCTEXPORTVOUCHER_DELETE_ALL_FAILED = 'PRODUCTEXPORTVOUCHER_DELETE_ALL_FAILED';
export const PRODUCTEXPORTVOUCHERS_EXPORT_EXCEL = 'PRODUCTEXPORTVOUCHERS_EXPORT_EXCEL';
export const PRODUCTEXPORTVOUCHERS_EXPORT_EXCEL_SUCCESS = 'PRODUCTEXPORTVOUCHERS_EXPORT_EXCEL_SUCCESS';
export const PRODUCTEXPORTVOUCHERS_EXPORT_EXCEL_FAILED = 'PRODUCTEXPORTVOUCHERS_EXPORT_EXCEL_FAILED';

// Supply const
export const SUPPLIES_SELECT_ALL = 'SUPPLIES_SELECT_ALL';
export const SUPPLIES_UNSELECT_ALL = 'SUPPLIES_UNSELECT_ALL';
export const SUPPLY_SELECTED = 'SUPPLY_SELECTED';
export const SUPPLY_LOAD = 'SUPPLY_LOAD';
export const SUPPLY_LOAD_SUCCESS = 'SUPPLY_LOAD_SUCCESS';
export const SUPPLY_LOAD_FAILED = 'SUPPLY_LOAD_FAILED';
export const SUPPLIES_LOAD = 'SUPPLIES_LOAD';
export const SUPPLIES_LOAD_SUCCESS = 'SUPPLIES_LOAD_SUCCESS';
export const SUPPLIES_LOAD_FAILED = 'SUPPLIES_LOAD_FAILED';
export const SUPPLY_ADD = 'SUPPLY_ADD';
export const SUPPLY_ADD_SUCCESS = 'SUPPLY_ADD_SUCCESS';
export const SUPPLY_ADD_FAILED = 'SUPPLY_ADD_FAILED';
export const SUPPLY_UPDATE = 'SUPPLY_UPDATE';
export const SUPPLY_UPDATE_SUCCESS = 'SUPPLY_UPDATE_SUCCESS';
export const SUPPLY_UPDATE_FAILED = 'SUPPLY_UPDATE_FAILED';
export const SUPPLY_DELETE = 'SUPPLY_DELETE';
export const SUPPLY_DELETE_SUCCESS = 'SUPPLY_DELETE_SUCCESS';
export const SUPPLY_DELETE_FAILED = 'SUPPLY_DELETE_FAILED';
export const SUPPLY_DELETE_ALL = 'SUPPLY_DELETE_ALL';
export const SUPPLY_DELETE_ALL_SUCCESS = 'SUPPLY_DELETE_ALL_SUCCESS';
export const SUPPLY_DELETE_ALL_FAILED = 'SUPPLY_DELETE_ALL_FAILED';
export const SUPPLIES_EXPORT_EXCEL = 'SUPPLIES_EXPORT_EXCEL';
export const SUPPLIES_EXPORT_EXCEL_SUCCESS = 'SUPPLIES_EXPORT_EXCEL_SUCCESS';
export const SUPPLIES_EXPORT_EXCEL_FAILED = 'SUPPLIES_EXPORT_EXCEL_FAILED';

// SupplyImportVoucher const
export const SUPPLYIMPORTVOUCHERS_SELECT_ALL = 'SUPPLYIMPORTVOUCHERS_SELECT_ALL';
export const SUPPLYIMPORTVOUCHERS_UNSELECT_ALL = 'SUPPLYIMPORTVOUCHERS_UNSELECT_ALL';
export const SUPPLYIMPORTVOUCHER_SELECTED = 'SUPPLYIMPORTVOUCHER_SELECTED';
export const SUPPLYIMPORTVOUCHER_LOAD = 'SUPPLYIMPORTVOUCHER_LOAD';
export const SUPPLYIMPORTVOUCHER_LOAD_SUCCESS = 'SUPPLYIMPORTVOUCHER_LOAD_SUCCESS';
export const SUPPLYIMPORTVOUCHER_LOAD_FAILED = 'SUPPLYIMPORTVOUCHER_LOAD_FAILED';
export const SUPPLYIMPORTVOUCHERS_LOAD = 'SUPPLYIMPORTVOUCHERS_LOAD';
export const SUPPLYIMPORTVOUCHERS_LOAD_SUCCESS = 'SUPPLYIMPORTVOUCHERS_LOAD_SUCCESS';
export const SUPPLYIMPORTVOUCHERS_LOAD_FAILED = 'SUPPLYIMPORTVOUCHERS_LOAD_FAILED';
export const SUPPLYIMPORTVOUCHER_ADD = 'SUPPLYIMPORTVOUCHER_ADD';
export const SUPPLYIMPORTVOUCHER_ADD_SUCCESS = 'SUPPLYIMPORTVOUCHER_ADD_SUCCESS';
export const SUPPLYIMPORTVOUCHER_ADD_FAILED = 'SUPPLYIMPORTVOUCHER_ADD_FAILED';
export const SUPPLYIMPORTVOUCHER_UPDATE = 'SUPPLYIMPORTVOUCHER_UPDATE';
export const SUPPLYIMPORTVOUCHER_UPDATE_SUCCESS = 'SUPPLYIMPORTVOUCHER_UPDATE_SUCCESS';
export const SUPPLYIMPORTVOUCHER_UPDATE_FAILED = 'SUPPLYIMPORTVOUCHER_UPDATE_FAILED';
export const SUPPLYIMPORTVOUCHER_DELETE = 'SUPPLYIMPORTVOUCHER_DELETE';
export const SUPPLYIMPORTVOUCHER_DELETE_SUCCESS = 'SUPPLYIMPORTVOUCHER_DELETE_SUCCESS';
export const SUPPLYIMPORTVOUCHER_DELETE_FAILED = 'SUPPLYIMPORTVOUCHER_DELETE_FAILED';
export const SUPPLYIMPORTVOUCHER_DELETE_ALL = 'SUPPLYIMPORTVOUCHER_DELETE_ALL';
export const SUPPLYIMPORTVOUCHER_DELETE_ALL_SUCCESS = 'SUPPLYIMPORTVOUCHER_DELETE_ALL_SUCCESS';
export const SUPPLYIMPORTVOUCHER_DELETE_ALL_FAILED = 'SUPPLYIMPORTVOUCHER_DELETE_ALL_FAILED';
export const SUPPLYIMPORTVOUCHERS_EXPORT_EXCEL = 'SUPPLYIMPORTVOUCHERS_EXPORT_EXCEL';
export const SUPPLYIMPORTVOUCHERS_EXPORT_EXCEL_SUCCESS = 'SUPPLYIMPORTVOUCHERS_EXPORT_EXCEL_SUCCESS';
export const SUPPLYIMPORTVOUCHERS_EXPORT_EXCEL_FAILED = 'SUPPLYIMPORTVOUCHERS_EXPORT_EXCEL_FAILED';

// SupplyExportVoucher const
export const SUPPLYEXPORTVOUCHERS_SELECT_ALL = 'SUPPLYEXPORTVOUCHERS_SELECT_ALL';
export const SUPPLYEXPORTVOUCHERS_UNSELECT_ALL = 'SUPPLYEXPORTVOUCHERS_UNSELECT_ALL';
export const SUPPLYEXPORTVOUCHER_SELECTED = 'SUPPLYEXPORTVOUCHER_SELECTED';
export const SUPPLYEXPORTVOUCHER_LOAD = 'SUPPLYEXPORTVOUCHER_LOAD';
export const SUPPLYEXPORTVOUCHER_LOAD_SUCCESS = 'SUPPLYEXPORTVOUCHER_LOAD_SUCCESS';
export const SUPPLYEXPORTVOUCHER_LOAD_FAILED = 'SUPPLYEXPORTVOUCHER_LOAD_FAILED';
export const SUPPLYEXPORTVOUCHERS_LOAD = 'SUPPLYEXPORTVOUCHERS_LOAD';
export const SUPPLYEXPORTVOUCHERS_LOAD_SUCCESS = 'SUPPLYEXPORTVOUCHERS_LOAD_SUCCESS';
export const SUPPLYEXPORTVOUCHERS_LOAD_FAILED = 'SUPPLYEXPORTVOUCHERS_LOAD_FAILED';
export const SUPPLYEXPORTVOUCHER_ADD = 'SUPPLYEXPORTVOUCHER_ADD';
export const SUPPLYEXPORTVOUCHER_ADD_SUCCESS = 'SUPPLYEXPORTVOUCHER_ADD_SUCCESS';
export const SUPPLYEXPORTVOUCHER_ADD_FAILED = 'SUPPLYEXPORTVOUCHER_ADD_FAILED';
export const SUPPLYEXPORTVOUCHER_UPDATE = 'SUPPLYEXPORTVOUCHER_UPDATE';
export const SUPPLYEXPORTVOUCHER_UPDATE_SUCCESS = 'SUPPLYEXPORTVOUCHER_UPDATE_SUCCESS';
export const SUPPLYEXPORTVOUCHER_UPDATE_FAILED = 'SUPPLYEXPORTVOUCHER_UPDATE_FAILED';
export const SUPPLYEXPORTVOUCHER_DELETE = 'SUPPLYEXPORTVOUCHER_DELETE';
export const SUPPLYEXPORTVOUCHER_DELETE_SUCCESS = 'SUPPLYEXPORTVOUCHER_DELETE_SUCCESS';
export const SUPPLYEXPORTVOUCHER_DELETE_FAILED = 'SUPPLYEXPORTVOUCHER_DELETE_FAILED';
export const SUPPLYEXPORTVOUCHER_DELETE_ALL = 'SUPPLYEXPORTVOUCHER_DELETE_ALL';
export const SUPPLYEXPORTVOUCHER_DELETE_ALL_SUCCESS = 'SUPPLYEXPORTVOUCHER_DELETE_ALL_SUCCESS';
export const SUPPLYEXPORTVOUCHER_DELETE_ALL_FAILED = 'SUPPLYEXPORTVOUCHER_DELETE_ALL_FAILED';
export const SUPPLYEXPORTVOUCHERS_EXPORT_EXCEL = 'SUPPLYEXPORTVOUCHERS_EXPORT_EXCEL';
export const SUPPLYEXPORTVOUCHERS_EXPORT_EXCEL_SUCCESS = 'SUPPLYEXPORTVOUCHERS_EXPORT_EXCEL_SUCCESS';
export const SUPPLYEXPORTVOUCHERS_EXPORT_EXCEL_FAILED = 'SUPPLYEXPORTVOUCHERS_EXPORT_EXCEL_FAILED';

// Sample const
export const SAMPLES_SELECT_ALL = 'SAMPLES_SELECT_ALL';
export const SAMPLES_UNSELECT_ALL = 'SAMPLES_UNSELECT_ALL';
export const SAMPLE_SELECTED = 'SAMPLE_SELECTED';
export const SAMPLE_LOAD = 'SAMPLE_LOAD';
export const SAMPLE_LOAD_SUCCESS = 'SAMPLE_LOAD_SUCCESS';
export const SAMPLE_LOAD_FAILED = 'SAMPLE_LOAD_FAILED';
export const SAMPLES_LOAD = 'SAMPLES_LOAD';
export const SAMPLES_LOAD_SUCCESS = 'SAMPLES_LOAD_SUCCESS';
export const SAMPLES_LOAD_FAILED = 'SAMPLES_LOAD_FAILED';
export const SAMPLE_ADD = 'SAMPLE_ADD';
export const SAMPLE_ADD_SUCCESS = 'SAMPLE_ADD_SUCCESS';
export const SAMPLE_ADD_FAILED = 'SAMPLE_ADD_FAILED';
export const SAMPLE_UPDATE = 'SAMPLE_UPDATE';
export const SAMPLE_UPDATE_SUCCESS = 'SAMPLE_UPDATE_SUCCESS';
export const SAMPLE_UPDATE_FAILED = 'SAMPLE_UPDATE_FAILED';
export const SAMPLE_DELETE = 'SAMPLE_DELETE';
export const SAMPLE_DELETE_SUCCESS = 'SAMPLE_DELETE_SUCCESS';
export const SAMPLE_DELETE_FAILED = 'SAMPLE_DELETE_FAILED';
export const SAMPLE_DELETE_ALL = 'SAMPLE_DELETE_ALL';
export const SAMPLE_DELETE_ALL_SUCCESS = 'SAMPLE_DELETE_ALL_SUCCESS';
export const SAMPLE_DELETE_ALL_FAILED = 'SAMPLE_DELETE_ALL_FAILED';
export const SAMPLES_EXPORT_EXCEL = 'SAMPLES_EXPORT_EXCEL';
export const SAMPLES_EXPORT_EXCEL_SUCCESS = 'SAMPLES_EXPORT_EXCEL_SUCCESS';
export const SAMPLES_EXPORT_EXCEL_FAILED = 'SAMPLES_EXPORT_EXCEL_FAILED';

// SUPPLYVOUCHERITEM const
export const SUPPLYVOUCHERITEMS_SELECT_ALL = 'SUPPLYVOUCHERITEMS_SELECT_ALL';
export const SUPPLYVOUCHERITEMS_UNSELECT_ALL = 'SUPPLYVOUCHERITEMS_UNSELECT_ALL';
export const SUPPLYVOUCHERITEM_SELECTED = 'SUPPLYVOUCHERITEM_SELECTED';
export const SUPPLYVOUCHERITEM_LOAD = 'SUPPLYVOUCHERITEM_LOAD';
export const SUPPLYVOUCHERITEM_LOAD_SUCCESS = 'SUPPLYVOUCHERITEM_LOAD_SUCCESS';
export const SUPPLYVOUCHERITEM_LOAD_FAILED = 'SUPPLYVOUCHERITEM_LOAD_FAILED';
export const SUPPLYVOUCHERITEMS_LOAD = 'SUPPLYVOUCHERITEMS_LOAD';
export const SUPPLYVOUCHERITEMS_LOAD_SUCCESS = 'SUPPLYVOUCHERITEMS_LOAD_SUCCESS';
export const SUPPLYVOUCHERITEMS_LOAD_FAILED = 'SUPPLYVOUCHERITEMS_LOAD_FAILED';
export const SUPPLYVOUCHERITEM_ADD = 'SUPPLYVOUCHERITEM_ADD';
export const SUPPLYVOUCHERITEM_ADD_SUCCESS = 'SUPPLYVOUCHERITEM_ADD_SUCCESS';
export const SUPPLYVOUCHERITEM_ADD_FAILED = 'SUPPLYVOUCHERITEM_ADD_FAILED';
export const SUPPLYVOUCHERITEM_UPDATE = 'SUPPLYVOUCHERITEM_UPDATE';
export const SUPPLYVOUCHERITEM_UPDATE_SUCCESS = 'SUPPLYVOUCHERITEM_UPDATE_SUCCESS';
export const SUPPLYVOUCHERITEM_UPDATE_FAILED = 'SUPPLYVOUCHERITEM_UPDATE_FAILED';
export const SUPPLYVOUCHERITEM_DELETE = 'SUPPLYVOUCHERITEM_DELETE';
export const SUPPLYVOUCHERITEM_DELETE_SUCCESS = 'SUPPLYVOUCHERITEM_DELETE_SUCCESS';
export const SUPPLYVOUCHERITEM_DELETE_FAILED = 'SUPPLYVOUCHERITEM_DELETE_FAILED';
export const SUPPLYVOUCHERITEM_DELETE_ALL = 'SUPPLYVOUCHERITEM_DELETE_ALL';
export const SUPPLYVOUCHERITEM_DELETE_ALL_SUCCESS = 'SUPPLYVOUCHERITEM_DELETE_ALL_SUCCESS';
export const SUPPLYVOUCHERITEM_DELETE_ALL_FAILED = 'SUPPLYVOUCHERITEM_DELETE_ALL_FAILED';
export const SUPPLYVOUCHERITEMS_EXPORT_EXCEL = 'SUPPLYVOUCHERITEMS_EXPORT_EXCEL';
export const SUPPLYVOUCHERITEMS_EXPORT_EXCEL_SUCCESS = 'SUPPLYVOUCHERITEMS_EXPORT_EXCEL_SUCCESS';
export const SUPPLYVOUCHERITEMS_EXPORT_EXCEL_FAILED = 'SUPPLYVOUCHERITEMS_EXPORT_EXCEL_FAILED';
export const SUPPLYVOUCHERITEMS_SET_FILTER = 'SUPPLYVOUCHERITEMS_SET_FILTER';

// SUPPLYFORM const
export const SUPPLYFORMS_SELECT_ALL = 'SUPPLYFORMS_SELECT_ALL';
export const SUPPLYFORMS_UNSELECT_ALL = 'SUPPLYFORMS_UNSELECT_ALL';
export const SUPPLYFORM_SELECTED = 'SUPPLYFORM_SELECTED';
export const SUPPLYFORM_LOAD = 'SUPPLYFORM_LOAD';
export const SUPPLYFORM_LOAD_SUCCESS = 'SUPPLYFORM_LOAD_SUCCESS';
export const SUPPLYFORM_LOAD_FAILED = 'SUPPLYFORM_LOAD_FAILED';
export const SUPPLYFORMS_LOAD = 'SUPPLYFORMS_LOAD';
export const SUPPLYFORMS_LOAD_SUCCESS = 'SUPPLYFORMS_LOAD_SUCCESS';
export const SUPPLYFORMS_LOAD_FAILED = 'SUPPLYFORMS_LOAD_FAILED';
export const SUPPLYFORM_ADD = 'SUPPLYFORM_ADD';
export const SUPPLYFORM_ADD_SUCCESS = 'SUPPLYFORM_ADD_SUCCESS';
export const SUPPLYFORM_ADD_FAILED = 'SUPPLYFORM_ADD_FAILED';
export const SUPPLYFORM_UPDATE = 'SUPPLYFORM_UPDATE';
export const SUPPLYFORM_UPDATE_SUCCESS = 'SUPPLYFORM_UPDATE_SUCCESS';
export const SUPPLYFORM_UPDATE_FAILED = 'SUPPLYFORM_UPDATE_FAILED';
export const SUPPLYFORM_DELETE = 'SUPPLYFORM_DELETE';
export const SUPPLYFORM_DELETE_SUCCESS = 'SUPPLYFORM_DELETE_SUCCESS';
export const SUPPLYFORM_DELETE_FAILED = 'SUPPLYFORM_DELETE_FAILED';
export const SUPPLYFORM_DELETE_ALL = 'SUPPLYFORM_DELETE_ALL';
export const SUPPLYFORM_DELETE_ALL_SUCCESS = 'SUPPLYFORM_DELETE_ALL_SUCCESS';
export const SUPPLYFORM_DELETE_ALL_FAILED = 'SUPPLYFORM_DELETE_ALL_FAILED';
export const SUPPLYFORMS_EXPORT_EXCEL = 'SUPPLYFORMS_EXPORT_EXCEL';
export const SUPPLYFORMS_EXPORT_EXCEL_SUCCESS = 'SUPPLYFORMS_EXPORT_EXCEL_SUCCESS';
export const SUPPLYFORMS_EXPORT_EXCEL_FAILED = 'SUPPLYFORMS_EXPORT_EXCEL_FAILED';

// SUPPLYFORMITEM const
export const SUPPLYFORMITEMS_SELECT_ALL = 'SUPPLYFORMITEMS_SELECT_ALL';
export const SUPPLYFORMITEMS_UNSELECT_ALL = 'SUPPLYFORMITEMS_UNSELECT_ALL';
export const SUPPLYFORMITEM_SELECTED = 'SUPPLYFORMITEM_SELECTED';

// PROVIDER const
export const PROVIDERS_SELECT_ALL = 'PROVIDERS_SELECT_ALL';
export const PROVIDERS_UNSELECT_ALL = 'PROVIDERS_UNSELECT_ALL';
export const PROVIDER_SELECTED = 'PROVIDER_SELECTED';
export const PROVIDER_LOAD = 'PROVIDER_LOAD';
export const PROVIDER_LOAD_SUCCESS = 'PROVIDER_LOAD_SUCCESS';
export const PROVIDER_LOAD_FAILED = 'PROVIDER_LOAD_FAILED';
export const PROVIDERS_LOAD = 'PROVIDERS_LOAD';
export const PROVIDERS_LOAD_SUCCESS = 'PROVIDERS_LOAD_SUCCESS';
export const PROVIDERS_LOAD_FAILED = 'PROVIDERS_LOAD_FAILED';
export const PROVIDER_ADD = 'PROVIDER_ADD';
export const PROVIDER_ADD_SUCCESS = 'PROVIDER_ADD_SUCCESS';
export const PROVIDER_ADD_FAILED = 'PROVIDER_ADD_FAILED';
export const PROVIDER_UPDATE = 'PROVIDER_UPDATE';
export const PROVIDER_UPDATE_SUCCESS = 'PROVIDER_UPDATE_SUCCESS';
export const PROVIDER_UPDATE_FAILED = 'PROVIDER_UPDATE_FAILED';
export const PROVIDER_DELETE = 'PROVIDER_DELETE';
export const PROVIDER_DELETE_SUCCESS = 'PROVIDER_DELETE_SUCCESS';
export const PROVIDER_DELETE_FAILED = 'PROVIDER_DELETE_FAILED';
export const PROVIDER_DELETE_ALL = 'PROVIDER_DELETE_ALL';
export const PROVIDER_DELETE_ALL_SUCCESS = 'PROVIDER_DELETE_ALL_SUCCESS';
export const PROVIDER_DELETE_ALL_FAILED = 'PROVIDER_DELETE_ALL_FAILED';
export const PROVIDERS_EXPORT_EXCEL = 'PROVIDERS_EXPORT_EXCEL';
export const PROVIDERS_EXPORT_EXCEL_SUCCESS = 'PROVIDERS_EXPORT_EXCEL_SUCCESS';
export const PROVIDERS_EXPORT_EXCEL_FAILED = 'PROVIDERS_EXPORT_EXCEL_FAILED';
export const PROVIDERS_REPORT = 'PROVIDERS_REPORT';
export const PROVIDERS_REPORT_SUCCESS = 'PROVIDERS_REPORT_SUCCESS';
export const PROVIDERS_REPORT_FAILED = 'PROVIDERS_REPORT_FAILED';

// STORE const
export const STORES_SELECT_ALL = 'STORES_SELECT_ALL';
export const STORES_UNSELECT_ALL = 'STORES_UNSELECT_ALL';
export const STORE_SELECTED = 'STORE_SELECTED';
export const STORE_LOAD = 'STORE_LOAD';
export const STORE_LOAD_SUCCESS = 'STORE_LOAD_SUCCESS';
export const STORE_LOAD_FAILED = 'STORE_LOAD_FAILED';
export const STORES_LOAD = 'STORES_LOAD';
export const STORES_LOAD_SUCCESS = 'STORES_LOAD_SUCCESS';
export const STORES_LOAD_FAILED = 'STORES_LOAD_FAILED';
export const STORE_ADD = 'STORE_ADD';
export const STORE_ADD_SUCCESS = 'STORE_ADD_SUCCESS';
export const STORE_ADD_FAILED = 'STORE_ADD_FAILED';
export const STORE_UPDATE = 'STORE_UPDATE';
export const STORE_UPDATE_SUCCESS = 'STORE_UPDATE_SUCCESS';
export const STORE_UPDATE_FAILED = 'STORE_UPDATE_FAILED';
export const STORE_DELETE = 'STORE_DELETE';
export const STORE_DELETE_SUCCESS = 'STORE_DELETE_SUCCESS';
export const STORE_DELETE_FAILED = 'STORE_DELETE_FAILED';
export const STORE_DELETE_ALL = 'STORE_DELETE_ALL';
export const STORE_DELETE_ALL_SUCCESS = 'STORE_DELETE_ALL_SUCCESS';
export const STORE_DELETE_ALL_FAILED = 'STORE_DELETE_ALL_FAILED';
export const STORES_EXPORT_EXCEL = 'STORES_EXPORT_EXCEL';
export const STORES_EXPORT_EXCEL_SUCCESS = 'STORES_EXPORT_EXCEL_SUCCESS';
export const STORES_EXPORT_EXCEL_FAILED = 'STORES_EXPORT_EXCEL_FAILED';
export const STORES_REPORT = 'STORES_REPORT';
export const STORES_REPORT_SUCCESS = 'STORES_REPORT_SUCCESS';
export const STORES_REPORT_FAILED = 'STORES_REPORT_FAILED';
// ORDER const
export const ORDERS_SELECT_ALL = 'ORDERS_SELECT_ALL';
export const ORDERS_UNSELECT_ALL = 'ORDERS_UNSELECT_ALL';
export const ORDER_SELECTED = 'ORDER_SELECTED';
export const ORDER_LOAD = 'ORDER_LOAD';
export const ORDER_LOAD_SUCCESS = 'ORDER_LOAD_SUCCESS';
export const ORDER_LOAD_FAILED = 'ORDER_LOAD_FAILED';
export const ORDERS_LOAD = 'ORDERS_LOAD';
export const ORDERS_LOAD_SUCCESS = 'ORDERS_LOAD_SUCCESS';
export const ORDERS_LOAD_FAILED = 'ORDERS_LOAD_FAILED';
export const ORDER_ADD = 'ORDER_ADD';
export const ORDER_ADD_SUCCESS = 'ORDER_ADD_SUCCESS';
export const ORDER_ADD_FAILED = 'ORDER_ADD_FAILED';
export const ORDER_UPDATE = 'ORDER_UPDATE';
export const ORDER_UPDATE_SUCCESS = 'ORDER_UPDATE_SUCCESS';
export const ORDER_UPDATE_FAILED = 'ORDER_UPDATE_FAILED';
export const ORDER_DELETE = 'ORDER_DELETE';
export const ORDER_DELETE_SUCCESS = 'ORDER_DELETE_SUCCESS';
export const ORDER_DELETE_FAILED = 'ORDER_DELETE_FAILED';
export const ORDER_DELETE_ALL = 'ORDER_DELETE_ALL';
export const ORDER_DELETE_ALL_SUCCESS = 'ORDER_DELETE_ALL_SUCCESS';
export const ORDER_DELETE_ALL_FAILED = 'ORDER_DELETE_ALL_FAILED';
export const ORDERS_EXPORT_EXCEL = 'ORDERS_EXPORT_EXCEL';
export const ORDERS_EXPORT_EXCEL_SUCCESS = 'ORDERS_EXPORT_EXCEL_SUCCESS';
export const ORDERS_EXPORT_EXCEL_FAILED = 'ORDERS_EXPORT_EXCEL_FAILED';
export const ORDERS_REPORT = 'ORDERS_REPORT';
export const ORDERS_REPORT_SUCCESS = 'ORDERS_REPORT_SUCCESS';
export const ORDERS_REPORT_FAILED = 'ORDERS_REPORT_FAILED';

// ORDERPRODUCT const
export const ORDERPRODUCTS_SELECT_ALL = 'ORDERPRODUCTS_SELECT_ALL';
export const ORDERPRODUCTS_UNSELECT_ALL = 'ORDERPRODUCTS_UNSELECT_ALL';
export const ORDERPRODUCT_SELECTED = 'ORDERPRODUCT_SELECTED';
export const ORDERPRODUCT_LOAD = 'ORDERPRODUCT_LOAD';
export const ORDERPRODUCT_LOAD_SUCCESS = 'ORDERPRODUCT_LOAD_SUCCESS';
export const ORDERPRODUCT_LOAD_FAILED = 'ORDERPRODUCT_LOAD_FAILED';
export const ORDERPRODUCTS_LOAD = 'ORDERPRODUCTS_LOAD';
export const ORDERPRODUCTS_LOAD_SUCCESS = 'ORDERPRODUCTS_LOAD_SUCCESS';
export const ORDERPRODUCTS_LOAD_FAILED = 'ORDERPRODUCTS_LOAD_FAILED';
export const ORDERPRODUCT_ADD = 'ORDERPRODUCT_ADD';
export const ORDERPRODUCT_ADD_SUCCESS = 'ORDERPRODUCT_ADD_SUCCESS';
export const ORDERPRODUCT_ADD_FAILED = 'ORDERPRODUCT_ADD_FAILED';
export const ORDERPRODUCT_UPDATE = 'ORDERPRODUCT_UPDATE';
export const ORDERPRODUCT_UPDATE_SUCCESS = 'ORDERPRODUCT_UPDATE_SUCCESS';
export const ORDERPRODUCT_UPDATE_FAILED = 'ORDERPRODUCT_UPDATE_FAILED';
export const ORDERPRODUCT_DELETE = 'ORDERPRODUCT_DELETE';
export const ORDERPRODUCT_DELETE_SUCCESS = 'ORDERPRODUCT_DELETE_SUCCESS';
export const ORDERPRODUCT_DELETE_FAILED = 'ORDERPRODUCT_DELETE_FAILED';
export const ORDERPRODUCT_DELETE_ALL = 'ORDERPRODUCT_DELETE_ALL';
export const ORDERPRODUCT_DELETE_ALL_SUCCESS = 'ORDERPRODUCT_DELETE_ALL_SUCCESS';
export const ORDERPRODUCT_DELETE_ALL_FAILED = 'ORDERPRODUCT_DELETE_ALL_FAILED';
export const ORDERPRODUCTS_EXPORT_EXCEL = 'ORDERPRODUCTS_EXPORT_EXCEL';
export const ORDERPRODUCTS_EXPORT_EXCEL_SUCCESS = 'ORDERPRODUCTS_EXPORT_EXCEL_SUCCESS';
export const ORDERPRODUCTS_EXPORT_EXCEL_FAILED = 'ORDERPRODUCTS_EXPORT_EXCEL_FAILED';
export const ORDERPRODUCTS_REPORT = 'ORDERPRODUCTS_REPORT';
export const ORDERPRODUCTS_REPORT_SUCCESS = 'ORDERPRODUCTS_REPORT_SUCCESS';
export const ORDERPRODUCTS_REPORT_FAILED = 'ORDERPRODUCTS_REPORT_FAILED';

// CATEGORY const
export const CATEGORIES_SELECT_ALL = 'CATEGORIES_SELECT_ALL';
export const CATEGORIES_UNSELECT_ALL = 'CATEGORIES_UNSELECT_ALL';
export const CATEGORY_SELECTED = 'CATEGORY_SELECTED';
export const CATEGORY_LOAD = 'CATEGORY_LOAD';
export const CATEGORY_LOAD_SUCCESS = 'CATEGORY_LOAD_SUCCESS';
export const CATEGORY_LOAD_FAILED = 'CATEGORY_LOAD_FAILED';
export const CATEGORIES_LOAD = 'CATEGORIES_LOAD';
export const CATEGORIES_LOAD_SUCCESS = 'CATEGORIES_LOAD_SUCCESS';
export const CATEGORIES_LOAD_FAILED = 'CATEGORIES_LOAD_FAILED';
export const CATEGORY_ADD = 'CATEGORY_ADD';
export const CATEGORY_ADD_SUCCESS = 'CATEGORY_ADD_SUCCESS';
export const CATEGORY_ADD_FAILED = 'CATEGORY_ADD_FAILED';
export const CATEGORY_UPDATE = 'CATEGORY_UPDATE';
export const CATEGORY_UPDATE_SUCCESS = 'CATEGORY_UPDATE_SUCCESS';
export const CATEGORY_UPDATE_FAILED = 'CATEGORY_UPDATE_FAILED';
export const CATEGORY_DELETE = 'CATEGORY_DELETE';
export const CATEGORY_DELETE_SUCCESS = 'CATEGORY_DELETE_SUCCESS';
export const CATEGORY_DELETE_FAILED = 'CATEGORY_DELETE_FAILED';
export const CATEGORY_DELETE_ALL = 'CATEGORY_DELETE_ALL';
export const CATEGORY_DELETE_ALL_SUCCESS = 'CATEGORY_DELETE_ALL_SUCCESS';
export const CATEGORY_DELETE_ALL_FAILED = 'CATEGORY_DELETE_ALL_FAILED';
export const CATEGORIES_EXPORT_EXCEL = 'CATEGORIES_EXPORT_EXCEL';
export const CATEGORIES_EXPORT_EXCEL_SUCCESS = 'CATEGORIES_EXPORT_EXCEL_SUCCESS';
export const CATEGORIES_EXPORT_EXCEL_FAILED = 'CATEGORIES_EXPORT_EXCEL_FAILED';
export const CATEGORIES_REPORT = 'CATEGORIES_REPORT';
export const CATEGORIES_REPORT_SUCCESS = 'CATEGORIES_REPORT_SUCCESS';
export const CATEGORIES_REPORT_FAILED = 'CATEGORIES_REPORT_FAILED';

// STOREPRODUCT const
export const STOREPRODUCTS_SELECT_ALL = 'STOREPRODUCTS_SELECT_ALL';
export const STOREPRODUCTS_UNSELECT_ALL = 'STOREPRODUCTS_UNSELECT_ALL';
export const STOREPRODUCT_SELECTED = 'STOREPRODUCT_SELECTED';
export const STOREPRODUCT_LOAD = 'STOREPRODUCT_LOAD';
export const STOREPRODUCT_LOAD_SUCCESS = 'STOREPRODUCT_LOAD_SUCCESS';
export const STOREPRODUCT_LOAD_FAILED = 'STOREPRODUCT_LOAD_FAILED';
export const STOREPRODUCTS_LOAD = 'STOREPRODUCTS_LOAD';
export const STOREPRODUCTS_LOAD_SUCCESS = 'STOREPRODUCTS_LOAD_SUCCESS';
export const STOREPRODUCTS_LOAD_FAILED = 'STOREPRODUCTS_LOAD_FAILED';
export const STOREPRODUCT_ADD = 'STOREPRODUCT_ADD';
export const STOREPRODUCT_ADD_SUCCESS = 'STOREPRODUCT_ADD_SUCCESS';
export const STOREPRODUCT_ADD_FAILED = 'STOREPRODUCT_ADD_FAILED';
export const STOREPRODUCT_UPDATE = 'STOREPRODUCT_UPDATE';
export const STOREPRODUCT_UPDATE_SUCCESS = 'STOREPRODUCT_UPDATE_SUCCESS';
export const STOREPRODUCT_UPDATE_FAILED = 'STOREPRODUCT_UPDATE_FAILED';
export const STOREPRODUCT_DELETE = 'STOREPRODUCT_DELETE';
export const STOREPRODUCT_DELETE_SUCCESS = 'STOREPRODUCT_DELETE_SUCCESS';
export const STOREPRODUCT_DELETE_FAILED = 'STOREPRODUCT_DELETE_FAILED';
export const STOREPRODUCT_DELETE_ALL = 'STOREPRODUCT_DELETE_ALL';
export const STOREPRODUCT_DELETE_ALL_SUCCESS = 'STOREPRODUCT_DELETE_ALL_SUCCESS';
export const STOREPRODUCT_DELETE_ALL_FAILED = 'STOREPRODUCT_DELETE_ALL_FAILED';
export const STOREPRODUCTS_EXPORT_EXCEL = 'STOREPRODUCTS_EXPORT_EXCEL';
export const STOREPRODUCTS_EXPORT_EXCEL_SUCCESS = 'STOREPRODUCTS_EXPORT_EXCEL_SUCCESS';
export const STOREPRODUCTS_EXPORT_EXCEL_FAILED = 'STOREPRODUCTS_EXPORT_EXCEL_FAILED';
export const STOREPRODUCTS_REPORT = 'STOREPRODUCTS_REPORT';
export const STOREPRODUCTS_REPORT_SUCCESS = 'STOREPRODUCTS_REPORT_SUCCESS';
export const STOREPRODUCTS_REPORT_FAILED = 'STOREPRODUCTS_REPORT_FAILED';

// TIMEKEEP const
export const TIMEKEEPS_SELECT_ALL = 'TIMEKEEPS_SELECT_ALL';
export const TIMEKEEPS_UNSELECT_ALL = 'TIMEKEEPS_UNSELECT_ALL';
export const TIMEKEEP_SELECTED = 'TIMEKEEP_SELECTED';
export const TIMEKEEP_LOAD = 'TIMEKEEP_LOAD';
export const TIMEKEEP_LOAD_SUCCESS = 'TIMEKEEP_LOAD_SUCCESS';
export const TIMEKEEP_LOAD_FAILED = 'TIMEKEEP_LOAD_FAILED';
export const TIMEKEEPS_LOAD = 'TIMEKEEPS_LOAD';
export const TIMEKEEPS_LOAD_SUCCESS = 'TIMEKEEPS_LOAD_SUCCESS';
export const TIMEKEEPS_LOAD_FAILED = 'TIMEKEEPS_LOAD_FAILED';
export const TIMEKEEP_ADD = 'TIMEKEEP_ADD';
export const TIMEKEEP_ADD_SUCCESS = 'TIMEKEEP_ADD_SUCCESS';
export const TIMEKEEP_ADD_FAILED = 'TIMEKEEP_ADD_FAILED';
export const TIMEKEEP_UPDATE = 'TIMEKEEP_UPDATE';
export const TIMEKEEP_UPDATE_SUCCESS = 'TIMEKEEP_UPDATE_SUCCESS';
export const TIMEKEEP_UPDATE_FAILED = 'TIMEKEEP_UPDATE_FAILED';
export const TIMEKEEP_DELETE = 'TIMEKEEP_DELETE';
export const TIMEKEEP_DELETE_SUCCESS = 'TIMEKEEP_DELETE_SUCCESS';
export const TIMEKEEP_DELETE_FAILED = 'TIMEKEEP_DELETE_FAILED';
export const TIMEKEEP_DELETE_ALL = 'TIMEKEEP_DELETE_ALL';
export const TIMEKEEP_DELETE_ALL_SUCCESS = 'TIMEKEEP_DELETE_ALL_SUCCESS';
export const TIMEKEEP_DELETE_ALL_FAILED = 'TIMEKEEP_DELETE_ALL_FAILED';
export const TIMEKEEPS_EXPORT_EXCEL = 'TIMEKEEPS_EXPORT_EXCEL';
export const TIMEKEEPS_EXPORT_EXCEL_SUCCESS = 'TIMEKEEPS_EXPORT_EXCEL_SUCCESS';
export const TIMEKEEPS_EXPORT_EXCEL_FAILED = 'TIMEKEEPS_EXPORT_EXCEL_FAILED';
export const TIMEKEEPS_COUNT = 'TIMEKEEPS_COUNT';
export const TIMEKEEPS_COUNT_SUCCESS = 'TIMEKEEPS_COUNT_SUCCESS';


// SUPPLYFORMITEM const
export const SUPPLYFORMITEM_LOAD = 'SUPPLYFORMITEM_LOAD';
export const SUPPLYFORMITEM_LOAD_SUCCESS = 'SUPPLYFORMITEM_LOAD_SUCCESS';
export const SUPPLYFORMITEM_LOAD_FAILED = 'SUPPLYFORMITEM_LOAD_FAILED';
export const SUPPLYFORMITEMS_LOAD = 'SUPPLYFORMITEMS_LOAD';
export const SUPPLYFORMITEMS_LOAD_SUCCESS = 'SUPPLYFORMITEMS_LOAD_SUCCESS';
export const SUPPLYFORMITEMS_LOAD_FAILED = 'SUPPLYFORMITEMS_LOAD_FAILED';
export const SUPPLYFORMITEM_ADD = 'SUPPLYFORMITEM_ADD';
export const SUPPLYFORMITEM_ADD_SUCCESS = 'SUPPLYFORMITEM_ADD_SUCCESS';
export const SUPPLYFORMITEM_ADD_FAILED = 'SUPPLYFORMITEM_ADD_FAILED';
export const SUPPLYFORMITEM_UPDATE = 'SUPPLYFORMITEM_UPDATE';
export const SUPPLYFORMITEM_UPDATE_SUCCESS = 'SUPPLYFORMITEM_UPDATE_SUCCESS';
export const SUPPLYFORMITEM_UPDATE_FAILED = 'SUPPLYFORMITEM_UPDATE_FAILED';
export const SUPPLYFORMITEM_DELETE = 'SUPPLYFORMITEM_DELETE';
export const SUPPLYFORMITEM_DELETE_SUCCESS = 'SUPPLYFORMITEM_DELETE_SUCCESS';
export const SUPPLYFORMITEM_DELETE_FAILED = 'SUPPLYFORMITEM_DELETE_FAILED';
export const SUPPLYFORMITEM_DELETE_ALL = 'SUPPLYFORMITEM_DELETE_ALL';
export const SUPPLYFORMITEM_DELETE_ALL_SUCCESS = 'SUPPLYFORMITEM_DELETE_ALL_SUCCESS';
export const SUPPLYFORMITEM_DELETE_ALL_FAILED = 'SUPPLYFORMITEM_DELETE_ALL_FAILED';
export const SUPPLYFORMITEMS_EXPORT_EXCEL = 'SUPPLYFORMITEMS_EXPORT_EXCEL';
export const SUPPLYFORMITEMS_EXPORT_EXCEL_SUCCESS = 'SUPPLYFORMITEMS_EXPORT_EXCEL_SUCCESS';
export const SUPPLYFORMITEMS_EXPORT_EXCEL_FAILED = 'SUPPLYFORMITEMS_EXPORT_EXCEL_FAILED';
export const SUPPLYFORMITEMS_COUNT = 'SUPPLYFORMITEMS_COUNT';
export const SUPPLYFORMITEMS_COUNT_SUCCESS = 'SUPPLYFORMITEMS_COUNT_SUCCESS';


// QuotationWaste const
export const QUOTATIONPRODUCTS_SELECT_ALL = 'QUOTATIONPRODUCTS_SELECT_ALL';
export const QUOTATIONPRODUCTS_UNSELECT_ALL = 'QUOTATIONPRODUCTS_UNSELECT_ALL';
export const QUOTATIONPRODUCT_SELECTED = 'QUOTATIONPRODUCT_SELECTED';
export const QUOTATIONPRODUCT_LOAD = 'QUOTATIONPRODUCT_LOAD';
export const QUOTATIONPRODUCT_LOAD_SUCCESS = 'QUOTATIONPRODUCT_LOAD_SUCCESS';
export const QUOTATIONPRODUCT_LOAD_FAILED = 'QUOTATIONPRODUCT_LOAD_FAILED';
export const QUOTATIONPRODUCTS_LOAD = 'QUOTATIONPRODUCTS_LOAD';
export const QUOTATIONPRODUCTS_LOAD_SUCCESS = 'QUOTATIONPRODUCTS_LOAD_SUCCESS';
export const QUOTATIONPRODUCTS_LOAD_FAILED = 'QUOTATIONPRODUCTS_LOAD_FAILED';
export const QUOTATIONPRODUCT_ADD = 'QUOTATIONPRODUCT_ADD';
export const QUOTATIONPRODUCT_ADD_SUCCESS = 'QUOTATIONPRODUCT_ADD_SUCCESS';
export const QUOTATIONPRODUCT_ADD_FAILED = 'QUOTATIONPRODUCT_ADD_FAILED';
export const QUOTATIONPRODUCT_UPDATE = 'QUOTATIONPRODUCT_UPDATE';
export const QUOTATIONPRODUCT_UPDATE_SUCCESS = 'QUOTATIONPRODUCT_UPDATE_SUCCESS';
export const QUOTATIONPRODUCT_UPDATE_FAILED = 'QUOTATIONPRODUCT_UPDATE_FAILED';
export const QUOTATIONPRODUCT_DELETE = 'QUOTATIONPRODUCT_DELETE';
export const QUOTATIONPRODUCT_DELETE_SUCCESS = 'QUOTATIONPRODUCT_DELETE_SUCCESS';
export const QUOTATIONPRODUCT_DELETE_FAILED = 'QUOTATIONPRODUCT_DELETE_FAILED';
export const QUOTATIONPRODUCT_DELETE_ALL = 'QUOTATIONPRODUCT_DELETE_ALL';
export const QUOTATIONPRODUCT_DELETE_ALL_SUCCESS = 'QUOTATIONPRODUCT_DELETE_ALL_SUCCESS';
export const QUOTATIONPRODUCT_DELETE_ALL_FAILED = 'QUOTATIONPRODUCT_DELETE_ALL_FAILED';
export const QUOTATIONPRODUCTS_EXPORT_EXCEL = 'QUOTATIONPRODUCTS_EXPORT_EXCEL';
export const QUOTATIONPRODUCTS_EXPORT_EXCEL_SUCCESS = 'QUOTATIONPRODUCTS_EXPORT_EXCEL_SUCCESS';
export const QUOTATIONPRODUCTS_EXPORT_EXCEL_FAILED = 'QUOTATIONPRODUCTS_EXPORT_EXCEL_FAILED';

// ACCOUNTLEDGER const
export const ACCOUNTLEDGERS_SELECT_ALL = 'ACCOUNTLEDGERS_SELECT_ALL';
export const ACCOUNTLEDGERS_UNSELECT_ALL = 'ACCOUNTLEDGERS_UNSELECT_ALL';
export const ACCOUNTLEDGER_SELECTED = 'ACCOUNTLEDGER_SELECTED';
export const ACCOUNTLEDGER_LOAD = 'ACCOUNTLEDGER_LOAD';
export const ACCOUNTLEDGER_LOAD_SUCCESS = 'ACCOUNTLEDGER_LOAD_SUCCESS';
export const ACCOUNTLEDGER_LOAD_FAILED = 'ACCOUNTLEDGER_LOAD_FAILED';
export const ACCOUNTLEDGERS_LOAD = 'ACCOUNTLEDGERS_LOAD';
export const ACCOUNTLEDGERS_LOAD_SUCCESS = 'ACCOUNTLEDGERS_LOAD_SUCCESS';
export const ACCOUNTLEDGERS_LOAD_FAILED = 'ACCOUNTLEDGERS_LOAD_FAILED';
export const ACCOUNTLEDGER_ADD = 'ACCOUNTLEDGER_ADD';
export const ACCOUNTLEDGER_ADD_SUCCESS = 'ACCOUNTLEDGER_ADD_SUCCESS';
export const ACCOUNTLEDGER_ADD_FAILED = 'ACCOUNTLEDGER_ADD_FAILED';
export const ACCOUNTLEDGER_UPDATE = 'ACCOUNTLEDGER_UPDATE';
export const ACCOUNTLEDGER_UPDATE_SUCCESS = 'ACCOUNTLEDGER_UPDATE_SUCCESS';
export const ACCOUNTLEDGER_UPDATE_FAILED = 'ACCOUNTLEDGER_UPDATE_FAILED';
export const ACCOUNTLEDGER_DELETE = 'ACCOUNTLEDGER_DELETE';
export const ACCOUNTLEDGER_DELETE_SUCCESS = 'ACCOUNTLEDGER_DELETE_SUCCESS';
export const ACCOUNTLEDGER_DELETE_FAILED = 'ACCOUNTLEDGER_DELETE_FAILED';
export const ACCOUNTLEDGER_DELETE_ALL = 'ACCOUNTLEDGER_DELETE_ALL';
export const ACCOUNTLEDGER_DELETE_ALL_SUCCESS = 'ACCOUNTLEDGER_DELETE_ALL_SUCCESS';
export const ACCOUNTLEDGER_DELETE_ALL_FAILED = 'ACCOUNTLEDGER_DELETE_ALL_FAILED';
export const ACCOUNTLEDGERS_EXPORT_EXCEL = 'ACCOUNTLEDGERS_EXPORT_EXCEL';
export const ACCOUNTLEDGERS_EXPORT_EXCEL_SUCCESS = 'ACCOUNTLEDGERS_EXPORT_EXCEL_SUCCESS';
export const ACCOUNTLEDGERS_EXPORT_EXCEL_FAILED = 'ACCOUNTLEDGERS_EXPORT_EXCEL_FAILED';
export const ACCOUNTLEDGERS_REPORT = 'ACCOUNTLEDGERS_REPORT';
export const ACCOUNTLEDGERS_REPORT_SUCCESS = 'ACCOUNTLEDGERS_REPORT_SUCCESS';
export const ACCOUNTLEDGERS_REPORT_FAILED = 'ACCOUNTLEDGERS_REPORT_FAILED';
export const ACCOUNTLEDGERS_REPORTSUM = 'ACCOUNTLEDGERS_REPORTSUM';
export const ACCOUNTLEDGERS_REPORTSUM_SUCCESS = 'ACCOUNTLEDGERS_REPORTSUM_SUCCESS';
export const ACCOUNTLEDGERS_REPORTSUM_FAILED = 'ACCOUNTLEDGERS_REPORTSUM_FAILED';

// ORDERSTORE const
export const ORDERSTORES_SELECT_ALL = 'ORDERSTORES_SELECT_ALL';
export const ORDERSTORES_UNSELECT_ALL = 'ORDERSTORES_UNSELECT_ALL';
export const ORDERSTORE_SELECTED = 'ORDERSTORE_SELECTED';
export const ORDERSTORE_LOAD = 'ORDERSTORE_LOAD';
export const ORDERSTORE_LOAD_SUCCESS = 'ORDERSTORE_LOAD_SUCCESS';
export const ORDERSTORE_LOAD_FAILED = 'ORDERSTORE_LOAD_FAILED';
export const ORDERSTORES_LOAD = 'ORDERSTORES_LOAD';
export const ORDERSTORES_LOAD_SUCCESS = 'ORDERSTORES_LOAD_SUCCESS';
export const ORDERSTORES_LOAD_FAILED = 'ORDERSTORES_LOAD_FAILED';
export const ORDERSTORE_ADD = 'ORDERSTORE_ADD';
export const ORDERSTORE_ADD_SUCCESS = 'ORDERSTORE_ADD_SUCCESS';
export const ORDERSTORE_ADD_FAILED = 'ORDERSTORE_ADD_FAILED';
export const ORDERSTORE_UPDATE = 'ORDERSTORE_UPDATE';
export const ORDERSTORE_UPDATE_SUCCESS = 'ORDERSTORE_UPDATE_SUCCESS';
export const ORDERSTORE_UPDATE_FAILED = 'ORDERSTORE_UPDATE_FAILED';
export const ORDERSTORE_DELETE = 'ORDERSTORE_DELETE';
export const ORDERSTORE_DELETE_SUCCESS = 'ORDERSTORE_DELETE_SUCCESS';
export const ORDERSTORE_DELETE_FAILED = 'ORDERSTORE_DELETE_FAILED';
export const ORDERSTORE_DELETE_ALL = 'ORDERSTORE_DELETE_ALL';
export const ORDERSTORE_DELETE_ALL_SUCCESS = 'ORDERSTORE_DELETE_ALL_SUCCESS';
export const ORDERSTORE_DELETE_ALL_FAILED = 'ORDERSTORE_DELETE_ALL_FAILED';
export const ORDERSTORES_EXPORT_EXCEL = 'ORDERSTORES_EXPORT_EXCEL';
export const ORDERSTORES_EXPORT_EXCEL_SUCCESS = 'ORDERSTORES_EXPORT_EXCEL_SUCCESS';
export const ORDERSTORES_EXPORT_EXCEL_FAILED = 'ORDERSTORES_EXPORT_EXCEL_FAILED';
export const ORDERSTORES_REPORT = 'ORDERSTORES_REPORT';
export const ORDERSTORES_REPORT_SUCCESS = 'ORDERSTORES_REPORT_SUCCESS';
export const ORDERSTORES_REPORT_FAILED = 'ORDERSTORES_REPORT_FAILED';

// ORDERSTOREPRODUCT const
export const ORDERSTOREPRODUCTS_SELECT_ALL = 'ORDERSTOREPRODUCTS_SELECT_ALL';
export const ORDERSTOREPRODUCTS_UNSELECT_ALL = 'ORDERSTOREPRODUCTS_UNSELECT_ALL';
export const ORDERSTOREPRODUCT_SELECTED = 'ORDERSTOREPRODUCT_SELECTED';
export const ORDERSTOREPRODUCT_LOAD = 'ORDERSTOREPRODUCT_LOAD';
export const ORDERSTOREPRODUCT_LOAD_SUCCESS = 'ORDERSTOREPRODUCT_LOAD_SUCCESS';
export const ORDERSTOREPRODUCT_LOAD_FAILED = 'ORDERSTOREPRODUCT_LOAD_FAILED';
export const ORDERSTOREPRODUCTS_LOAD = 'ORDERSTOREPRODUCTS_LOAD';
export const ORDERSTOREPRODUCTS_LOAD_SUCCESS = 'ORDERSTOREPRODUCTS_LOAD_SUCCESS';
export const ORDERSTOREPRODUCTS_LOAD_FAILED = 'ORDERSTOREPRODUCTS_LOAD_FAILED';
export const ORDERSTOREPRODUCT_ADD = 'ORDERSTOREPRODUCT_ADD';
export const ORDERSTOREPRODUCT_ADD_SUCCESS = 'ORDERSTOREPRODUCT_ADD_SUCCESS';
export const ORDERSTOREPRODUCT_ADD_FAILED = 'ORDERSTOREPRODUCT_ADD_FAILED';
export const ORDERSTOREPRODUCT_UPDATE = 'ORDERSTOREPRODUCT_UPDATE';
export const ORDERSTOREPRODUCT_UPDATE_SUCCESS = 'ORDERSTOREPRODUCT_UPDATE_SUCCESS';
export const ORDERSTOREPRODUCT_UPDATE_FAILED = 'ORDERSTOREPRODUCT_UPDATE_FAILED';
export const ORDERSTOREPRODUCT_DELETE = 'ORDERSTOREPRODUCT_DELETE';
export const ORDERSTOREPRODUCT_DELETE_SUCCESS = 'ORDERSTOREPRODUCT_DELETE_SUCCESS';
export const ORDERSTOREPRODUCT_DELETE_FAILED = 'ORDERSTOREPRODUCT_DELETE_FAILED';
export const ORDERSTOREPRODUCT_DELETE_ALL = 'ORDERSTOREPRODUCT_DELETE_ALL';
export const ORDERSTOREPRODUCT_DELETE_ALL_SUCCESS = 'ORDERSTOREPRODUCT_DELETE_ALL_SUCCESS';
export const ORDERSTOREPRODUCT_DELETE_ALL_FAILED = 'ORDERSTOREPRODUCT_DELETE_ALL_FAILED';
export const ORDERSTOREPRODUCTS_EXPORT_EXCEL = 'ORDERSTOREPRODUCTS_EXPORT_EXCEL';
export const ORDERSTOREPRODUCTS_EXPORT_EXCEL_SUCCESS = 'ORDERSTOREPRODUCTS_EXPORT_EXCEL_SUCCESS';
export const ORDERSTOREPRODUCTS_EXPORT_EXCEL_FAILED = 'ORDERSTOREPRODUCTS_EXPORT_EXCEL_FAILED';
export const ORDERSTOREPRODUCTS_REPORT = 'ORDERSTOREPRODUCTS_REPORT';
export const ORDERSTOREPRODUCTS_REPORT_SUCCESS = 'ORDERSTOREPRODUCTS_REPORT_SUCCESS';
export const ORDERSTOREPRODUCTS_REPORT_FAILED = 'ORDERSTOREPRODUCTS_REPORT_FAILED';