import {
  INVOICES_SELECT_ALL,
  INVOICES_UNSELECT_ALL,
  INVOICE_SELECTED,
  INVOICES_LOAD,
  INVOICES_LOAD_SUCCESS,
  INVOICES_LOAD_FAILED,
  INVOICE_LOAD,
  INVOICE_LOAD_SUCCESS,
  INVOICE_LOAD_FAILED,
  INVOICE_ADD,
  INVOICE_ADD_SUCCESS,
  INVOICE_ADD_FAILED,
  INVOICE_UPDATE,
  INVOICE_UPDATE_SUCCESS,
  INVOICE_UPDATE_FAILED,
  INVOICE_DELETE,
  INVOICE_DELETE_SUCCESS,
  INVOICE_DELETE_FAILED,
  INVOICE_DELETE_ALL,
  INVOICE_DELETE_ALL_SUCCESS,
  INVOICE_DELETE_ALL_FAILED,
  INVOICES_EXPORT_EXCEL,
  INVOICES_EXPORT_EXCEL_SUCCESS,
  INVOICES_EXPORT_EXCEL_FAILED,
  INVOICES_REPORT,
  INVOICES_REPORT_SUCCESS,
  INVOICES_REPORT_FAILED
} from '../constants/ActionTypes';
import { PAGE_SIZES } from '../constants/AppConstant';

const INIT_STATE = {
  paging: {
    records: [],
    offset: 0,
    limit: PAGE_SIZES,
    totalRecords: 0
  },
  reportData: [],
  selectedInvoice: null,
  selectedInvoiceId: null,
  message: null,
  messageType: 'info',
  filter: {
    offset: 0,
    limit: PAGE_SIZES,
    orderBy: 'id',
    descending: true,
    filterGroups: null
  },
  hideLoading: true
};

export default (state = INIT_STATE, action) => {
  switch (action.type) {
    case INVOICES_SELECT_ALL:
      return {
        ...state,
        paging: {
          ...state.paging,
          records: state.paging.records.map(data => {
            data.selected = true;
            return data;
          })
        }
      }
    case INVOICES_UNSELECT_ALL:
      return {
        ...state,
        paging: {
          ...state.paging,
          records: state.paging.records.map(data => {
            data.selected = false;
            return data;
          })
        }
      }
    case INVOICE_SELECTED:
      return {
        ...state,
        selectedInvoice: action.payload,
        selectedInvoiceId: action.payload.id
      };
    case INVOICES_LOAD:
      return {
        ...state,
        filter: action.payload ? action.payload : state.filter
      };
    case INVOICES_LOAD_SUCCESS:
      return {
        ...state,
        paging: action.payload
      };
    case INVOICES_LOAD_FAILED:
      return {
        ...state
      };
    case INVOICE_LOAD:
      return {
        ...state
      };
    case INVOICE_LOAD_SUCCESS:
      return {
        ...state
      };
    case INVOICE_LOAD_FAILED:
      return {
        ...state
      };
    case INVOICE_ADD:
      return {
        ...state,
        hideLoading: false,
        selectedInvoice: undefined
      };
    case INVOICE_ADD_SUCCESS:
      return {
        ...state,
        hideLoading: true,
        selectedInvoice: action.payload
      };
    case INVOICE_ADD_FAILED:
      return {
        ...state,
        hideLoading: true,
        selectedInvoice: undefined
      };
    case INVOICE_UPDATE:
      return {
        ...state,
        hideLoading: false,
        selectedInvoice: undefined
      };
    case INVOICE_UPDATE_SUCCESS:
      return {
        ...state,
        hideLoading: true,
        selectedInvoice: action.payload
      };
    case INVOICE_UPDATE_FAILED:
      return {
        ...state,
        hideLoading: true,
        selectedInvoice: undefined
      };
    case INVOICE_DELETE:
      return {
        ...state
      };
    case INVOICE_DELETE_SUCCESS:
      return {
        ...state,
        message: action.message
      };
    case INVOICE_DELETE_FAILED:
      return {
        ...state
      };
    case INVOICE_DELETE_ALL:
      return {
        ...state
      };
    case INVOICE_DELETE_ALL_SUCCESS:
      return {
        ...state,
        message: action.message
      };
    case INVOICE_DELETE_ALL_FAILED:
      return {
        ...state
      };
    case INVOICES_EXPORT_EXCEL:
      return {
        ...state,
        excelBlob: null
      };
    case INVOICES_EXPORT_EXCEL_SUCCESS:
      return {
        ...state,
        excelBlob: action.payload
      };
    case INVOICES_EXPORT_EXCEL_FAILED:
      return {
        ...state,
        excelBlob: null
      };
    case INVOICES_REPORT:
      return {
        ...state,
        reportData: []
      };
    case INVOICES_REPORT_SUCCESS:
      return {
        ...state,
        reportData: action.payload
      };
    case INVOICES_REPORT_FAILED:
      return {
        ...state,
        reportData: []
      };
    default:
      return state;
  }
}
