import {
    CATEGORIES_SELECT_ALL,
    CATEGORIES_UNSELECT_ALL,
    CATEGORY_SELECTED,
    CATEGORIES_LOAD,
    CATEGORIES_LOAD_SUCCESS,
    CATEGORIES_LOAD_FAILED,
    CATEGORY_LOAD,
    CATEGORY_LOAD_SUCCESS,
    CATEGORY_LOAD_FAILED,
    CATEGORY_ADD,
    CATEGORY_ADD_SUCCESS,
    CATEGORY_ADD_FAILED,
    CATEGORY_UPDATE,
    CATEGORY_UPDATE_SUCCESS,
    CATEGORY_UPDATE_FAILED,
    CATEGORY_DELETE,
    CATEGORY_DELETE_SUCCESS,
    CATEGORY_DELETE_FAILED,
    CATEGORY_DELETE_ALL,
    CATEGORY_DELETE_ALL_SUCCESS,
    CATEGORY_DELETE_ALL_FAILED,
    CATEGORIES_EXPORT_EXCEL,
    CATEGORIES_EXPORT_EXCEL_SUCCESS,
    CATEGORIES_EXPORT_EXCEL_FAILED,
    CATEGORIES_REPORT,
    CATEGORIES_REPORT_SUCCESS,
    CATEGORIES_REPORT_FAILED
  } from '../constants/ActionTypes';
  
  export const selectAllCategories = () => {
    return {
      type: CATEGORIES_SELECT_ALL
    }
  }
  
  export const unselectAllCategories = () => {
    return {
      type: CATEGORIES_UNSELECT_ALL
    }
  }
  
  export const selectCategory = data => {
    return {
      type: CATEGORY_SELECTED,
      payload: data
    }
  }
  
  export const loadCategories = (data) => {
    return {
      type: CATEGORIES_LOAD,
      payload: data
    };
  }
  
  export const loadCategoriesSuccess = (data) => {
    return {
      type: CATEGORIES_LOAD_SUCCESS,
      payload: data
    };
  }
  
  export const loadCategoriesFailed = () => {
    return {
      type: CATEGORIES_LOAD_FAILED
    };
  }
  
  export const loadCategory = () => {
    return {
      type: CATEGORY_LOAD
    };
  }
  
  export const loadCategorySuccess = (data) => {
    return {
      type: CATEGORY_LOAD_SUCCESS,
      payload: data
    };
  }
  
  
  export const loadCategoryFailed = () => {
    return {
      type: CATEGORY_LOAD_FAILED
    };
  }
  
  
  export const addCategory = (data) => {
    return {
      type: CATEGORY_ADD,
      payload: data
    };
  }
  
  
  export const addCategorySuccess = (filter) => {
    return {
      type: CATEGORY_ADD_SUCCESS,
      message: 'Category added successfully.',
      payload: filter
    };
  }
  
  export const addCategoryFailed = () => {
    return {
      type: CATEGORY_ADD_FAILED
    };
  }
  
  export const updateCategory = (data) => {
    return {
      type: CATEGORY_UPDATE,
      payload: data
    };
  }
  
  
  export const updateCategorySuccess = (data) => {
    return {
      type: CATEGORY_UPDATE_SUCCESS,
      payload: data
    };
  }
  
  export const updateCategoryFailed = (data) => {
    return {
      type: CATEGORY_UPDATE_FAILED,
      payload: data
    };
  }
  
  export const deleteCategory = (data) => {
    return {
      type: CATEGORY_DELETE,
      payload: data
    };
  }
  
  export const deleteCategorySuccess = () => {
    return {
      type: CATEGORY_DELETE_SUCCESS,
      message: 'Category deleted successfully.'
    };
  }
  
  export const deleteCategoryFailed = () => {
    return {
      type: CATEGORY_DELETE_FAILED
    };
  }
  
  export const deleteAllCategories = (data) => {
    return {
      type: CATEGORY_DELETE_ALL,
      payload: data
    };
  }
  
  export const deleteAllCategoriesSuccess = () => {
    return {
      type: CATEGORY_DELETE_ALL_SUCCESS,
      message: 'Category all deleted successfully.'
    };
  }
  
  export const deleteAllCategoriesFailed = () => {
    return {
      type: CATEGORY_DELETE_ALL_FAILED
    };
  }
  
  export const exportExcelCategories = (filter) => {
    return {
      type: CATEGORIES_EXPORT_EXCEL,
      payload: filter
    }
  }
  
  export const exportExcelCategoriesSuccess = (data) => {
    return {
      type: CATEGORIES_EXPORT_EXCEL_SUCCESS,
      payload: data
    };
  }
  
  export const exportExcelCategoriesFailed = () => {
    return {
      type: CATEGORIES_EXPORT_EXCEL_FAILED
    };
  }
  
  export const reportCategories = (filter) => {
    return {
      type: CATEGORIES_REPORT,
      payload: filter
    }
  }
  
  export const reportCategoriesSuccess = (data) => {
    return {
      type: CATEGORIES_REPORT_SUCCESS,
      payload: data
    };
  }
  export const reportCategoriesFailed = () => {
    return {
      type: CATEGORIES_REPORT_FAILED
    };
  }