import { all, call, fork, put, takeEvery } from "redux-saga/effects";
import Api, { patchData } from './Api';
import { GET_ALL_SIZE } from '../constants/AppConstant';

import {
  loadProviders as loadProvidersAction,
  loadProvidersSuccess,
  addProviderSuccess,
  updateProviderSuccess,
  exportExcelProvidersSuccess,
  reportProvidersSuccess
} from '../actions/Provider';

import {
  PROVIDERS_LOAD,
  PROVIDER_ADD,
  PROVIDER_UPDATE,
  PROVIDER_DELETE,
  PROVIDER_DELETE_ALL,
  PROVIDERS_EXPORT_EXCEL,
  PROVIDERS_REPORT
} from '../constants/ActionTypes';

import {
  userSignOut
} from '../actions/Auth';

export const getProviderById = (id, callback) => {
  Api().get('provider/' + id).then(response => {
    callback(response.data);
  })
}

export const getAllProviders = (callback, params) => {
  if (!params) {
    params = {
      limit: GET_ALL_SIZE
    }
  }

  Api().post('provider/search', params)
    .then(response => {
      callback(response.data.records);        
    })
}

export const getProvidersByCompany = (companyId, callback) => {
  Api().post('provider/search', {
    limit: GET_ALL_SIZE,
    filterGroups: [{
      filters: [{
        property: 'companyId',
        operator: 'equal',
        value: companyId
      }]
    }]
  }).then(response => {
    callback(response.data.records);    
  })
}

export const getMaxProviderCodeSorted = (callback) => {
  Api().post('provider/search', { offset: 0,
    limit: 1,
    orderBy: "codeSorted",
    descending: true
  })
    .then(response => {
      let codeSorted = 0;
      let records = response.data.records;
      if (records.length) {
        codeSorted = records[0].codeSorted;
      }
      callback(codeSorted);
    })
}

const loadProvidersRequest = async (params) => {
  if (params.filterGroups && params.filterGroups.length) {
    return await Api().post(`provider/search`, params)
      .then(data => data)
      .catch(error => error);
  }

  return await Api().get(`provider`, { params })
    .then(data => data)
    .catch(error => error);
}

const addProviderRequest = async (payload) =>
  await Api().post(`provider`, payload)
    .then(data => data)
    .catch(error => error);

const updateProviderRequest = async (payload) =>
  await Api().patch(`provider/${payload.id}`, patchData(payload))
    .then(data => data)
    .catch(error => error);

export const deleteProviderRequest = async (id) =>
  await Api().delete(`provider/${id}`)
    .then(data => data)
    .catch(error => error);

function* loadProviders({ payload }) {
  try {
    const data = yield call(loadProvidersRequest, payload);
    data.status == 401 || data.response && data.response.status == 401 ? yield put(userSignOut()) :
    yield put(loadProvidersSuccess(data.data));
  } catch (error) {
    //yield put(showAuthMessage(error));
  }
}

function* processLoadProviders() {
  yield takeEvery(PROVIDERS_LOAD, loadProviders);
}

function* loadProvider({ selectedProviderId }) {
  try {
    const data = yield call(loadProvidersRequest, selectedProviderId);
    data.status == 401 || data.response && data.response.status == 401 ? yield put(userSignOut()) :
    yield put(loadProvidersSuccess(data.data));
  } catch (error) {
    //yield put(showAuthMessage(error));
  }
}

function* processLoadProvider() {
  yield takeEvery(PROVIDERS_LOAD, loadProvider);
}


function* addProvider({ payload }) {
  try {
    const data = yield call(addProviderRequest, payload.model);
    data.status == 401 || data.response && data.response.status == 401 ? yield put(userSignOut()) :
    yield put(addProviderSuccess(payload.filter));
  } catch (error) {
    //yield put(showAuthMessage(error));
  }
}

function* processAddProvider() {
  yield takeEvery(PROVIDER_ADD, addProvider);
}


function* updateProvider({ payload }) {
  try {
    const data = yield call(updateProviderRequest, payload.model);
    data.status == 401 || data.response && data.response.status == 401 ? yield put(userSignOut()) :
    yield put(updateProviderSuccess(payload.filter));
  } catch (error) {
    //yield put(showAuthMessage(error));
  }
}

function* processUpdateProvider() {
  yield takeEvery(PROVIDER_UPDATE, updateProvider);
}

function* deleteProvider({ payload }) {
  try {
    const data = yield call(deleteProviderRequest, payload.model.id);
    data.status == 401 || data.response && data.response.status == 401 ? yield put(userSignOut()) :
    yield put(loadProvidersAction(payload.filter));
  } catch (error) {
    //yield put(showAuthMessage(error));
  }
}

function* deleteAllProviders({ payload }) {
  try {
    for (let i = 0; i < payload.models.length; ++i) {
      const data = yield call(deleteProviderRequest, payload.models[i].id);
      if (data.status == 401 || data.response && data.response.status == 401) { yield put(userSignOut()); break; }
    }

    yield put(loadProvidersAction(payload.filter));
  } catch (error) {
    //yield put(showAuthMessage(error));
  }
}

function* processDeleteProvider() {
  yield takeEvery(PROVIDER_DELETE, deleteProvider);
}

function* processDeleteAllProviders() {
  yield takeEvery(PROVIDER_DELETE_ALL, deleteAllProviders);
}

const exportProvidersRequest = async (params) => {
  return await Api({responseType: 'blob'}).post(`provider/export/excel`, params)
    .then(data => data)
    .catch(error => error);
}


function* exportProviders({ payload }) {
  try {
    const data = yield call(exportProvidersRequest, payload);
    data.status == 401 || data.response && data.response.status == 401 ? yield put(userSignOut()) :
    yield put(exportExcelProvidersSuccess(data.data));
  } catch (error) {
    //yield put(showAuthMessage(error));
  }
}

function* processExportProviders() {
  yield takeEvery(PROVIDERS_EXPORT_EXCEL, exportProviders);
}

const reportProvidersRequest = async (params) => {
  return await Api().post(`provider/report`, params)
    .then(data => data)
    .catch(error => error);
}


function* reportProviders({ payload }) {
  try {
    if (!payload) { payload = {} }
    const data = yield call(reportProvidersRequest, payload);
    data.status == 401 || data.response && data.response.status == 401 ? yield put(userSignOut()) :
    yield put(reportProvidersSuccess(data.data));
  } catch (error) {
    //yield put(showAuthMessage(error));
  }
}

function* processReportProviders() {
  yield takeEvery(PROVIDERS_REPORT, reportProviders);
}

export default function* ProviderSagas() {
  yield all([fork(processLoadProviders),
  fork(processLoadProvider),
  fork(processAddProvider),
  fork(processUpdateProvider),
  fork(processDeleteAllProviders),
  fork(processDeleteProvider),
  fork(processExportProviders),
  fork(processReportProviders)
]);
}
