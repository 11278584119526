import { combineReducers } from "redux";
import { connectRouter } from 'connected-react-router';
import Setting from './Setting';
import Company from './Company';
import Department from './Department';
import Employee from './Employee';
import PersonalPaper from './PersonalPaper';
import WorkLocation from './WorkLocation';
import Customer from './Customer';
import CustomerReg from './CustomerReg';
import LeaveTrack from './LeaveTrack';
import LeaveTrackHistory from './LeaveTrackHistory';
import Payment from './Payment';
import Contract from './Contract';
import Quotation from './Quotation';
import Mail from './Mail';
import MailToUser from './MailToUser';
import Job from './Job';
import Template from './Template';
import Auth from "./Auth";
import User from "./User";
import Role from "./Role";
import Common from './Common';
import Invoice from './Invoice';
import Album from './Album';
import Media from './Media';
import Library from './Library';
import Notification from './Notification';
import Document from './Document';
import DocumentCategory from './DocumentCategory';
import PersonalMail from './PersonalMail';
import Product from './Product';
import Supply from './Supply';
import Project from './Project';
import SalarySetting from './SalarySetting';
import SalaryHistory from './SalaryHistory';
import Approval from './Approval';
import AdvanceSalary from './AdvanceSalary';
import Ledger from './Ledger';
import PaymentVoucher from './PaymentVoucher';
import ReceiptVoucher from './ReceiptVoucher';
import ProductImportVoucher from './ProductImportVoucher';
import ProductExportVoucher from './ProductExportVoucher';
import SupplyImportVoucher from './SupplyImportVoucher';
import SupplyExportVoucher from './SupplyExportVoucher';
import SupplyVoucherItem from './SupplyVoucherItem';
import SupplyForm from './SupplyForm';
import SupplyFormItem from './SupplyFormItem';
import TimeKeep from './TimeKeep';
import Provider from './Provider';
import Store from './Store';
import Order from "./Order";
import OrderProduct from './OrderProduct';
import Category from './Category';
import StoreProduct from './StoreProduct';
import QuotationProduct from './QuotationProduct';
import AccountLedger from './AccountLedger';
import OrderStore from './OrderStore';
import OrderStoreProduct from './OrderStoreProduct';
import Sample from './Sample';

export default (history) => combineReducers({
  router: connectRouter(history),
  setting: Setting,  
  company: Company,  
  department: Department,  
  employee: Employee,  
  personalpaper: PersonalPaper,  
  workLocation: WorkLocation,  
  customer: Customer,  
  customerReg: CustomerReg,
  leaveTrack: LeaveTrack,  
  leaveTrackHistory: LeaveTrackHistory,  
  payment: Payment,  
  contract: Contract, 
  quotation: Quotation,  
  auth: Auth,
  user: User,
  role: Role,
  common: Common,
  mail: Mail, 
  mailToUser: MailToUser, 
  job: Job, 
  template: Template,
  invoice: Invoice,
  album: Album,
  media: Media,
  library: Library,
  notification: Notification,
  document: Document,
  documentCategory: DocumentCategory,
  personalMail: PersonalMail,
  product: Product,
  supply: Supply,
  project: Project,
  salarySetting: SalarySetting,
  salaryHistory: SalaryHistory,
  approval: Approval,
  advanceSalary: AdvanceSalary,
  ledger: Ledger,
  paymentVoucher: PaymentVoucher,
  receiptVoucher: ReceiptVoucher,
  productImportVoucher: ProductImportVoucher,
  productExportVoucher: ProductExportVoucher,
  supplyImportVoucher: SupplyImportVoucher,
  supplyExportVoucher: SupplyExportVoucher,
  store: Store,
  order: Order,
  orderProduct: OrderProduct, 
  category: Category,
  storeProduct: StoreProduct, 
  provider: Provider, 
  supplyVoucherItem: SupplyVoucherItem,
  supplyForm: SupplyForm,
  supplyFormItem: SupplyFormItem,
  timeKeep: TimeKeep,
  quotationProduct: QuotationProduct,
  accountLedger: AccountLedger,
  orderStore: OrderStore,
  orderStoreProduct: OrderStoreProduct,
  sample: Sample
});